<div class="container">

    <span class="info" matTooltip="{{'MACHINE_DETAILS.NAME_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user"></i>{{machine.name}}
    </span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'MACHINE_DETAILS.CODE_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-envelope"></i>{{machine.code}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'MACHINE_DETAILS.SERIAL_NUMBER_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user-tag"></i>{{machine.serialNumber}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'MACHINE_DETAILS.MACHINE_TYPE_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user-tag"></i>{{machine.machineTypeId}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'MACHINE_DETAILS.COMPANY_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user-tag"></i>{{machine.companyId}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'MACHINE_DETAILS.AREA_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user-tag"></i>{{machine.areaId}}</span>
    <mat-divider></mat-divider>

    <mat-divider></mat-divider>
    <button mat-raised-button color="primary" (click)="openModifyMachineDialog(machine);">
        <i class="fa-solid fa-pen-to-square"></i>
        <span>{{'MACHINE_DETAILS.MODIFY_MACHINE' | i18next}}</span>
    </button>

</div>
