<div class="container">

    <span class="info" matTooltip="{{'AREA_DETAILS.REGION_TOOLTIP' | i18next}}" matTooltipPosition="left"><i
            class="fa-solid fa-envelope"></i>{{area.region}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'AREA_DETAILS.CITY_TOOLTIP' | i18next}}" matTooltipPosition="left"><i
            class="fa-solid fa-user-tag"></i>{{area.city}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'AREA_DETAILS.LOCATION_TOOLTIP' | i18next}}" matTooltipPosition="left"><i
            class="fa-solid fa-user-tag"></i>{{area.location}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'AREA_DETAILS.COMPANY_TOOLTIP' | i18next}}" matTooltipPosition="left"><i
            class="fa-solid fa-user-tag"></i>{{area.companyName}}</span>

    <mat-divider></mat-divider>
    <button mat-raised-button color="primary" (click)="openModifyMachineDialog(area);">
        <i class="fa-solid fa-pen-to-square"></i>
        <span>{{'AREA_DETAILS.MODIFY_AREA' | i18next}}</span>
    </button>

</div>
