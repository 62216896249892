import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProviderService } from '../../core/provider.service';
import { Constants } from '../../models/constants';
import { User } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';
import { ListCompaniesGQL } from '../../../graphql/generated';
import { ListCompaniesMapper } from '../companies/companies.mappers';
import { CompanyModel } from '../../shared/models/company.model';
import { AntiMemLeak } from '../../shared/abstract-classes/anti-mem-leak';

export const companyChangedSubject: BehaviorSubject<any> =
  new BehaviorSubject<any>(null);

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends AntiMemLeak implements OnInit, OnDestroy {
  @Output() sidebarToggleEvent = new EventEmitter<boolean>();
  user: any;
  userAuth0Id = '';
  eConstants = Constants;
  test = false;

  companies: CompanyModel[] = [];
  selectedCompany?: CompanyModel;
  mapper = new ListCompaniesMapper();

  constructor(
    private router: Router,
    private providerService: ProviderService,
    public listCompanies: ListCompaniesGQL
  ) {
    super();
    this.subscriptions.add(
      this.providerService.oAuthService.getUser().subscribe((user: User) => {
        this.user = user;
        this.userAuth0Id = user.sub ?? '';
      })
    );
    this.subscriptions.add(
      this.providerService.oAuthService
        .isAuthenticated()
        .subscribe((isLogged: boolean) => (this.test = isLogged))
    );
  }

  ngOnInit(): void {}

  companySelectionChanged(event: any): void {
    this.selectedCompany = event;

    this.providerService.companyService.setSelectedCompany(event);
    companyChangedSubject.next(event);
  }

  toggleSidebar(value: boolean): void {
    this.sidebarToggleEvent.emit(value);
  }

  signOut(): void {
    this.providerService.oAuthService.signOut().then(() => {
      this.navigateTo('/auth/sign-in');
    });
  }

  navigateTo(href: string): void {
    this.providerService.utilService.navigateTo(href);
  }
}
