import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogLevel } from '../../models/log-level';
import { Constants } from '../../models/constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomConfirmationDialogComponent } from '../../components/dialogs/custom-confirmation-dialog/custom-confirmation-dialog.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  dialogRef: any = null;
  confirmationDialogRef: any = null;

  constructor(private snackbar: MatSnackBar, private dialog: MatDialog, private router: Router) {}

  showMessage(message: string, type: LogLevel): void {
    this.snackbar.open(message, 'Dismiss', {
      duration: Constants.snackbarDuration,
      panelClass: `app-notification-${type}`,
    });
  }

  async openConfirmationDialog(title: string, message: string): Promise<boolean> {
    if (this.confirmationDialogRef) {
      this.confirmationDialogRef.close();
    }
    this.confirmationDialogRef = this.dialog.open(CustomConfirmationDialogComponent, {
      data: {
        message,
        title,
      },
    });
    return (await firstValueFrom(this.confirmationDialogRef.afterClosed())) ?? false;
  }

  openDialog(component: any, data: any, panelClass?: string): MatDialogRef<any> {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(component, {
      data,
      panelClass,
    });
    return this.dialogRef;
  }

  async navigateTo(href: string, data?: string): Promise<void> {
    if (data) {
      await this.router.navigate([href, data]);
    } else {
      await this.router.navigate(href.split('/'));
    }
  }

  toCapitalizedWords(word: string): string {
    const capitalize = (w: string) => w.charAt(0).toUpperCase() + w.substring(1);
    const words = word.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(capitalize).join(' ');
  }

  camelToSnakeCase(value: string): string {
    return value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }
}
