import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
} from 'rxjs';
import { AntiMemLeak } from './anti-mem-leak';

export abstract class FilterTableComponent extends AntiMemLeak {
  debounceSubject = new BehaviorSubject<string>('');
  lastDebounceValue = '';
  filterObservable = new BehaviorSubject<string>('');

  protected constructor() {
    super();
    this.subscriptions.add(
      this.debounceSubject
        .pipe(debounceTime(500), distinctUntilChanged(), skip(1))
        .subscribe((value) => {
          this.filterObservable.next(value);
        })
    );
  }

  sendFilterQuery(event: any): void {
    this.lastDebounceValue = event.target.value;
    this.debounceSubject.next(event.target.value);
  }
}
