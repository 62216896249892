import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as Apollo from 'apollo-angular';
import {
  exhaustMap,
  Observable,
  scan,
  startWith,
  Subject,
  Subscriber,
  Subscription,
} from 'rxjs';
import { IGraphqlPagedMapper } from 'src/app/models/interfaces/I-graphql-paged-mapper';
import { ProviderService } from '../../provider.service';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-infinite-scroll-multi',
  templateUrl: './select-infinite-scroll-multi.component.html',
  styleUrls: ['./select-infinite-scroll-multi.component.scss'],
})
export class SelectInfiniteScrollMultiComponent implements OnInit, OnDestroy {
  @Input()
  icon = '';
  @Input()
  label = '';
  @Input()
  dataShowSelector = '';
  @Input()
  dataCompareSelector = '';
  @Input()
  query?: Apollo.Query<any, any>;
  @Input()
  mapper?: IGraphqlPagedMapper;
  @Input()
  params?: { [key: string]: string };
  @Input()
  selectedData?: any;
  @Input()
  disabled = false;
  @Output()
  selectionChanged = new EventEmitter<any>();
  loading = false;
  pageSize = 10;
  total = 0;
  data$: Observable<any[]> | undefined;

  public exFormGroup = new FormGroup({
    selectedOptions: new FormControl({} as any),
  });
  private nextPage$ = new Subject();
  private optionsSubscription?: Subscription;

  constructor(private providerService: ProviderService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.data$ = this.getItems(0);
    this.optionsSubscription =
      this.exFormGroup.controls.selectedOptions.valueChanges.subscribe(
        (value) => {
          this.selectionChanged.emit(value);
        }
      );
    if (this.selectedData) {
      this.exFormGroup.controls.selectedOptions.setValue(this.selectedData);
    }
    if (this.disabled) {
      this.exFormGroup.controls.selectedOptions.disable();
    }
    let currentPage = 0;
    this.data$ = this.nextPage$.pipe(
      startWith(currentPage),
      // Note: Until the backend responds, ignore NextPage requests.
      exhaustMap((_) => this.getItems(currentPage)),
      tap(() => currentPage++),
      takeWhile((p: any[]) => p.length > 0),
      scan((allData: any, newData: any) => allData.concat(newData), [])
    );
    this.loading = false;
  }

  onScroll(): void {
    //Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next('');
  }

  getItems(page: number): Observable<any[]> {
    return new Observable((subscriber: Subscriber<any[]>) => {
      const variables: {
        [key: string]: any;
      } = {};
      variables['page'] = {
        filter: '',
        limit: this.pageSize,
        orderCol: '',
        begins: page * this.pageSize,
        order: 'asc',
      };
      for (const key in this.params) {
        if (Object.hasOwn(this.params, key)) {
          variables[key] = this.params[key];
        }
      }
      if (this.query && this.mapper) {
        this.providerService.graphqlService
          .fetch<any>(this.query, variables, this.mapper)
          .then((result) => {
            this.total = result.total;
            subscriber.next(result.items);
            subscriber.complete();
          });
      }
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    if (!this.dataCompareSelector) {
      return true;
    } else {
      return o1[this.dataCompareSelector] === o2[this.dataCompareSelector];
    }
  }

  getOptionShowValue(option: any): any {
    if (option) {
      return option[this.dataShowSelector];
    }
  }

  ngOnDestroy(): void {
    this.optionsSubscription?.unsubscribe();
  }
}
