import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';
import { AreaModel } from '../../../shared/models/area.model';

export class AreaMapper implements IGraphqlMapper {
  area?: AreaModel;

  returnFromJson(json: any): AreaModel {
    const value = json.insertArea || json.updateArea || '';

    this.area = {
      id: value['id'],
      region: value['region'],
      city: value['city'],
      location: value['location'],
      companyId: value['companyId'],
      companyName: value['companyName'],
    };
    return this.area;
  }
}
