<h1 mat-dialog-title>{{data.title}}</h1>

<div mat-dialog-content>
  <span>{{data.message}}</span>
</div>
<div class="show-info-modal-dialog-actions" mat-dialog-actions>
  <a mat-button (click)="dialogRef.close();">
    <span>{{'SHOW_INFO.OK' | i18next}}</span>
  </a>
</div>
