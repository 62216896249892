<h1 mat-dialog-title>{{'CREATE_USER.TITLE' | i18next}}</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_USER.NAME_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'CREATE_USER.NAME_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'CREATE_USER.NAME_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_USER.SURNAME_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="surname" name="surname" formControlName="surname" placeholder="{{'CREATE_USER.SURNAME_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'CREATE_USER.SURNAME_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'surname'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.SURNAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_USER.EMAIL_LABEL' | i18next}}</mat-label>
      <input matInput type="mail" id="email" name="email" formControlName="email" placeholder="{{'CREATE_USER.EMAIL_PLACEHOLDER' | i18next}}" value="">
      <i class="fa fa-regular fa-envelope" matSuffix></i>
      <mat-hint>{{'CREATE_USER.EMAIL_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'email'" [errorCode]="'pattern'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.EMAIL_NOT_VALID_ERROR' | i18next}}"></app-validator-ui>
    <app-validator-ui [form]="form" [name]="'email'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.EMAIL_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_USER.USER_ROLE_LABEL' | i18next}}</mat-label>
      <mat-select [formControl]="form.controls.userType">
        <mat-option *ngFor="let userType of userTypes" [value]="userType.id">{{providerService.utilService.toCapitalizedWords(userType.name)}}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'userType'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_USER.ROLE_REQUIRED_ERROR' | i18next}}"></app-validator-ui>
  </form>

<!--  <app-select-infinite-scroll-multi-->
<!--    [icon] = "'fa-solid fa-buildings'"-->
<!--    label = "{{'CREATE_USER.APPLICATIONS_LABEL' | i18next}}*"-->
<!--    [dataShowSelector] = "'applicationName'"-->
<!--    [query] = "listApplications"-->
<!--    [mapper] = "listApplicationsMapper"-->
<!--    [params] = "{}"-->
<!--    (selectionChanged)="applicationsSelectionChanged($event)">-->
<!--  </app-select-infinite-scroll-multi>-->
<!--  <div class="applications-container-header" *ngIf="selectedApplications.length > 0">-->
<!--    <div class="applications-row title">-->
<!--      <div class="applications-name">-->
<!--        <span>Application:</span>-->
<!--      </div>-->
<!--      <div class="applications-user-type">-->
<!--        <span>User type:</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngFor="let application of selectedApplications" class="applications-container">-->
<!--    <div class="applications-row">-->
<!--      <div class="applications-name">-->
<!--        <span>{{application.applicationName}}:</span>-->
<!--      </div>-->
<!--      <div class="applications-user-type">-->
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label>{{'CREATE_USER.USER_ROLE_LABEL' | i18next}}</mat-label>-->
<!--          <mat-select>-->
<!--            <mat-option *ngFor="let userType of userTypes" [value]="userType.id">{{providerService.utilService.toCapitalizedWords(userType.name)}}</mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="addUser();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'CREATE_USER.CREATE_USER' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'CREATE_USER.CANCEL' | i18next}}</span>
  </a>
</div>
