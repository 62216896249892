import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';
import { CompanyModel } from '../../../shared/models/company.model';

export class InsertCompanyMapper implements IGraphqlMapper {
  company?: CompanyModel;

  returnFromJson(json: any): CompanyModel {
    const value = json['insertCompany'];
    this.company = {
      id: value['id'],
      idpId: value['idpIdentifier'],
      name: value['name'],
      description: value['description'],
      businessName: value['businessName'],
      vat: value['vat'],
      address: value['address'],
      numUsers: 0,
      brandingEnabled: value['brandingEnabled'],
      // branding: JSON.parse(value['branding']),
      branding: {} as any,
    };
    return this.company;
  }
}
