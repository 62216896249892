import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';

@Component({
  selector: 'app-custom-confirmation-dialog',
  templateUrl: './custom-confirmation-dialog.component.html',
  styleUrls: ['./custom-confirmation-dialog.component.scss'],
})
export class CustomConfirmationDialogComponent {
  constructor(public dialogRef: MatDialogRef<CustomConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private providerService: ProviderService) {}

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
