import { GetUsersGraphqlMapper } from '../../shared/mappers/user.mapper';
import { UserModel } from '../../shared/models/user.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';
import { debounceTime, distinctUntilChanged, skip, Subscription } from 'rxjs';
import { ProviderService } from '../../core/provider.service';
import { DeleteUserGQL, ListUsersGQL } from '../../../graphql/generated';
import { UserDetailsComponent } from '../dialogs/user-details/user-details.component';
import { ModifyUserComponent } from '../dialogs/modify-user/modify-user.component';
import { LogLevel } from '../../models/log-level';
import { FilterTableComponent } from '../../shared/abstract-classes/filter-table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { companyChangedSubject } from '../navbar/navbar.component';
import { CompanyModel } from '../../shared/models/company.model';
import { CreateUserComponent } from '../dialogs/create-user/create-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class UsersComponent extends FilterTableComponent implements OnInit, OnDestroy {
  dataSource?: GraphqlTableDatasource<UserModel>;
  company?: CompanyModel;

  usersMapper: GetUsersGraphqlMapper = new GetUsersGraphqlMapper();

  tableDefinitions = [
    { def: 'name', title: _('USERS.NAME'), sortable: true },
    { def: 'surname', title: _('USERS.SURNAME'), sortable: true },
    { def: 'email', title: _('USERS.EMAIL'), sortable: true },
    { def: 'userType', secondDef: 'name', title: _('USERS.USER_TYPE') },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen-to-square',
      text: _('USERS.EDIT_USER'),
      styleClass: 'table-action-edit',
      callback: (user: any): void => {
        this.modifyUser(user);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('USERS.DELETE_USER'),
      styleClass: 'table-action-delete',
      callback: (user: any): void => {
        this.deleteUser(user);
      },
    },
  ];

  private deleteUserSub?: Subscription;

  constructor(
    private providerService: ProviderService,
    private listUsersGQL: ListUsersGQL,
    private deleteUserGQL: DeleteUserGQL
  ) {
    super();
    this.subscriptions.add(
      this.debounceSubject
        .pipe(debounceTime(500), distinctUntilChanged(), skip(1))
        .subscribe((value) => {
          this.filterObservable.next(value);
        })
    );
    let variables = {};

    if (!this.providerService.companyService.getIsAdminSelected()) {
      variables = {
        companyId: this.providerService.companyService.getSelectedCompany()?.id,
      };
    }

    this.dataSource = new GraphqlTableDatasource<UserModel>(
      this.providerService,
      this.listUsersGQL,
      this.usersMapper,
      variables
    );
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      companyChangedSubject.pipe().subscribe((company) => {
        if (company) {
          this.company = company;
          const variables: { [key: string]: string } = {};
          if (!this.providerService.companyService.getIsAdminSelected()) {
            variables['companyId'] = company.id;
          }
          if (!this.dataSource) {
            this.dataSource = new GraphqlTableDatasource<UserModel>(
              this.providerService,
              this.listUsersGQL,
              this.usersMapper,
              variables
            );
          } else {
            this.dataSource.changeVariables(variables);
          }
        }
      })
    );
  }

  userRowClicked(row: any): void {
    this.providerService.utilService.openDialog(
      UserDetailsComponent,
      { row },
      'create-modal'
    );
  }

  addUser(): void {
    this.providerService.utilService.openDialog(
      CreateUserComponent,
      {
        companyId: this.company?.id,
        companyIdpId: this.company?.idpId,
        callback: () => {
          this.refreshCallback();
        },
      },
      'create-modal'
    );
  }

  refreshCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  async deleteUser(user: any): Promise<void> {
    if (
      await this.providerService.utilService.openConfirmationDialog(
        _('USERS.DELETE_USER_CONFIRMATION_DIALOG_TITLE'),
        _('USERS.DELETE_USER_CONFIRMATION_DIALOG_MESSAGE')
      )
    ) {
      this.deleteUserSub = this.deleteUserGQL
        .mutate({ id: user.userId }, { errorPolicy: 'all' })
        .subscribe((value) => {
          if (value.data?.deleteUser) {
            this.filterObservable.next(this.lastDebounceValue);
          } else if (value.errors && value.errors.length > 0) {
            let stringToShow = '';
            value.errors.forEach((err) => {
              stringToShow = stringToShow.concat(err.message, ' ');
            });
            this.providerService.utilService.showMessage(
              stringToShow.trim(),
              LogLevel.error
            );
          } else {
            this.providerService.utilService.showMessage(
              _('USERS.DELETE_USER_ERROR'),
              LogLevel.error
            );
          }
        });
    }
  }

  private modifyUser(user: any) {
    this.providerService.utilService.openDialog(
      ModifyUserComponent,
      {
        user,
        callback: () => {
          this.modifyUserCallback();
        },
      },
      'modify-modal'
    );
  }

  private modifyUserCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }
}
