import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as Apollo from 'apollo-angular';
import {
  debounceTime,
  exhaustMap,
  firstValueFrom,
  Observable,
  scan,
  startWith,
  Subject,
  Subscriber,
  switchMap,
} from 'rxjs';
import { IGraphqlPagedMapper } from 'src/app/models/interfaces/I-graphql-paged-mapper';
import { ProviderService } from '../../provider.service';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-select-infinite-scroll',
  templateUrl: './select-infinite-scroll.component.html',
  styleUrls: ['./select-infinite-scroll.component.scss'],
})
export class SelectInfiniteScrollComponent implements OnInit {
  @Input()
  icon = '';
  @Input()
  label = '';
  @Input()
  dataShowSelector = '';
  @Input()
  query?: Apollo.Query<any, any>;
  @Input()
  mapper?: IGraphqlPagedMapper;
  @Input()
  params?: { [key: string]: string };
  @Input()
  selectFirstOption = false;
  @Input()
  selectedData?: any;
  @Output()
  selectionChanged = new EventEmitter<any>();
  loading = false;
  pageSize = 10;
  total = 0;
  data$: Observable<any[]> | undefined;

  public exFormGroup = new FormGroup({
    dataFilterController: new FormControl(''),
  });
  private nextPage$ = new Subject();

  constructor(private providerService: ProviderService) {}

  async ngOnInit(): Promise<void> {
    this.data$ = this.getItems('', 0);
    if (this.selectedData) {
      this.selectionChanged.emit(this.selectedData);
      this.exFormGroup.controls.dataFilterController.setValue(
        this.selectedData
      );
    }
    if (this.selectFirstOption) {
      const data = await firstValueFrom(this.data$);
      if (data && data.length > 0) {
        this.exFormGroup.controls.dataFilterController.setValue(data[0]);
        this.selectionChanged.emit(data[0]);
      }
    }
    // Note: listen for search text changes
    const filter$ =
      this.exFormGroup.controls.dataFilterController.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        filter((q) => typeof q === 'string')
      );

    this.data$ = filter$.pipe(
      switchMap((filterString) => {
        //Note: Reset the page with every new seach text
        let currentPage = 0;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap((_) => this.getItems(filterString, currentPage)),
          tap(() => currentPage++),
          takeWhile((p: any[]) => p.length > 0),
          scan((allData: any, newData: any) => allData.concat(newData), [])
        );
      })
    );
  }

  displayWith(element: any): any {
    return element ? element.name : null;
  }

  onScroll(): void {
    //Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next('');
  }

  getItems(filterString: any, page: number): Observable<any[]> {
    return new Observable((subscriber: Subscriber<any[]>) => {
      const variables: {
        [key: string]: any;
      } = {};
      variables['page'] = {
        filter: filterString,
        limit: this.pageSize,
        orderCol: '',
        begins: page * this.pageSize,
        order: 'asc',
      };
      for (const key in this.params) {
        if (Object.hasOwn(this.params, key)) {
          variables[key] = this.params[key];
        }
      }
      if (this.query && this.mapper) {
        this.providerService.graphqlService
          .fetch<any>(this.query, variables, this.mapper)
          .then((result) => {
            this.total = result.total;
            subscriber.next(result.items);
            subscriber.complete();
          });
      }
    });
  }

  optionSelected($event: MatAutocompleteSelectedEvent): void {
    this.selectionChanged.emit($event.option.value);
  }

  getOptionShowValue(option: any): any {
    return option[this.dataShowSelector];
  }
}
