<div class="confirmation-dialog-container">
  <span class="dialog-title">
    {{data.title | i18next}}
  </span>
  <div class="dialog-text">
    <span>
      {{data.message | i18next}}
    </span>
  </div>
  <div class="buttons-container">
    <button mat-raised-button color="primary" (click)="confirm()"><span>{{'CONFIRMATION_DIALOG.CONFIRM' | i18next}}</span></button>
    <button mat-raised-button (click)="cancel()"><span>{{'CONFIRMATION_DIALOG.CANCEL' | i18next}}</span></button>
  </div>
</div>
