import { CompanyModel } from 'src/app/shared/models/company.model';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';

export class ListCompaniesMapper implements IGraphqlPagedMapper {
  items: CompanyModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: CompanyModel[]; total: number } {
    this.items = [];
    const pagedCompanies = json['listCompanies'];
    pagedCompanies['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        idpId: value['idpIdentifier'],
        name: value['name'],
        businessName: value['businessName'],
        numUsers: value['numUsers'],
        description: value['description'],
        vat: value['vat'],
        address: value['address'],
        brandingEnabled: value['brandingEnabled'],
        branding: JSON.parse(value['branding']),
      });
    });
    this.total = pagedCompanies['total'];
    return { items: this.items, total: this.total };
  }
}
