import { Injectable } from '@angular/core';
import {
  CognitoIdentity,
  GetCredentialsForIdentityCommandOutput,
  GetIdCommandOutput,
} from '@aws-sdk/client-cognito-identity';
import { environment } from '../../../environments/environment';
import { IdToken } from '@auth0/auth0-angular';
import { OAuthService } from '../net-utils/user-authentication/o-auth.service';
import { S3, GetObjectCommandOutput } from '@aws-sdk/client-s3';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  private bucket?: S3;

  constructor(private oauthService: OAuthService) {}

  async uploadImageFile(file: any, name: string): Promise<string> {
    await this.prepareS3Bucket();
    return new Promise((resolve, reject) => {
      const contentType = file.type;
      const params = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Bucket: environment.bucket.name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Key: environment.bucket.folder + name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Body: file,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ContentType: contentType,
      };
      this.bucket?.putObject(params, (err: Error, _data: any): void => {
        if (err) {
          reject(err);
        } else {
          resolve(
            `https://${
              environment.bucket.name
            }.eu-west-1.amazonaws.com/${name.replaceAll(' ', '+')}`
          );
        }
      });
    });
  }

  async deleteFile(key: string): Promise<boolean> {
    await this.prepareS3Bucket();
    return new Promise((resolve, reject) => {
      const params = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Bucket: environment.bucket.name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Key: environment.bucket.folder + key,
      };
      this.bucket?.deleteObject(
        params,
        (err: any, _data: any | undefined): void => {
          if (err) {
            reject(err);
          } else {
            resolve(true);
          }
        }
      );
    });
  }

  async getFile(key: string): Promise<GetObjectCommandOutput> {
    await this.prepareS3Bucket();
    return new Promise((resolve, reject) => {
      const params = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Bucket: environment.bucket.name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Key: environment.bucket.folder + key,
      };
      this.bucket?.getObject(
        params,
        async (
          err: any,
          data: GetObjectCommandOutput | undefined
        ): Promise<void> => {
          if (err) {
            reject(err);
          } else if (data) {
            resolve(data);
          } else {
            reject('No object returned');
          }
        }
      );
    });
  }

  private getCognitoIdentity(): CognitoIdentity {
    return new CognitoIdentity({
      region: 'eu-west-1',
    });
  }

  private async prepareS3Bucket(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const sub = this.oauthService
          ?.getIdToken()
          .subscribe(async (token: IdToken) => {
            if (token) {
              try {
                const logins: { [key: string]: string } = {};
                logins[environment.auth0.domain] = token?.__raw;

                const cognitoIdentity = this.getCognitoIdentity();

                // 1) Get an identity from the identity pool of Cognito based on the grant given by the id token of the external IdP.
                const getIdCommandOutput: GetIdCommandOutput =
                  await cognitoIdentity?.getId({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    IdentityPoolId: environment.cognito.identityPoolId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Logins: logins,
                  });

                // 2) Based on the identity generate a set of temporary credentials.
                const getCredentialsForIdentityCommandOutput: GetCredentialsForIdentityCommandOutput =
                  await cognitoIdentity?.getCredentialsForIdentity({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    IdentityId: getIdCommandOutput.IdentityId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Logins: logins,
                  });

                // 3) Given the credentials, set up the bucket
                this.bucket = new S3({
                  region: 'eu-west-1',
                  credentials: {
                    accessKeyId:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.AccessKeyId ?? '',
                    secretAccessKey:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.SecretKey ?? '',
                    sessionToken:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.SessionToken ?? '',
                  },
                });
                sub?.unsubscribe();
                resolve();
              } catch (error2) {
                reject(error2);
              }
            } else {
              reject('no token retrieved');
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}
