import { Component, OnInit } from '@angular/core';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';
import { ProviderService } from '../../core/provider.service';
import { DeleteLicenseGQL, ListLicensesGQL } from '../../../graphql/generated';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { FilterTableComponent } from '../../shared/abstract-classes/filter-table';
import { LicenseModel } from '../../shared/models/license.model';
import { DeleteLicenseMapper, LicenseMapper } from './license.mapper';
import { LicenseInfoComponent } from '../dialogs/license-info/license-info.component';
import { CreateLicenseComponent } from '../dialogs/create-license/create-license.component';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { companyChangedSubject } from '../navbar/navbar.component';
import { LogLevel } from '../../models/log-level';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent extends FilterTableComponent implements OnInit {
  dataSource?: GraphqlTableDatasource<LicenseModel>;
  tableDefinitions = [
    { def: 'id', title: _('LICENSES.ID'), sortable: true },
    {
      def: 'company',
      secondDef: 'name',
      title: _('LICENSES.COMPANY'),
      sortable: false,
    },
    {
      def: 'machine',
      secondDef: 'name',
      title: _('LICENSES.MACHINE'),
      sortable: false,
    },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-trash-can',
      text: _('LICENSES.DELETE_LICENSE'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteLicense(row);
      },
    },
  ];

  licenseMapper: LicenseMapper = new LicenseMapper();

  constructor(private providerService: ProviderService, private listLicenseGQL: ListLicensesGQL, private deleteLicenseGQL: DeleteLicenseGQL) {
    super();
    this.subscriptions.add(
      this.debounceSubject.pipe(debounceTime(500), distinctUntilChanged(), skip(1)).subscribe((value) => {
        this.filterObservable.next(value);
      })
    );

    let variables = {};

    if (!this.providerService.companyService.getIsAdminSelected()) {
      variables = {
        companyId: this.providerService.companyService.getSelectedCompany()?.id,
      };
    }

    this.dataSource = new GraphqlTableDatasource<LicenseModel>(this.providerService, this.listLicenseGQL, this.licenseMapper, variables);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      companyChangedSubject.pipe().subscribe((company) => {
        const variables: { [key: string]: string } = {};
        if (!this.providerService.companyService.getIsAdminSelected()) {
          variables['companyId'] = company.id;
        }
        if (!this.dataSource) {
          this.dataSource = new GraphqlTableDatasource<LicenseModel>(this.providerService, this.listLicenseGQL, this.licenseMapper, variables);
        } else {
          this.dataSource.changeVariables(variables);
        }
      })
    );
  }

  async deleteLicense(row: any): Promise<void> {
    if (await this.providerService.utilService.openConfirmationDialog(_('LICENSES.DELETE_LICENSE_CONFIRMATION_DIALOG_TITLE'), _('LICENSES.DELETE_LICENSE_CONFIRMATION_DIALOG_MESSAGE'))) {
      try {
        await this.providerService.graphqlService.mutate(this.deleteLicenseGQL, { id: row.id }, new DeleteLicenseMapper());
        this.dataSource?.loadElements();
      } catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      }
    }
  }

  licenseRowClicked($event: any): void {
    this.providerService.utilService.openDialog(LicenseInfoComponent, { license: $event }, 'create-modal');
  }

  addLicense(): void {
    this.providerService.utilService.openDialog(
      CreateLicenseComponent,
      {
        callback: () => {
          this.addLicenseCallback();
        },
      },
      'create-modal'
    );
  }

  private addLicenseCallback() {
    this.dataSource?.loadElements();
  }
}
