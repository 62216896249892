<br><br>

<header>
    <h1>{{company?.name}}</h1>
    <p>{{company?.businessName}}</p>
</header>

<div class="container">
    <div class="info-card">
        <mat-card>
            <div><span>Name:</span> {{company?.name}}</div>
            <div><span>Number of users:</span> {{company?.numUsers}}</div>
            <div><span>Business Name:</span> {{company?.businessName}}</div>
            <div><span>Address:</span> {{company?.address}}</div>
            <div><span>Description:</span> {{company?.description}}</div>
            <div *ngIf="company?.brandingEnabled">
                <img src="{{company?.branding?.logo}}" alt="">
                <div>{{company?.branding?.colors?.main}}</div>
            </div>
        </mat-card>
    </div>

    <div>
        <app-graphql-table-ui
                *ngIf="dataSource"
                [datasource]="dataSource"
                [tableDefinitions]="tableDefinitions"
                [actionDefinitions]="actionDefinitions"
                [pageSize]="10"
                [pageSizeOptions]="[10,20,30]"
                [filterObservable]="filterObservable"
                [hasRowAction]="false">
        </app-graphql-table-ui>
    </div>
</div>
