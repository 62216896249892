import { Component, OnInit } from '@angular/core';
import { FilterTableComponent } from '../../shared/abstract-classes/filter-table';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';
import { ProviderService } from '../../core/provider.service';
import { DeleteMachineGQL, ListMachinesGQL } from '../../../graphql/generated';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
  Subscription,
} from 'rxjs';
import { MachineModel } from '../../shared/models/machine.model';
import { MachineMapper } from './machine.mapper';
import { ModifyMachineComponent } from '../dialogs/machine/modify-machine/modify-machine.component';
import { CreateMachineComponent } from '../dialogs/machine/create-machine/create-machine.component';
import { MachineDetailsComponent } from '../dialogs/machine/machine-details/machine-details.component';
import { LogLevel } from '../../models/log-level';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { companyChangedSubject } from '../navbar/navbar.component';
import { CompanyModel } from '../../shared/models/company.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss'],
})
export class MachinesComponent extends FilterTableComponent implements OnInit {
  company?: CompanyModel;
  dataSource?: GraphqlTableDatasource<MachineModel>;
  changeVariablesSubject: BehaviorSubject<{ [key: string]: string }> =
    new BehaviorSubject<{ [key: string]: string }>({});
  tableDefinitions = [
    { def: 'name', title: _('MACHINES.NAME'), sortable: true },
    { def: 'code', title: _('MACHINES.CODE'), sortable: true },
    { def: 'serialNumber', title: _('MACHINES.SERIAL_NUMBER'), sortable: true },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: _('MACHINES.MODIFY_MACHINE'),
      styleClass: 'table-action',
      callback: (row: any): void => {
        this.modifyMachine(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('MACHINES.DELETE_MACHINE'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteMachine(row);
      },
    },
  ];

  machineMapper: MachineMapper = new MachineMapper();
  private deleteMachineSub?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private providerService: ProviderService,
    private listMachinesGQL: ListMachinesGQL,
    private deleteMachineGQL: DeleteMachineGQL
  ) {
    super();
    this.subscriptions.add(
      this.debounceSubject
        .pipe(debounceTime(500), distinctUntilChanged(), skip(1))
        .subscribe((value) => {
          this.filterObservable.next(value);
        })
    );
    let variables = {};

    if (!this.providerService.companyService.getIsAdminSelected()) {
      variables = {
        companyId: this.providerService.companyService.getSelectedCompany()?.id,
      };
    }
    this.dataSource = new GraphqlTableDatasource<MachineModel>(
      this.providerService,
      this.listMachinesGQL,
      this.machineMapper,
      variables
    );
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      companyChangedSubject.pipe().subscribe((company) => {
        const variables: { [key: string]: string } = {};
        if (!this.providerService.companyService.getIsAdminSelected()) {
          variables['companyId'] = company.id;
        }
        if (!this.dataSource) {
          this.dataSource = new GraphqlTableDatasource<MachineModel>(
            this.providerService,
            this.listMachinesGQL,
            this.machineMapper,
            variables
          );
        } else {
          this.dataSource.changeVariables(variables);
        }
      })
    );
  }

  async deleteMachine(row: any): Promise<void> {
    if (
      await this.providerService.utilService.openConfirmationDialog(
        _('MACHINES.DELETE_MACHINE_CONFIRMATION_DIALOG_TITLE'),
        _('MACHINES.DELETE_MACHINE_CONFIRMATION_DIALOG_MESSAGE')
      )
    ) {
      this.deleteMachineSub = this.deleteMachineGQL
        .mutate({ id: row.id }, { errorPolicy: 'all' })
        .subscribe((value) => {
          if (value.data?.deleteMachine) {
            this.filterObservable.next(this.lastDebounceValue);
          } else {
            if (value.errors && value.errors.length > 0) {
              let stringToShow = '';
              value.errors.forEach((err) => {
                stringToShow = stringToShow.concat(err.message, ' ');
              });
              this.providerService.utilService.showMessage(
                stringToShow.trim(),
                LogLevel.error
              );
            } else {
              this.providerService.utilService.showMessage(
                _('MACHINES.DELETE_MACHINE_ERROR'),
                LogLevel.error
              );
            }
          }
        });
    }
  }

  modifyMachine(row: any): void {
    this.providerService.utilService
      .openDialog(
        ModifyMachineComponent,
        {
          machine: row,
          callback: () => {
            this.machineCallback();
          },
        },
        'create-modal'
      )
      .afterClosed()
      .subscribe(() => this.machineCallback());
  }

  machineRowClicked(row: any): void {
    this.providerService.utilService.openDialog(
      MachineDetailsComponent,
      { row },
      'create-modal'
    );
  }

  addMachine(): void {
    this.providerService.utilService
      .openDialog(
        CreateMachineComponent,
        {
          callback: () => {
            this.machineCallback();
          },
        },
        'create-modal'
      )
      .afterClosed()
      .subscribe(() => this.machineCallback());
  }

  private machineCallback() {
    this.filterObservable.next(this.lastDebounceValue);
  }
}
