import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';
import { IUser } from '../../../models/interfaces/i-user';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  constructor(private providerService: ProviderService) {}

  ngOnInit(): void {
    this.providerService.oAuthService.signIn({} as IUser).then();
  }
}
