import { UserModel } from '../models/user.model';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';
import { IGraphqlExportMapper } from '../../models/interfaces/i-graphql-export-mapper';
import { IChipItem } from '../../models/interfaces/i-chip-item';

export class GetUsersGraphqlMapper implements IGraphqlPagedMapper {
  items: UserModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: UserModel[]; total: number } {
    this.items = [];
    let pagedUser = [];

    pagedUser = json['listUsers'];

    pagedUser['items'].forEach((value: any) => {
      this.items.push({
        userId: value['id'],
        name: value['name'],
        surname: value['surname'],
        email: value['email'],
        idpIdentifier: value['idp_identifier'],
        userType: value['userType'],
      });
    });
    this.total = pagedUser['total'];
    return { items: this.items, total: this.total };
  }
}

export class ListUserTypesChipsMapper implements IGraphqlExportMapper {
  elements: IChipItem[] = [];

  returnFromJson(json: any): any {
    const value = json['listUserTypes'];
    this.elements = [];
    value.forEach((userType: any) => {
      this.elements.push({
        id: userType['id'],
        description: userType['name'],
      });
    });
    return this.elements;
  }
}

export class GetUserMapper implements IGraphqlMapper {
  user?: UserModel;
  returnFromJson(json: any): UserModel {
    console.log(json);
    const value = json['getUser'];
    this.user = {
      userId: value['id'],
      email: value['email'],
      idpIdentifier: value['idp_identifier'],
      name: value['name'],
      surname: value['surname'],
      userType: value['userType'],
    };
    return this.user;
  }
}
