import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { IApplicationPermission } from './create-application.models';
import { BehaviorSubject, Observable } from 'rxjs';

export class CreateApplicationDatasource
  // eslint-disable-next-line prettier/prettier
  implements DataSource<IApplicationPermission> {
  private tableSubject = new BehaviorSubject<IApplicationPermission[]>([]);

  connect(
    _collectionViewer: CollectionViewer
  ): Observable<IApplicationPermission[]> {
    return this.tableSubject.asObservable();
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    this.tableSubject.complete();
  }

  nextItems(items: IApplicationPermission[]): void {
    this.tableSubject.next(items);
  }
}
