<div class="sidebar {{ssToggle ? 'set-away' : ''}}">

    <ng-container class="nav-menu">
        <ng-container>
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/applications']">
                <i class="fa-solid fa-grid"></i>
                <span>{{'SIDEBAR.APPLICATIONS' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="isAdmin && !isCompanySelected">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/companies']">
                <i class="fa-solid fa-buildings"></i>
                <span>{{'SIDEBAR.COMPANIES' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="(isAdmin && isCompanySelected) || isOperator || isTechnician">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/company']">
                <i class="fa-solid fa-buildings"></i>
                <span>{{'SIDEBAR.COMPANY' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="isAdmin || isOperator ">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/users']">
                <i class="fa-solid fa-user"></i>
                <span>{{'SIDEBAR.USERS' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="isAdmin || isOperator || isTechnician">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/areas']">
                <i class="fa-solid fa-location-dot"></i>
                <span>{{'SIDEBAR.AREAS' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="isAdmin">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/licenses']">
                <i class="fa-solid fa-file-signature"></i>
                <span>{{'SIDEBAR.LICENSES' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="isAdmin || isOperator || isTechnician">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/machines']">
                <i class="fa-solid fa-computer-classic"></i>
                <span>{{'SIDEBAR.MACHINES' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
        <ng-container class="nav-item" *ngIf="isAdmin || isOperator">
            <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/branding']">
                <i class="fa-solid fa-paint-roller"></i>
                <span>{{'SIDEBAR.BRANDING' | i18next}}</span>
            </button>
            <mat-divider></mat-divider>
        </ng-container>
    </ng-container>

    <div class="spacer"></div>

    <div class="copyright-version">
        <p>
            <span>&copy;beSharp 2023. All right reserved.</span>
            <br>
            <small>V0.0.1</small>
        </p>
    </div>
</div>
