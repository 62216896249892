import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyModel } from '../../../../shared/models/company.model';
import { ListCompaniesMapper } from '../../../companies/companies.mappers';
import { MachineTypeModel } from '../../../../shared/models/machine-type.model';
import { AreaModel } from '../../../../shared/models/area.model';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { ProviderService } from '../../../../core/provider.service';
import {
  ListAreasGQL,
  ListCompaniesGQL,
  ListMachineTypesGQL,
  OrderType,
  UpdateMachineGQL,
} from '../../../../../graphql/generated';
import { LogLevel } from '../../../../models/log-level';
import { MachineMapper } from '../machine.mapper';

@Component({
  selector: 'app-modify-machine',
  templateUrl: './modify-machine.component.html',
  styleUrls: ['./modify-machine.component.scss'],
})
export class ModifyMachineComponent {
  form = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    area: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
    machineType: new FormControl('', [Validators.required]),
  });
  loading = false;
  selectedCompany?: CompanyModel;
  mapper = new ListCompaniesMapper();

  public machineTypes: MachineTypeModel[] = [];
  public areas: AreaModel[] = [];

  private listMachineTypesSub?: Subscription;
  private listAreasSub?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ModifyMachineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private providerService: ProviderService,
    public listCompanies: ListCompaniesGQL,
    public listMachineTypesGQL: ListMachineTypesGQL,
    public listAreasGQL: ListAreasGQL,
    public updateMachineGQL: UpdateMachineGQL
  ) {
    this.form.controls.machineType.disable();
    this.form.controls.area.disable();
    this.getMachineTypes().then(() => this.form.controls.machineType.enable());
    this.getAreas(data.machine.companyId).then(() =>
      this.form.controls.area.enable()
    );

    this.form.controls.id.setValue(data.machine.id);
    this.form.controls.name.setValue(data.machine.name);
    this.form.controls.code.setValue(data.machine.code);
    this.form.controls.serialNumber.setValue(data.machine.serialNumber);
    this.form.controls.machineType.setValue(data.machine.machineTypeId);
    this.form.controls.area.setValue(data.machine.areaId);
    this.form.controls.company.setValue(data.machine.companyId);
  }

  async getMachineTypes(): Promise<void> {
    this.listMachineTypesSub = await this.listMachineTypesGQL
      .watch({}, { errorPolicy: 'all' })
      .valueChanges.subscribe((value) => {
        this.machineTypes = [];
        value.data.listMachineTypes?.forEach((machineType) => {
          this.machineTypes.push({
            id: machineType?.id ?? '',
            name: machineType?.name ?? '',
            description: machineType?.description ?? '',
          });
        });
      });
  }

  async getAreas(companyId: string): Promise<void> {
    this.listAreasSub = await this.listAreasGQL
      .watch(
        {
          page: {
            begins: 0,
            filter: '',
            limit: 99,
            order: OrderType.Asc,
            orderCol: '',
          },
          companyId,
        },
        { errorPolicy: 'all' }
      )
      .valueChanges.subscribe((value) => {
        this.areas = [];
        value.data.listAreas?.items.forEach((area) => {
          this.areas.push({
            id: area?.id ?? '',
            city: area?.city ?? '',
            region: area?.region ?? '',
            location: area?.location ?? '',
            companyId: area?.company?.id ?? '',
            companyName: area?.company?.name ?? '',
          });
        });
      });
  }

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  companySelectionChanged(event: any): void {
    this.form.controls.company.setValue(event.id);
  }

  async modifyMachine(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new MachineMapper();
        const variables = {
          id: this.form.controls.id.value,
          name: this.form.controls.name.value,
          code: this.form.controls.code.value,
          serialNumber: this.form.controls.serialNumber.value,
          areaId: this.form.controls.area.value,
          machineTypeId: this.form.controls.machineType.value,
        };
        await this.providerService.graphqlService.mutate(
          this.updateMachineGQL,
          variables,
          mapper
        );
        this.loading = false;
        this.closeModal();
      } catch (e: any) {
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
        this.loading = false;
      }
    }
  }
}
