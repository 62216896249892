import { CompanyModel } from 'src/app/shared/models/company.model';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';

export class GetCompanyMapper implements IGraphqlMapper {
  company?: CompanyModel;

  returnFromJson(json: any): CompanyModel {
    const value = json['getCompany'];
    this.company = {
      id: value['id'],
      idpId: value['idpIdentifier'],
      name: value['name'],
      businessName: value['businessName'],
      numUsers: value['numUsers'],
      description: value['description'],
      vat: value['vat'],
      address: value['address'],
      brandingEnabled: value['brandingEnabled'],
      branding: JSON.parse(value['branding']),
    };
    return this.company;
  }
}

export class DeleteUserMapper implements IGraphqlMapper {
  result = false;

  returnFromJson(json: any): boolean {
    const value = json['deleteUser'];
    this.result = value;
    return this.result;
  }
}
