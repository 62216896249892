import { Injectable } from '@angular/core';
import { IAuthenticator } from '../../../models/interfaces/i-authenticator';
import { HttpVerb } from '../../../models/http-verb';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { SignatureV4 } from '@aws-sdk/signature-v4';

@Injectable({
  providedIn: 'root',
})
export class IamAuthenticatorService implements IAuthenticator {
  // How to solve proper signing: https://github.com/aws/aws-sdk-js-v3/issues/3590
  sigV4: SignatureV4;

  constructor() {
    this.sigV4 = new SignatureV4({
      service: 'execute-api',
      region: 'eu-west-1',
      credentials: {
        accessKeyId: 'AKIAVJQY53S5EOFCH5UT',
        secretAccessKey: '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',
      },
      sha256: Sha256,
    });
  }

  async getRequest(
    path: string,
    method: HttpVerb,
    headers: { [key: string]: string },
    body?: string
  ): Promise<any> {
    const apiUrl = new URL(path);
    return await this.sigV4.sign(
      {
        method: method.toUpperCase(),
        hostname: apiUrl.host,
        path: apiUrl.pathname,
        protocol: apiUrl.protocol,
        body,
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
          host: apiUrl.hostname,
        },
      },
      { signingDate: new Date() }
    );
  }

  isAuthenticationError(statusCode: number): boolean {
    return statusCode === 403;
  }

  manageError(response: any): Promise<any> {
    return Promise.reject(
      `Status code: ${response.statusCode} - error: ${response.body}`
    );
  }
}
