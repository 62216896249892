import { Component, Inject, OnInit } from '@angular/core';
import { UserModel, UserTypeModel } from '../../../shared/models/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { ListCompaniesByUserIdGQL, ListCompaniesGQL, ListUserTypesGQL, UpdateUserGQL, UpdateUserMutationVariables } from '../../../../graphql/generated';
import { LogLevel } from '../../../models/log-level';
import { CompanyModel } from '../../../shared/models/company.model';
import { ListCompaniesByUserIdMapper, ModifyUserMapper } from './modify-user.mapper';
import { ListUserTypesMapper } from '../create-user/create-user.mappers';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss'],
})
export class ModifyUserComponent implements OnInit {
  result: any = {};
  loading = false;
  userTypes: UserTypeModel[] = [];
  companies: { idCompany: string; nameCompany: string }[] = [];
  listCompaniesMapper = new ListCompaniesByUserIdMapper();
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    userType: new FormControl('', [Validators.required]),
    companies: new FormControl({} as any, [Validators.required]),
  });
  companySelectionDisabled = true;
  selectedCompanies: CompanyModel[] = [];
  initiating = false;
  constructor(public dialogRef: MatDialogRef<ModifyUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public providerService: ProviderService, private modifyUserQuery: UpdateUserGQL, public listUserTypes: ListUserTypesGQL, public listCompaniesGQL: ListCompaniesGQL, private listCompaniesByUserIdGQL: ListCompaniesByUserIdGQL) {}

  ngOnInit(): void {
    this.initializeVariables(this.data.user, this.data.fromCompanyDetails);
  }

  async getUserTypes(): Promise<void> {
    this.userTypes = await this.providerService.graphqlService.fetch(this.listUserTypes, {}, new ListUserTypesMapper());
  }

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async modifyUser(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const variables = {
          id: this.data.user.userId,
          email: this.data.user.email,
          name: this.form.controls.name.value ?? this.data.user.name,
          surname: this.form.controls.surname.value ?? this.data.user.surname,
          userTypeId: this.form.controls.userType.value ?? this.data.user.userType,
          companiesIds: this.form.controls.companies.value?.map((value: CompanyModel) => value.id) ?? this.data.companyIds,
        } as UpdateUserMutationVariables;
        await this.providerService.graphqlService.mutate(this.modifyUserQuery, variables, new ModifyUserMapper());
        if (this.data.callback) {
          this.data.callback();
        }
        this.closeModal();
      } catch (error: any) {
        this.providerService.utilService.showMessage(error.toString(), LogLevel.error);
      } finally {
        this.loading = false;
      }
    }
  }

  companySelectionChanged($event: any): void {
    this.form.controls.companies.setValue($event);
  }

  async initializeVariables(user: UserModel, fromCompanyDetails: boolean): Promise<void> {
    this.initiating = true;
    this.companySelectionDisabled = fromCompanyDetails;
    const companies = await this.providerService.graphqlService.fetch<{ items: CompanyModel[]; total: number }>(this.listCompaniesByUserIdGQL, { id: user.userId }, new ListCompaniesByUserIdMapper());
    this.selectedCompanies = companies.items;
    await this.getUserTypes();
    this.form.controls.name.setValue(user.name);
    this.form.controls.surname.setValue(user.surname);
    this.form.controls.userType.setValue(user.userType.id);
    this.form.markAllAsTouched();
    this.markFormAsDirty();
    this.initiating = false;
  }

  private markFormAsDirty(): void {
    this.form.controls.name.markAsDirty();
    this.form.controls.surname.markAsDirty();
    this.form.controls.userType.markAsDirty();
  }
}
