import { Component, Input, OnInit } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { companyChangedSubject } from '../navbar/navbar.component';
import { skip } from 'rxjs';
import { AntiMemLeak } from '../../shared/abstract-classes/anti-mem-leak';
import { Constants } from '../../models/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends AntiMemLeak implements OnInit {
  @Input() ssToggle = false;
  isAdmin = false;
  isOperator = false;
  isTechnician = false;
  isCompanySelected = false;

  constructor(public providerService: ProviderService) {
    super();
    this.ssToggle = false;
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      companyChangedSubject.pipe(skip(1)).subscribe((company) => {
        this.checkIfCompanyIsSelected(company);
        console.log('sidebar company', company);
      })
    );

    const userAndRole = await this.providerService.roleService.getUserAndRole();
    console.log(userAndRole);
    this.isAdmin = userAndRole.userType.name === Constants.superAdminRole;
    this.isOperator = userAndRole.userType.name === Constants.operatorRole;
    this.isTechnician = userAndRole.userType.name === Constants.technicianRole;
    this.ssToggle = userAndRole.userType.name === 'user';
  }

  navigateTo(href: string): void {
    this.providerService.utilService.navigateTo(href).then();
  }

  checkIfCompanyIsSelected(company: any): void {
    if (company) {
      this.isCompanySelected = company.name !== Constants.superAdmin;
    }
  }
}
