<h1 mat-dialog-title>Create Area</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">

    <mat-form-field appearance="fill">
      <mat-label>{{'CREATE_AREA.REGION' | i18next}}</mat-label>
      <input matInput type="text" id="region" name="region" formControlName="region" placeholder="{{'CREATE_AREA.REGION_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'CREATE_AREA.REGION_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'region'" [icon]="'fa-regular fa-circle-exclamation'" [message]="'Region required'"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>City</mat-label>
      <input matInput type="text" id="city" name="city" formControlName="city" placeholder="{{'CREATE_AREA.CITY_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>Area city name goes here</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'city'" [icon]="'fa-regular fa-circle-exclamation'" [message]="'City required'"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>Location</mat-label>
      <input matInput type="text" id="location" name="location" formControlName="location" placeholder="{{'CREATE_AREA.LOCATION_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>Area location goes here</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'location'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_AREA.LOCATION_ERROR' | i18next}}"></app-validator-ui>

    <app-select-infinite-scroll
      [icon] = "'fa fa-solid fa-buildings'"
      label = "{{'CREATE_AREA.COMPANIES_LABEL' | i18next}}"
      [dataShowSelector] = "'name'"
      [query] = "listCompanies"
      [mapper] = "mapper"
      [params] = "{}"
      (selectionChanged)="companySelectionChanged($event)">
    </app-select-infinite-scroll>
  </form>

</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="createArea();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'CREATE_AREA.CREATE_AREA' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'CREATE_AREA.CANCEL' | i18next}}</span>
  </a>
</div>
