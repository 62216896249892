/* eslint-disable */

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Application = {
  __typename?: 'Application';
  auth0Description?: Maybe<Scalars['String']['output']>;
  auth0Name?: Maybe<Scalars['String']['output']>;
  callbackUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  color?: Maybe<Scalars['String']['output']>;
  companiesIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  idpIdentifier: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isMobile: Scalars['Boolean']['output'];
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  loginUrl?: Maybe<Scalars['String']['output']>;
  logoutUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Maybe<ApplicationPermission>>>;
  role?: Maybe<Array<Maybe<UserType>>>;
  url: Scalars['String']['output'];
};

export type ApplicationInput = {
  appType: ApplicationType;
  callbackUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  companiesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  loginUrl?: InputMaybe<Scalars['String']['input']>;
  logoutUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<ApplicationPermissionInput>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  userTypes: Array<UserTypeInput>;
};

export type ApplicationPermission = {
  __typename?: 'ApplicationPermission';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ApplicationPermissionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum ApplicationType {
  Mobile = 'MOBILE',
  WebApp = 'WEB_APP'
}

export type Area = {
  __typename?: 'Area';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']['output']>;
  branding?: Maybe<Scalars['String']['output']>;
  brandingEnabled?: Maybe<Scalars['Boolean']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idpIdentifier?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['String']['output']>;
};

export type CompanyBranding = {
  logo: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  primaryFontColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
  secondaryFontColor: Scalars['String']['input'];
};

export type License = {
  __typename?: 'License';
  company: Company;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  licenseType: LicenseType;
  machine?: Maybe<Machine>;
  parameters?: Maybe<Scalars['String']['output']>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parametersDefinition?: Maybe<Scalars['String']['output']>;
};

export type Machine = {
  __typename?: 'Machine';
  areaId?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  machineTypeId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  xPosition?: Maybe<Scalars['Float']['output']>;
  yPosition?: Maybe<Scalars['Float']['output']>;
};

export type MachineType = {
  __typename?: 'MachineType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignLicenseToMachine?: Maybe<Scalars['Boolean']['output']>;
  deleteApplication?: Maybe<Scalars['Boolean']['output']>;
  deleteArea?: Maybe<Scalars['Boolean']['output']>;
  deleteCompany?: Maybe<Scalars['Boolean']['output']>;
  deleteLicense?: Maybe<Scalars['Boolean']['output']>;
  deleteMachine?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  insertApplication: Application;
  insertArea: Area;
  insertCompaniesApplication?: Maybe<Scalars['Boolean']['output']>;
  insertCompany: Company;
  insertLicense: License;
  insertMachine: Machine;
  insertUser: User;
  updateApplication: Application;
  updateArea: Area;
  updateCompany?: Maybe<Company>;
  updateMachine: Machine;
  updateUser: User;
};


export type MutationAssignLicenseToMachineArgs = {
  licenseId: Scalars['String']['input'];
  machineId: Scalars['String']['input'];
};


export type MutationDeleteApplicationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAreaArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteLicenseArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMachineArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationInsertApplicationArgs = {
  application?: InputMaybe<ApplicationInput>;
};


export type MutationInsertAreaArgs = {
  city: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  location: Scalars['String']['input'];
  region: Scalars['String']['input'];
};


export type MutationInsertCompaniesApplicationArgs = {
  applicationId: Scalars['String']['input'];
  companiesIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationInsertCompanyArgs = {
  address: Scalars['String']['input'];
  branding?: InputMaybe<CompanyBranding>;
  brandingEnabled: Scalars['Boolean']['input'];
  businessName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};


export type MutationInsertLicenseArgs = {
  companyId: Scalars['String']['input'];
  licenseTypeId: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInsertMachineArgs = {
  areaId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  machineTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  xPosition?: InputMaybe<Scalars['Float']['input']>;
  yPosition?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationInsertUserArgs = {
  areaIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companiesIds: Array<InputMaybe<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypeId: Scalars['String']['input'];
};


export type MutationUpdateApplicationArgs = {
  appType: ApplicationType;
  callbackUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  color?: InputMaybe<Scalars['String']['input']>;
  companiesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  loginUrl?: InputMaybe<Scalars['String']['input']>;
  logoutUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<ApplicationPermissionInput>;
  roleIds: Array<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAreaArgs = {
  city: Scalars['String']['input'];
  id: Scalars['String']['input'];
  location: Scalars['String']['input'];
  region: Scalars['String']['input'];
};


export type MutationUpdateCompanyArgs = {
  address: Scalars['String']['input'];
  applicationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  branding?: InputMaybe<CompanyBranding>;
  brandingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};


export type MutationUpdateMachineArgs = {
  areaId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  machineTypeId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  xPosition?: InputMaybe<Scalars['Float']['input']>;
  yPosition?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateUserArgs = {
  companiesIds: Array<InputMaybe<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypeId: Scalars['String']['input'];
};

export enum OrderType {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInput = {
  begins?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderType>;
  orderCol?: InputMaybe<Scalars['String']['input']>;
};

export type Pageable = Application | Area | Company | License | Machine | User;

export type PagedApplication = PagedResult & {
  __typename?: 'PagedApplication';
  items: Array<Application>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedArea = PagedResult & {
  __typename?: 'PagedArea';
  items: Array<Area>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedCompany = PagedResult & {
  __typename?: 'PagedCompany';
  items: Array<Company>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedLicense = PagedResult & {
  __typename?: 'PagedLicense';
  items: Array<License>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedMachines = PagedResult & {
  __typename?: 'PagedMachines';
  items: Array<Machine>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedResult = {
  items: Array<Pageable>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PagedUser = PagedResult & {
  __typename?: 'PagedUser';
  items: Array<User>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getApplication?: Maybe<Application>;
  getArea?: Maybe<Area>;
  getCompany?: Maybe<Company>;
  getLicense?: Maybe<License>;
  getMachine?: Maybe<Machine>;
  getUser?: Maybe<User>;
  getUserType?: Maybe<UserType>;
  listApplications?: Maybe<PagedApplication>;
  listAreas?: Maybe<PagedArea>;
  listCompanies?: Maybe<PagedCompany>;
  listLicenseTypes?: Maybe<Array<Maybe<LicenseType>>>;
  listLicenses?: Maybe<PagedLicense>;
  listMachineTypes?: Maybe<Array<Maybe<MachineType>>>;
  listMachines?: Maybe<PagedMachines>;
  listUserTypes?: Maybe<Array<Maybe<UserType>>>;
  listUsers?: Maybe<PagedUser>;
};


export type QueryGetApplicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAreaArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCompanyArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetLicenseArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMachineArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserTypeArgs = {
  id: Scalars['String']['input'];
};


export type QueryListApplicationsArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListAreasArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListCompaniesArgs = {
  page?: InputMaybe<PageInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListLicensesArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListMachinesArgs = {
  areaId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};


export type QueryListUsersArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idpIdentifier?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  userType?: Maybe<UserType>;
};

export type UserType = {
  __typename?: 'UserType';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  idpIdentifier?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<ApplicationPermission>>>;
};

export type UserTypeInput = {
  id: Scalars['String']['input'];
  idpIdentifier: Scalars['String']['input'];
  permissions?: InputMaybe<Array<InputMaybe<ApplicationPermissionInput>>>;
};

export type ListApplicationsQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
}>;


export type ListApplicationsQuery = { __typename?: 'Query', listApplications?: { __typename?: 'PagedApplication', total?: number | null, items: Array<{ __typename?: 'Application', id: string, idpIdentifier: string, name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null }> } | null };

export type DeleteApplicationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteApplicationMutation = { __typename?: 'Mutation', deleteApplication?: boolean | null };

export type ListAreasQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListAreasQuery = { __typename?: 'Query', listAreas?: { __typename?: 'PagedArea', total?: number | null, items: Array<{ __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null, company?: { __typename?: 'Company', id?: string | null, name?: string | null } | null }> } | null };

export type GetAreaQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetAreaQuery = { __typename?: 'Query', getArea?: { __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null, company?: { __typename?: 'Company', id?: string | null, name?: string | null } | null } | null };

export type DeleteAreaMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAreaMutation = { __typename?: 'Mutation', deleteArea?: boolean | null };

export type ListCompaniesQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListCompaniesQuery = { __typename?: 'Query', listCompanies?: { __typename?: 'PagedCompany', total?: number | null, items: Array<{ __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, address?: string | null, brandingEnabled?: boolean | null, branding?: string | null }> } | null };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany?: boolean | null };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', getCompany?: { __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, address?: string | null, brandingEnabled?: boolean | null, branding?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } | null };

export type InsertApplicationMutationVariables = Exact<{
  application?: InputMaybe<ApplicationInput>;
}>;


export type InsertApplicationMutation = { __typename?: 'Mutation', insertApplication: { __typename?: 'Application', name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null, loginUrl?: string | null, callbackUrls?: Array<string | null> | null, logoutUrls?: Array<string | null> | null, companiesIds?: Array<string | null> | null } };

export type InsertCompaniesApplicationMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  companiesIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type InsertCompaniesApplicationMutation = { __typename?: 'Mutation', insertCompaniesApplication?: boolean | null };

export type InsertAreaMutationVariables = Exact<{
  region: Scalars['String']['input'];
  city: Scalars['String']['input'];
  location: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type InsertAreaMutation = { __typename?: 'Mutation', insertArea: { __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null, company?: { __typename?: 'Company', id?: string | null } | null } };

export type UpdateAreaMutationVariables = Exact<{
  id: Scalars['String']['input'];
  region: Scalars['String']['input'];
  city: Scalars['String']['input'];
  location: Scalars['String']['input'];
}>;


export type UpdateAreaMutation = { __typename?: 'Mutation', updateArea: { __typename?: 'Area', id?: string | null, region?: string | null, city?: string | null, location?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null, company?: { __typename?: 'Company', id?: string | null } | null } };

export type InsertCompanyMutationVariables = Exact<{
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  businessName: Scalars['String']['input'];
  vat: Scalars['String']['input'];
  address: Scalars['String']['input'];
  brandingEnabled: Scalars['Boolean']['input'];
  branding?: InputMaybe<CompanyBranding>;
}>;


export type InsertCompanyMutation = { __typename?: 'Mutation', insertCompany: { __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, address?: string | null, brandingEnabled?: boolean | null, branding?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } };

export type ListLicenseTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListLicenseTypesQuery = { __typename?: 'Query', listLicenseTypes?: Array<{ __typename?: 'LicenseType', id: string, name: string, description?: string | null, parametersDefinition?: string | null } | null> | null };

export type InsertUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypeId: Scalars['String']['input'];
  companiesIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type InsertUserMutation = { __typename?: 'Mutation', insertUser: { __typename?: 'User', id?: string | null, idpIdentifier?: string | null, name?: string | null, surname?: string | null, email?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } };

export type GetLicenseQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetLicenseQuery = { __typename?: 'Query', getLicense?: { __typename?: 'License', id: string, parameters?: string | null, licenseType: { __typename?: 'LicenseType', id: string, name: string }, company: { __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, address?: string | null, brandingEnabled?: boolean | null, branding?: string | null }, machine?: { __typename?: 'Machine', id?: string | null, name?: string | null, code?: string | null, serialNumber?: string | null, xPosition?: number | null, yPosition?: number | null, areaId?: string | null, companyId?: string | null, machineTypeId?: string | null } | null } | null };

export type InsertMachineMutationVariables = Exact<{
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  machineTypeId: Scalars['String']['input'];
  areaId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type InsertMachineMutation = { __typename?: 'Mutation', insertMachine: { __typename?: 'Machine', id?: string | null, name?: string | null, code?: string | null, serialNumber?: string | null, machineTypeId?: string | null, areaId?: string | null, companyId?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } };

export type UpdateMachineMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  machineTypeId: Scalars['String']['input'];
  areaId: Scalars['String']['input'];
  xPosition?: InputMaybe<Scalars['Float']['input']>;
  yPosition?: InputMaybe<Scalars['Float']['input']>;
}>;


export type UpdateMachineMutation = { __typename?: 'Mutation', updateMachine: { __typename?: 'Machine', id?: string | null, name?: string | null, code?: string | null, serialNumber?: string | null, machineTypeId?: string | null, areaId?: string | null, xPosition?: number | null, yPosition?: number | null, companyId?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  vat: Scalars['String']['input'];
  address: Scalars['String']['input'];
  brandingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  branding?: InputMaybe<CompanyBranding>;
  applicationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany?: { __typename?: 'Company', id?: string | null, idpIdentifier?: string | null, name?: string | null, description?: string | null, businessName?: string | null, vat?: string | null, address?: string | null, brandingEnabled?: boolean | null, branding?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null } | null };

export type ListApplicationsByCompanyQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
  page?: InputMaybe<PageInput>;
}>;


export type ListApplicationsByCompanyQuery = { __typename?: 'Query', listApplications?: { __typename?: 'PagedApplication', total?: number | null, items: Array<{ __typename?: 'Application', id: string, idpIdentifier: string, name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null, auth0Name?: string | null, auth0Description?: string | null, isMobile: boolean, loginUrl?: string | null, callbackUrls?: Array<string | null> | null, logoutUrls?: Array<string | null> | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null, companiesIds?: Array<string | null> | null }> } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  userTypeId: Scalars['String']['input'];
  companiesIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id?: string | null, idpIdentifier?: string | null, name?: string | null, surname?: string | null, email?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null, userType?: { __typename?: 'UserType', id?: string | null, name?: string | null, idpIdentifier?: string | null } | null } };

export type ListCompaniesByUserIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListCompaniesByUserIdQuery = { __typename?: 'Query', listCompanies?: { __typename?: 'PagedCompany', total?: number | null, items: Array<{ __typename?: 'Company', id?: string | null, name?: string | null }> } | null };

export type ListLicensesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
}>;


export type ListLicensesQuery = { __typename?: 'Query', listLicenses?: { __typename?: 'PagedLicense', total?: number | null, items: Array<{ __typename?: 'License', id: string, licenseType: { __typename?: 'LicenseType', id: string, name: string }, company: { __typename?: 'Company', id?: string | null, name?: string | null }, machine?: { __typename?: 'Machine', id?: string | null, name?: string | null } | null }> } | null };

export type InsertLicenseMutationVariables = Exact<{
  licenseTypeId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['String']['input']>;
}>;


export type InsertLicenseMutation = { __typename?: 'Mutation', insertLicense: { __typename?: 'License', id: string, parameters?: string | null, licenseType: { __typename?: 'LicenseType', id: string, name: string, description?: string | null }, company: { __typename?: 'Company', id?: string | null, name?: string | null }, machine?: { __typename?: 'Machine', id?: string | null, name?: string | null } | null } };

export type DeleteLicenseMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteLicenseMutation = { __typename?: 'Mutation', deleteLicense?: boolean | null };

export type ListMachinesQueryVariables = Exact<{
  page?: InputMaybe<PageInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListMachinesQuery = { __typename?: 'Query', listMachines?: { __typename?: 'PagedMachines', total?: number | null, items: Array<{ __typename?: 'Machine', id?: string | null, name?: string | null, code?: string | null, serialNumber?: string | null, companyId?: string | null, areaId?: string | null, machineTypeId?: string | null }> } | null };

export type DeleteMachineMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteMachineMutation = { __typename?: 'Mutation', deleteMachine?: boolean | null };

export type GetApplicationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetApplicationQuery = { __typename?: 'Query', getApplication?: { __typename?: 'Application', id: string, idpIdentifier: string, name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null, auth0Name?: string | null, auth0Description?: string | null, isMobile: boolean, loginUrl?: string | null, callbackUrls?: Array<string | null> | null, logoutUrls?: Array<string | null> | null, companiesIds?: Array<string | null> | null, role?: Array<{ __typename?: 'UserType', id?: string | null, name?: string | null, parentId?: string | null, idpIdentifier?: string | null, permissions?: Array<{ __typename?: 'ApplicationPermission', name: string, description?: string | null } | null> | null } | null> | null, permissions?: Array<{ __typename?: 'ApplicationPermission', name: string, description?: string | null } | null> | null } | null };

export type UpdateApplicationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  appType: ApplicationType;
  loginUrl?: InputMaybe<Scalars['String']['input']>;
  callbackUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  logoutUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  companiesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  roleIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UpdateApplicationMutation = { __typename?: 'Mutation', updateApplication: { __typename?: 'Application', id: string, idpIdentifier: string, name: string, description?: string | null, url: string, imageUrl?: string | null, color?: string | null, auth0Name?: string | null, auth0Description?: string | null, isMobile: boolean, loginUrl?: string | null, callbackUrls?: Array<string | null> | null, logoutUrls?: Array<string | null> | null, companiesIds?: Array<string | null> | null } };

export type ListUsersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInput>;
}>;


export type ListUsersQuery = { __typename?: 'Query', listUsers?: { __typename?: 'PagedUser', total?: number | null, items: Array<{ __typename?: 'User', id?: string | null, name?: string | null, surname?: string | null, email?: string | null, userType?: { __typename?: 'UserType', id?: string | null, name?: string | null } | null }> } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: boolean | null };

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id?: string | null, email?: string | null, idpIdentifier?: string | null, name?: string | null, surname?: string | null, createdBy?: string | null, createdAt?: string | null, lastUpdatedBy?: string | null, lastUpdatedAt?: string | null, userType?: { __typename?: 'UserType', id?: string | null, name?: string | null, idpIdentifier?: string | null } | null } | null };

export type ListMachineTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListMachineTypesQuery = { __typename?: 'Query', listMachineTypes?: Array<{ __typename?: 'MachineType', id?: string | null, name?: string | null, description?: string | null } | null> | null };

export type ListUserTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUserTypesQuery = { __typename?: 'Query', listUserTypes?: Array<{ __typename?: 'UserType', id?: string | null, name?: string | null, parentId?: string | null, idpIdentifier?: string | null } | null> | null };

export const ListApplicationsDocument = gql`
    query ListApplications($page: PageInput) {
  listApplications(page: $page) {
    items {
      id
      idpIdentifier
      name
      description
      url
      imageUrl
      color
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListApplicationsGQL extends Apollo.Query<ListApplicationsQuery, ListApplicationsQueryVariables> {
    override document = ListApplicationsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteApplicationDocument = gql`
    mutation DeleteApplication($id: String!) {
  deleteApplication(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteApplicationGQL extends Apollo.Mutation<DeleteApplicationMutation, DeleteApplicationMutationVariables> {
    override document = DeleteApplicationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAreasDocument = gql`
    query ListAreas($page: PageInput, $companyId: String) {
  listAreas(page: $page, companyId: $companyId) {
    items {
      id
      region
      city
      location
      company {
        id
        name
      }
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAreasGQL extends Apollo.Query<ListAreasQuery, ListAreasQueryVariables> {
    override document = ListAreasDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAreaDocument = gql`
    query GetArea($id: String!) {
  getArea(id: $id) {
    id
    region
    city
    location
    company {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAreaGQL extends Apollo.Query<GetAreaQuery, GetAreaQueryVariables> {
    override document = GetAreaDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAreaDocument = gql`
    mutation DeleteArea($id: String!) {
  deleteArea(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAreaGQL extends Apollo.Mutation<DeleteAreaMutation, DeleteAreaMutationVariables> {
    override document = DeleteAreaDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCompaniesDocument = gql`
    query ListCompanies($page: PageInput, $userId: String) {
  listCompanies(page: $page, userId: $userId) {
    items {
      id
      idpIdentifier
      name
      description
      businessName
      vat
      address
      brandingEnabled
      branding
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCompaniesGQL extends Apollo.Query<ListCompaniesQuery, ListCompaniesQueryVariables> {
    override document = ListCompaniesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: String!) {
  deleteCompany(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCompanyGQL extends Apollo.Mutation<DeleteCompanyMutation, DeleteCompanyMutationVariables> {
    override document = DeleteCompanyDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompanyDocument = gql`
    query GetCompany($id: String!) {
  getCompany(id: $id) {
    id
    idpIdentifier
    name
    description
    businessName
    vat
    address
    brandingEnabled
    branding
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompanyGQL extends Apollo.Query<GetCompanyQuery, GetCompanyQueryVariables> {
    override document = GetCompanyDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertApplicationDocument = gql`
    mutation InsertApplication($application: ApplicationInput) {
  insertApplication(application: $application) {
    name
    description
    url
    imageUrl
    color
    loginUrl
    callbackUrls
    logoutUrls
    companiesIds
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertApplicationGQL extends Apollo.Mutation<InsertApplicationMutation, InsertApplicationMutationVariables> {
    override document = InsertApplicationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCompaniesApplicationDocument = gql`
    mutation InsertCompaniesApplication($applicationId: String!, $companiesIds: [String]!) {
  insertCompaniesApplication(
    applicationId: $applicationId
    companiesIds: $companiesIds
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCompaniesApplicationGQL extends Apollo.Mutation<InsertCompaniesApplicationMutation, InsertCompaniesApplicationMutationVariables> {
    override document = InsertCompaniesApplicationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAreaDocument = gql`
    mutation InsertArea($region: String!, $city: String!, $location: String!, $companyId: String!) {
  insertArea(
    region: $region
    city: $city
    location: $location
    companyId: $companyId
  ) {
    id
    region
    city
    location
    company {
      id
    }
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAreaGQL extends Apollo.Mutation<InsertAreaMutation, InsertAreaMutationVariables> {
    override document = InsertAreaDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAreaDocument = gql`
    mutation UpdateArea($id: String!, $region: String!, $city: String!, $location: String!) {
  updateArea(id: $id, region: $region, city: $city, location: $location) {
    id
    region
    city
    location
    company {
      id
    }
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAreaGQL extends Apollo.Mutation<UpdateAreaMutation, UpdateAreaMutationVariables> {
    override document = UpdateAreaDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCompanyDocument = gql`
    mutation InsertCompany($name: String!, $description: String, $businessName: String!, $vat: String!, $address: String!, $brandingEnabled: Boolean!, $branding: CompanyBranding) {
  insertCompany(
    name: $name
    description: $description
    businessName: $businessName
    vat: $vat
    address: $address
    brandingEnabled: $brandingEnabled
    branding: $branding
  ) {
    id
    idpIdentifier
    name
    description
    businessName
    vat
    address
    brandingEnabled
    branding
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCompanyGQL extends Apollo.Mutation<InsertCompanyMutation, InsertCompanyMutationVariables> {
    override document = InsertCompanyDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListLicenseTypesDocument = gql`
    query ListLicenseTypes {
  listLicenseTypes {
    id
    name
    description
    parametersDefinition
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListLicenseTypesGQL extends Apollo.Query<ListLicenseTypesQuery, ListLicenseTypesQueryVariables> {
    override document = ListLicenseTypesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertUserDocument = gql`
    mutation insertUser($email: String!, $name: String!, $surname: String!, $userTypeId: String!, $companiesIds: [String]!) {
  insertUser(
    email: $email
    name: $name
    surname: $surname
    userTypeId: $userTypeId
    companiesIds: $companiesIds
  ) {
    id
    idpIdentifier
    name
    surname
    email
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertUserGQL extends Apollo.Mutation<InsertUserMutation, InsertUserMutationVariables> {
    override document = InsertUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLicenseDocument = gql`
    query GetLicense($id: String!) {
  getLicense(id: $id) {
    id
    licenseType {
      id
      name
    }
    company {
      id
      idpIdentifier
      name
      description
      businessName
      vat
      address
      brandingEnabled
      branding
    }
    machine {
      id
      name
      code
      serialNumber
      xPosition
      yPosition
      areaId
      companyId
      machineTypeId
    }
    parameters
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLicenseGQL extends Apollo.Query<GetLicenseQuery, GetLicenseQueryVariables> {
    override document = GetLicenseDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMachineDocument = gql`
    mutation InsertMachine($name: String!, $code: String!, $serialNumber: String!, $machineTypeId: String!, $areaId: String!, $companyId: String!) {
  insertMachine(
    name: $name
    code: $code
    serialNumber: $serialNumber
    machineTypeId: $machineTypeId
    areaId: $areaId
    companyId: $companyId
  ) {
    id
    name
    code
    serialNumber
    machineTypeId
    areaId
    companyId
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMachineGQL extends Apollo.Mutation<InsertMachineMutation, InsertMachineMutationVariables> {
    override document = InsertMachineDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMachineDocument = gql`
    mutation UpdateMachine($id: String!, $name: String!, $code: String!, $serialNumber: String!, $machineTypeId: String!, $areaId: String!, $xPosition: Float, $yPosition: Float) {
  updateMachine(
    id: $id
    name: $name
    code: $code
    serialNumber: $serialNumber
    machineTypeId: $machineTypeId
    areaId: $areaId
    xPosition: $xPosition
    yPosition: $yPosition
  ) {
    id
    name
    code
    serialNumber
    machineTypeId
    areaId
    xPosition
    yPosition
    companyId
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMachineGQL extends Apollo.Mutation<UpdateMachineMutation, UpdateMachineMutationVariables> {
    override document = UpdateMachineDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: String!, $description: String, $vat: String!, $address: String!, $brandingEnabled: Boolean, $branding: CompanyBranding, $applicationIds: [String]) {
  updateCompany(
    id: $id
    description: $description
    vat: $vat
    address: $address
    brandingEnabled: $brandingEnabled
    branding: $branding
    applicationIds: $applicationIds
  ) {
    id
    idpIdentifier
    name
    description
    businessName
    vat
    address
    brandingEnabled
    branding
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyGQL extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
    override document = UpdateCompanyDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListApplicationsByCompanyDocument = gql`
    query listApplicationsByCompany($companyId: String!, $page: PageInput) {
  listApplications(companyId: $companyId, page: $page) {
    items {
      id
      idpIdentifier
      name
      description
      url
      imageUrl
      color
      auth0Name
      auth0Description
      isMobile
      loginUrl
      callbackUrls
      logoutUrls
      createdBy
      createdAt
      lastUpdatedBy
      lastUpdatedAt
      companiesIds
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListApplicationsByCompanyGQL extends Apollo.Query<ListApplicationsByCompanyQuery, ListApplicationsByCompanyQueryVariables> {
    override document = ListApplicationsByCompanyDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $name: String!, $surname: String!, $userTypeId: String!, $companiesIds: [String]!) {
  updateUser(
    id: $id
    name: $name
    surname: $surname
    userTypeId: $userTypeId
    companiesIds: $companiesIds
  ) {
    id
    idpIdentifier
    name
    surname
    email
    userType {
      id
      name
      idpIdentifier
    }
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCompaniesByUserIdDocument = gql`
    query ListCompaniesByUserId($id: String!) {
  listCompanies(userId: $id) {
    items {
      id
      name
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCompaniesByUserIdGQL extends Apollo.Query<ListCompaniesByUserIdQuery, ListCompaniesByUserIdQueryVariables> {
    override document = ListCompaniesByUserIdDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListLicensesDocument = gql`
    query ListLicenses($companyId: String, $page: PageInput) {
  listLicenses(companyId: $companyId, page: $page) {
    items {
      id
      licenseType {
        id
        name
      }
      company {
        id
        name
      }
      machine {
        id
        name
      }
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListLicensesGQL extends Apollo.Query<ListLicensesQuery, ListLicensesQueryVariables> {
    override document = ListLicensesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertLicenseDocument = gql`
    mutation InsertLicense($licenseTypeId: String!, $companyId: String!, $parameters: String) {
  insertLicense(
    licenseTypeId: $licenseTypeId
    companyId: $companyId
    parameters: $parameters
  ) {
    id
    licenseType {
      id
      name
      description
    }
    company {
      id
      name
    }
    machine {
      id
      name
    }
    parameters
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertLicenseGQL extends Apollo.Mutation<InsertLicenseMutation, InsertLicenseMutationVariables> {
    override document = InsertLicenseDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteLicenseDocument = gql`
    mutation DeleteLicense($id: String!) {
  deleteLicense(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteLicenseGQL extends Apollo.Mutation<DeleteLicenseMutation, DeleteLicenseMutationVariables> {
    override document = DeleteLicenseDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListMachinesDocument = gql`
    query ListMachines($page: PageInput, $companyId: String) {
  listMachines(page: $page, companyId: $companyId) {
    items {
      id
      name
      code
      serialNumber
      companyId
      areaId
      machineTypeId
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListMachinesGQL extends Apollo.Query<ListMachinesQuery, ListMachinesQueryVariables> {
    override document = ListMachinesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMachineDocument = gql`
    mutation DeleteMachine($id: String!) {
  deleteMachine(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMachineGQL extends Apollo.Mutation<DeleteMachineMutation, DeleteMachineMutationVariables> {
    override document = DeleteMachineDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApplicationDocument = gql`
    query GetApplication($id: String!) {
  getApplication(id: $id) {
    id
    idpIdentifier
    name
    description
    url
    imageUrl
    color
    auth0Name
    auth0Description
    isMobile
    loginUrl
    callbackUrls
    logoutUrls
    companiesIds
    role {
      id
      name
      parentId
      idpIdentifier
      permissions {
        name
        description
      }
    }
    permissions {
      name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApplicationGQL extends Apollo.Query<GetApplicationQuery, GetApplicationQueryVariables> {
    override document = GetApplicationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateApplicationDocument = gql`
    mutation updateApplication($id: String!, $name: String!, $description: String, $url: String, $imageUrl: String, $color: String, $appType: ApplicationType!, $loginUrl: String, $callbackUrls: [String], $logoutUrls: [String], $companiesIds: [String], $roleIds: [String!]!) {
  updateApplication(
    id: $id
    name: $name
    description: $description
    url: $url
    imageUrl: $imageUrl
    color: $color
    appType: $appType
    loginUrl: $loginUrl
    callbackUrls: $callbackUrls
    logoutUrls: $logoutUrls
    companiesIds: $companiesIds
    roleIds: $roleIds
  ) {
    id
    idpIdentifier
    name
    description
    url
    imageUrl
    color
    auth0Name
    auth0Description
    isMobile
    loginUrl
    callbackUrls
    logoutUrls
    companiesIds
    isMobile
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateApplicationGQL extends Apollo.Mutation<UpdateApplicationMutation, UpdateApplicationMutationVariables> {
    override document = UpdateApplicationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListUsersDocument = gql`
    query ListUsers($companyId: String, $page: PageInput) {
  listUsers(companyId: $companyId, page: $page) {
    items {
      id
      name
      surname
      email
      userType {
        id
        name
      }
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUsersGQL extends Apollo.Query<ListUsersQuery, ListUsersQueryVariables> {
    override document = ListUsersDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    override document = DeleteUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser($id: String) {
  getUser(id: $id) {
    id
    email
    idpIdentifier
    name
    surname
    userType {
      id
      name
      idpIdentifier
    }
    createdBy
    createdAt
    lastUpdatedBy
    lastUpdatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    override document = GetUserDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListMachineTypesDocument = gql`
    query listMachineTypes {
  listMachineTypes {
    id
    name
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListMachineTypesGQL extends Apollo.Query<ListMachineTypesQuery, ListMachineTypesQueryVariables> {
    override document = ListMachineTypesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListUserTypesDocument = gql`
    query listUserTypes {
  listUserTypes {
    id
    name
    parentId
    idpIdentifier
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUserTypesGQL extends Apollo.Query<ListUserTypesQuery, ListUserTypesQueryVariables> {
    override document = ListUserTypesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
