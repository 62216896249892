import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appScrollNearEnd]',
  standalone: true,
})
export class ScrollNearEndDirective {
  @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();

  @Input() thresholdPercent = 0.8;

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  windowScrollEvent(event: any): void {
    const threshold =
      (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
    const current = event.target.scrollTop + event.target.clientHeight;
    if (current > threshold) {
      this.nearEnd.next();
    }
  }
}
