<div class="wall-options-container" matDialogContent>
  <div class="options-columns-container">
   <ng-container *ngIf="hasSelectAll">
     <ng-container>
       <mat-checkbox [checked]="allTasks!.selectAllTask!.checked!"
                     (change)="setAll($event.checked)"
                     disableRipple=true>
         <span class="select-all-label">{{allTasks!.selectAllTask!.label! | i18next}}</span>
       </mat-checkbox>
     </ng-container>
     <ng-container *ngFor="let subtask of allTasks!.subTasks!">
       <mat-checkbox [(ngModel)]="subtask.checked"
                     (ngModelChange)="updateAllComplete()"
                     disableRipple=true>
         <span>{{subtask!.label! | i18next}}</span>
       </mat-checkbox>
     </ng-container>
   </ng-container>
    <ng-container *ngIf="!hasSelectAll">
      <ng-container *ngFor="let subtask of allTasks!.subTasks!">
        <mat-checkbox [(ngModel)]="subtask.checked"
                      (ngModelChange)="updateAllComplete()"
                      disableRipple=true>
          <span>{{subtask!.label! | i18next}}</span>
        </mat-checkbox>
      </ng-container>
    </ng-container>
  </div>
</div>
