<h1 mat-dialog-title>{{'MODIFY_MACHINE.TITLE' | i18next}}</h1>

<div mat-dialog-content>
    <form spellcheck="false" [formGroup]="form">

        <mat-form-field appearance="fill">
            <mat-label>{{'MODIFY_MACHINE.NAME_LABEL' | i18next}}</mat-label>
            <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'MODIFY_MACHINE.NAME_PLACEHOLDER' | i18next}}" value="">
            <mat-hint>{{'MODIFY_MACHINE.NAME_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_MACHINE.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

        <mat-form-field appearance="fill">
            <mat-label>{{'MODIFY_MACHINE.CODE_LABEL' | i18next}}</mat-label>
            <input matInput type="text" id="code" name="code" formControlName="code" placeholder="{{'MODIFY_MACHINE.CODE_PLACEHOLDER' | i18next}}" value="">
            <mat-hint>{{'MODIFY_MACHINE.CODE_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'code'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_MACHINE.CODE_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

        <mat-form-field appearance="fill">
            <mat-label>{{'MODIFY_MACHINE.SERIAL_NUMBER_LABEL' | i18next}}</mat-label>
            <input matInput type="text" id="serialNumber" name="serialNumber" formControlName="serialNumber" placeholder="{{'MODIFY_MACHINE.SERIAL_NUMBER_PLACEHOLDER' | i18next}}" value="">
            <mat-hint>{{'MODIFY_MACHINE.SERIAL_NUMBER_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'serialNumber'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_MACHINE.SERIAL_NUMBER_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

        <mat-form-field appearance="fill">
            <mat-label>{{'MODIFY_MACHINE.MACHINE_TYPE_LABEL' | i18next}}</mat-label>
            <mat-select [formControl]="form.controls.machineType">
                <mat-option *ngFor="let machineType of machineTypes" [value]="machineType.id">{{machineType.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'machineType'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_MACHINE.MACHINE_TYPE_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

        <mat-form-field appearance="fill">
            <mat-label>{{'MODIFY_MACHINE.AREA_LABEL' | i18next}}</mat-label>
            <mat-select [formControl]="form.controls.area">
                <mat-option *ngFor="let area of areas" [value]="area.id">{{area.region}} - {{area.city}} - {{area.location}}</mat-option>
            </mat-select>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'area'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_MACHINE.AREA_REQUIRED_ERROR' | i18next}}"></app-validator-ui>
    </form>

</div>
<div class="modal-dialog-actions" mat-dialog-actions>
    <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="modifyMachine();" [disabled]="!formValid() || loading">
        <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
        <span>{{'MODIFY_MACHINE.MODIFY_MACHINE' | i18next}}</span>
    </button>
    <a mat-button (click)="closeModal()">
        <span>{{'MODIFY_MACHINE.CANCEL' | i18next}}</span>
    </a>
</div>
