<div class="{{gridSize}}">
  <div class="app-card">
    <mat-card>
      <mat-card-header [matTooltip]="application.applicationName" style="background: #{{headerColor.replace('#', '')}}"  *ngIf="headerColor !== ''">
        <mat-card-title>{{title}}</mat-card-title>
        <div matSuffix>
          <i class="fa-solid fa-ellipsis-vertical" [matMenuTriggerFor]="menu"></i>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="modifyApplication(application.applicationId)">
            <mat-icon>edit</mat-icon>
            <span>{{'APP_CARD.EDIT_APPLICATION' | i18next}}</span>
          </button>
          <button mat-menu-item (click)="deleteApplication(application.applicationId)">
            <mat-icon class="table-action-delete">delete</mat-icon>
            <span class="table-action-delete">{{'APP_CARD.DELETE_APPLICATION' | i18next}}</span>
          </button>
        </mat-menu>
      </mat-card-header>
      <mat-card-header style="background: #DF002C" *ngIf="headerColor === ''">
        <mat-card-title>{{title}}</mat-card-title>
        <i class="fa-solid fa-right-to-bracket" matSuffix></i>
      </mat-card-header>
      <a class="mat-card-body" href="{{url}}">
        <div class="image-back"></div>
        <img mat-card-image alt="" *ngIf="img !== ''" title="" src="{{img}}">
        <img mat-card-image alt="" *ngIf="img === ''" title="" src="/assets/images/logo.png">
      </a>
    </mat-card>
  </div>
</div>
