import { Component, OnDestroy } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { CreateCompanyComponent } from '../dialogs/create-company/create-company.component';
import { LogLevel } from '../../models/log-level';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';
import { DeleteCompanyGQL, ListCompaniesGQL } from '../../../graphql/generated';
import { ListCompaniesMapper } from './companies.mappers';
import { CompanyModel } from '../../shared/models/company.model';
import { ModifyCompanyComponent } from '../dialogs/modify-company/modify-company.component';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { FilterTableComponent } from '../../shared/abstract-classes/filter-table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class CompaniesComponent extends FilterTableComponent implements OnDestroy {
  dataSource?: GraphqlTableDatasource<CompanyModel>;

  companiesMapper: ListCompaniesMapper = new ListCompaniesMapper();

  tableDefinitions = [
    { def: 'name', title: _('COMPANIES.COMPANY'), sortable: true },
    {
      def: 'businessName',
      title: _('COMPANIES.BUSINESS_NAME'),
      sortable: true,
    },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: _('COMPANIES.MODIFY_COMPANY'),
      styleClass: 'table-action',
      callback: (row: any): void => {
        this.modifyCompany(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('COMPANIES.DELETE_COMPANY'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteCompany(row);
      },
    },
  ];

  constructor(
    private router: Router,
    private providerService: ProviderService,
    private listCompaniesGQL: ListCompaniesGQL,
    private deleteCompanyGQL: DeleteCompanyGQL
  ) {
    super();
    this.subscriptions.add(
      this.debounceSubject
        .pipe(debounceTime(500), distinctUntilChanged(), skip(1))
        .subscribe((value) => {
          this.filterObservable.next(value);
        })
    );
    this.dataSource = new GraphqlTableDatasource<CompanyModel>(
      this.providerService,
      this.listCompaniesGQL,
      this.companiesMapper
    );
  }

  addCompany(): void {
    this.providerService.utilService.openDialog(
      CreateCompanyComponent,
      {
        callback: () => {
          this.addCompanyCallback();
        },
      },
      'create-modal'
    );
  }

  addCompanyCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  companyRowClicked(row: any): void {
    this.router.navigate(['app/companies', row.id], {
      queryParamsHandling: 'merge',
    });
  }

  modifyCompany(row: any): void {
    this.providerService.utilService.openDialog(
      ModifyCompanyComponent,
      {
        company: row,
        callback: () => {
          this.modifyCompanyCallback();
        },
      },
      'create-modal'
    );
  }

  modifyCompanyCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  async deleteCompany(row: any): Promise<void> {
    if (
      await this.providerService.utilService.openConfirmationDialog(
        _('COMPANIES.DELETE_COMPANY_CONFIRMATION_DIALOG_TITLE'),
        _('COMPANIES.DELETE_COMPANY_CONFIRMATION_DIALOG_MESSAGE')
      )
    ) {
      this.subscriptions.add(
        this.deleteCompanyGQL
          .mutate({ id: row.id }, { errorPolicy: 'all' })
          .subscribe((value) => {
            if (value.data?.deleteCompany) {
              this.filterObservable.next(this.lastDebounceValue);
            } else {
              if (value.errors && value.errors.length > 0) {
                let stringToShow = '';
                value.errors.forEach((err) => {
                  stringToShow = stringToShow.concat(err.message, ' ');
                });
                this.providerService.utilService.showMessage(
                  stringToShow.trim(),
                  LogLevel.error
                );
              } else {
                this.providerService.utilService.showMessage(
                  _('COMPANIES.DELETE_COMPANY_ERROR'),
                  LogLevel.error
                );
              }
            }
          })
      );
    }
  }
}
