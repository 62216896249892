<br><br>
<div class="header">
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <input matInput type="text" formControlName="dataFilterController" placeholder="{{'APPLICATIONS.FILTER_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'APPLICATIONS.FULL_TEXT_QUERY' | i18next}}</mat-hint>
    </mat-form-field>
  </form>
  <div class="button-array">
    <button *ngIf="isAdmin" mat-raised-button color="primary" (click)="this.providerService.utilService.navigateTo('app/applications/create-application');">
      <i class="fa-solid fa-plus"></i>
      <span>{{'APPLICATIONS.ADD_APPLICATION' | i18next}}</span>
    </button>
    <a class="{{sizeBig ? 'selected': 'not-selected'}}" (click)="changeSize('app-card-size-big');">
      <i class="fa-solid fa-grid-2"></i>
    </a>
    <a class="{{sizeMedium ? 'selected': 'not-selected'}}" (click)="changeSize('app-card-size-medium');">
      <i class="fa-solid fa-grid"></i>
    </a>
    <a class="{{sizeSmall ? 'selected': 'not-selected'}}" (click)="changeSize('app-card-size-small');">
      <i class="fa-solid fa-grid-4"></i>
    </a>
  </div>
</div>
<ng-container *ngIf="$data | async as applications">
  <div appScrollNearEnd (nearEnd)="onNearEndScroll()" class="applications-scroll-container">
    <div *ngFor="let application of applications">
      <app-app-card (applicationsRemoved)="removeApplication($event)" [isAdmin]="isAdmin" [application]="application" [title]="application.applicationName" [img]="application.imageUrl" [url]="application.url" [headerColor]="application.color.trim()" [gridSize]="gridSize"></app-app-card>
    </div>
  </div>
  <app-loader *ngIf="loading"></app-loader>
</ng-container>
