<div class="container">
  <div id="top" class="row">
    <div class="content">
      <app-navbar (sidebarToggleEvent)="sideBarToggle()"></app-navbar>
    </div>
  </div>
  <div id="bottom" class="row">
    <div id="left" class="column">
      <div class="content">
        <app-sidebar [ssToggle]="sidebarOpened"></app-sidebar>
      </div>
    </div>
    <div id="right" class="column">
      <div class="content">
        <app-breadcrumb></app-breadcrumb>
        <div class="app-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>



