import { Component, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
  Subscription,
} from 'rxjs';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';
import { AreaModel } from '../../shared/models/area.model';
import { ProviderService } from '../../core/provider.service';
import { AreasMapper } from './areas.mapper';
import {
  DeleteAreaGQL,
  GetCompanyGQL,
  ListAreasGQL,
} from '../../../graphql/generated';
import { FilterTableComponent } from '../../shared/abstract-classes/filter-table';
import { CreateAreaComponent } from '../dialogs/area/create-area/create-area.component';
import { ModifyAreaComponent } from '../dialogs/area/modify-area/modify-area.component';
import { AreaDetailsComponent } from '../dialogs/area/area-details/area-details.component';
import { LogLevel } from '../../models/log-level';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { companyChangedSubject } from '../navbar/navbar.component';
import { ActivatedRoute } from '@angular/router';
import { CompanyModel } from '../../shared/models/company.model';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss'],
})
export class AreasComponent extends FilterTableComponent implements OnInit {
  company?: CompanyModel;
  dataSource?: GraphqlTableDatasource<AreaModel>;
  changeVariablesSubject: BehaviorSubject<{ [key: string]: string }> =
    new BehaviorSubject<{ [key: string]: string }>({});

  tableDefinitions = [
    { def: 'region', title: _('AREAS.REGION'), sortable: true },
    { def: 'city', title: _('AREAS.CITY'), sortable: true },
    { def: 'location', title: _('AREAS.LOCATION'), sortable: true },
    { def: 'companyName', title: _('AREAS.COMPANY'), sortable: true },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: _('AREAS.MODIFY_AREA'),
      styleClass: 'table-action',
      callback: (row: any): void => {
        this.modifyArea(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('AREAS.DELETE_AREA'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteArea(row);
      },
    },
  ];

  private areasMapper: AreasMapper = new AreasMapper();
  private deleteAreaSub?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private providerService: ProviderService,
    private companyGql: GetCompanyGQL,
    private listAreasGQL: ListAreasGQL,
    private deleteAreaGQL: DeleteAreaGQL
  ) {
    super();
    this.debounceSubject
      .pipe(debounceTime(500), distinctUntilChanged(), skip(1))
      .subscribe((value) => {
        this.filterObservable.next(value);
      });

    let variables = {};

    if (!this.providerService.companyService.getIsAdminSelected()) {
      variables = {
        companyId: this.providerService.companyService.getSelectedCompany()?.id,
      };
    }

    this.dataSource = new GraphqlTableDatasource<AreaModel>(
      this.providerService,
      this.listAreasGQL,
      this.areasMapper,
      variables
    );
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.add(
      companyChangedSubject.pipe().subscribe((company) => {
        if (company) {
          this.company = company;
          const variables: { [key: string]: string } = {};
          if (!this.providerService.companyService.getIsAdminSelected()) {
            variables['companyId'] = company.id;
          }
          if (!this.dataSource) {
            this.dataSource = new GraphqlTableDatasource<AreaModel>(
              this.providerService,
              this.listAreasGQL,
              this.areasMapper,
              variables
            );
          } else {
            this.dataSource.changeVariables(variables);
          }
        }
      })
    );
  }

  deleteArea(row: any): void {
    this.deleteAreaSub = this.deleteAreaGQL
      .mutate({ id: row.id }, { errorPolicy: 'all' })
      .subscribe((value) => {
        if (value.data?.deleteArea) {
          this.filterObservable.next(this.lastDebounceValue);
        } else {
          if (value.errors && value.errors.length > 0) {
            let stringToShow = '';
            value.errors.forEach((err) => {
              stringToShow = stringToShow.concat(err.message, ' ');
            });
            this.providerService.utilService.showMessage(
              stringToShow.trim(),
              LogLevel.error
            );
          } else {
            this.providerService.utilService.showMessage(
              _('AREAS.DELETE_AREA_ERROR'),
              LogLevel.error
            );
          }
        }
      });
  }

  modifyArea(row: any): void {
    this.providerService.utilService
      .openDialog(
        ModifyAreaComponent,
        {
          area: row,
          callback: () => {
            this.areaCallback();
          },
        },
        'create-modal'
      )
      .afterClosed()
      .subscribe(() => this.areaCallback());
  }

  areaRowClicked(row: any): void {
    this.providerService.utilService.openDialog(
      AreaDetailsComponent,
      {
        area: row,
      },
      'create-modal'
    );
  }

  areaCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  addArea(): void {
    this.providerService.utilService
      .openDialog(
        CreateAreaComponent,
        {
          callback: () => {
            this.areaCallback();
          },
        },
        'create-modal'
      )
      .afterClosed()
      .subscribe(() => this.areaCallback());
  }
}
