import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-in-app',
  templateUrl: './layout-in-app.component.html',
  styleUrls: ['./layout-in-app.component.scss'],
})
export class LayoutInAppComponent {
  sidebarOpened = false;

  sideBarToggle(): void {
    this.sidebarOpened = !this.sidebarOpened;
  }
}
