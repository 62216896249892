import { Injectable } from '@angular/core';
import { IAuthenticator } from '../../../models/interfaces/i-authenticator';
import { HttpVerb } from '../../../models/http-verb';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-browser';
import {
  CognitoIdentity,
  GetCredentialsForIdentityCommandOutput,
  GetIdCommandOutput,
} from '@aws-sdk/client-cognito-identity';
import { environment } from '../../../../environments/environment';
import { IdToken } from '@auth0/auth0-angular';
import { IAuthorizer } from '../../../models/interfaces/i-authorizer';

@Injectable({
  providedIn: 'root',
})
export class CognitoIamAuthenticatorService implements IAuthenticator {
  // How to solve proper signing: https://github.com/aws/aws-sdk-js-v3/issues/3590
  sigV4?: SignatureV4;
  sigV4Appsync?: SignatureV4;
  cognitoIdentity?: CognitoIdentity;
  oauthService?: IAuthorizer;
  idToken = '';

  constructor() {}

  setOauthService(oauthService: IAuthorizer): void {
    this.oauthService = oauthService;
  }

  async getRequest(
    path: string,
    method: HttpVerb,
    headers: { [key: string]: string },
    body?: string,
    params?: { [key: string]: string }
  ): Promise<any> {
    await this.prepareSignatureV4();

    const apiUrl = new URL(path);
    headers['host'] = apiUrl.hostname;
    if (body !== undefined) {
      headers['content-type'] = 'application/json';
      headers['content-length'] = body.length.toString();
    }
    const objectToSign = {
      method: method.toUpperCase(),
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      protocol: apiUrl.protocol,
      query: params,
      body,
      headers,
    };

    return await this?.sigV4Appsync?.sign(objectToSign, {
      signingDate: new Date(),
    });
  }

  isAuthenticationError(statusCode: number): boolean {
    return statusCode === 403;
  }

  manageError(response: any): Promise<any> {
    return Promise.reject(
      `Status code: ${response.statusCode} - error: ${response.body}`
    );
  }

  private prepareSignatureV4(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const sub = this.oauthService
          ?.getIdToken()
          .subscribe(async (token: IdToken) => {
            if (token) {
              try {
                // How to define logins Map:
                // https://docs.aws.amazon.com/cognitoidentity/latest/APIReference/API_GetId.html#API_GetId_RequestSyntax
                // Check the LoginsMap information in the document, basically you have to use the domain value for the key
                // of the map and the id token for the value.
                const logins: { [key: string]: string } = {};
                logins[environment.auth0.domain] = token?.__raw;
                this.idToken = token?.__raw;

                this.cognitoIdentity = new CognitoIdentity({
                  region: 'eu-west-1',
                });

                // 1) Get an identity from the identity pool of Cognito based on the grant given by the id token of the external IdP.
                const getIdCommandOutput: GetIdCommandOutput =
                  await this.cognitoIdentity?.getId({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    IdentityPoolId: environment.cognito.identityPoolId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Logins: logins,
                  });

                // 2) Based on the identity generate a set of temporary credentials.
                const getCredentialsForIdentityCommandOutput: GetCredentialsForIdentityCommandOutput =
                  await this.cognitoIdentity?.getCredentialsForIdentity({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    IdentityId: getIdCommandOutput.IdentityId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Logins: logins,
                  });

                // 3) Given the credentials, set up the Signature V4 library to execute apis in a particular region using those credentials.
                this.sigV4 = new SignatureV4({
                  service: 'execute-api',
                  region: 'eu-west-1',
                  credentials: {
                    accessKeyId:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.AccessKeyId ?? '', // 'AKIAVJQY53S5EOFCH5UT',
                    secretAccessKey:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.SecretKey ?? '', // 'gEV6Iad0bWwDn/D4qtClONxTDW5qa+LFUYUQL/Yc',
                    sessionToken:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.SessionToken ?? '',
                  },
                  sha256: Sha256,
                });

                this.sigV4Appsync = new SignatureV4({
                  service: 'appsync',
                  region: 'eu-west-1',
                  credentials: {
                    accessKeyId:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.AccessKeyId ?? '', // 'AKIAVJQY53S5EOFCH5UT',
                    secretAccessKey:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.SecretKey ?? '', // 'gEV6Iad0bWwDn/D4qtClONxTDW5qa+LFUYUQL/Yc',
                    sessionToken:
                      getCredentialsForIdentityCommandOutput.Credentials
                        ?.SessionToken ?? '',
                  },
                  sha256: Sha256,
                });

                // 4) Complete the async method: the SigV4 element is correctly initialized and can be used to sign the requests.
                sub?.unsubscribe();
                resolve();
              } catch (error2) {
                reject(error2);
              }
            } else {
              reject('no token retrieved');
            }
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}
