<h1 mat-dialog-title>{{'MODIFY_AREA.TITLE' | i18next}}</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_AREA.REGION_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="region" name="region" formControlName="region" placeholder="{{'MODIFY_AREA.REGION_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_AREA.REGION_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'region'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_AREA.REGION_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_AREA.CITY_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="city" name="city" formControlName="city" placeholder="{{'MODIFY_AREA.CITY_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_AREA.CITY_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'city'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_AREA.CITY_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_AREA.LOCATION_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="location" name="location" formControlName="location" placeholder="{{'MODIFY_AREA.LOCATION_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_AREA.LOCATION_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'location'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_AREA.LOCATION_ERROR' | i18next}}"></app-validator-ui>
  </form>

</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="updateArea();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'MODIFY_AREA.MODIFY_AREA' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'MODIFY_AREA.CANCEL' | i18next}}</span>
  </a>
</div>
