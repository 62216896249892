<div class="license-info-container">
  <span class="title">
    {{'LICENSE_DETAILS.TITLE' | i18next}}
  </span>
  <div class="info-container">
    <span class="info-label">
      {{'LICENSE_DETAILS.LICENSE_TYPE_NAME' | i18next}}
    </span>
    <span class="info-content">
      {{license?.licenseType?.name}}
    </span>
  </div>
  <div class="info-container">
    <span class="info-label">
      {{'LICENSE_DETAILS.LICENSE_TYPE_DESCRIPTION' | i18next}}
    </span>
    <span class="info-content">
      {{license?.licenseType?.description}}
    </span>
  </div>
  <div class="info-container">
    <span class="info-label">
      {{'LICENSE_DETAILS.COMPANY_NAME' | i18next}}
    </span>
    <span class="info-content">
      {{license?.company?.name}}
    </span>
  </div>
  <div class="info-container">
    <span class="info-label">
      {{'LICENSE_DETAILS.COMPANY_DESCRIPTION' | i18next}}
    </span>
    <span class="info-content">
      {{license?.company?.description}}
    </span>
  </div>
  <div class="info-container">
    <span class="info-label">
      {{'LICENSE_DETAILS.COMPANY_BUSINESS_NAME' | i18next}}
    </span>
    <span class="info-content">
      {{license?.company?.businessName}}
    </span>
  </div>
  <div class="info-container" *ngFor="let param of parameters">
    <span class="info-label">
      {{param.name | i18next}}
    </span>
    <span class="info-content">
      {{param.value}}
    </span>
  </div>
</div>
