import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyModel } from '../../../shared/models/company.model';
import { ListCompaniesMapper } from '../../companies/companies.mappers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { ProviderService } from '../../../core/provider.service';
import { InsertLicenseGQL, ListCompaniesGQL, ListLicenseTypesGQL } from '../../../../graphql/generated';
import { LicenseTypeModel } from '../../../shared/models/license.model';
import { ListLicenseTypesMapper } from './create-license.mappers';
import { InsertLicenseMapper } from '../../licenses/license.mapper';
import { Subscription } from 'rxjs';
import { LogLevel } from '../../../models/log-level';

export class LicenseParameter {
  name?: string;
  value?: string;
}

@Component({
  selector: 'app-create-license',
  templateUrl: './create-license.component.html',
  styleUrls: ['./create-license.component.scss'],
})
export class CreateLicenseComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    licenseTypeId: new FormControl('', [Validators.required]),
  });
  parametersForm = new FormGroup({});
  loading = false;
  selectedCompany?: CompanyModel;
  mapper = new ListCompaniesMapper();
  licenseTypes: LicenseTypeModel[] = [];
  licenseParameters: LicenseParameter[] = [];
  private licenseTypeSub?: Subscription;
  constructor(public dialogRef: MatDialogRef<CreateLicenseComponent>, @Inject(MAT_DIALOG_DATA) public data: any, @Inject(DOCUMENT) private document: Document, private providerService: ProviderService, public listCompanies: ListCompaniesGQL, private listLicenseTypes: ListLicenseTypesGQL, private insertLicense: InsertLicenseGQL) {}

  async ngOnInit(): Promise<void> {
    try {
      this.licenseTypes = await this.providerService.graphqlService.fetch(this.listLicenseTypes, {}, new ListLicenseTypesMapper());
      this.licenseTypeSub = this.form.controls.licenseTypeId.valueChanges.subscribe((value) => {
        const type = this.licenseTypes.find((licenseType) => licenseType.id === value);
        if (type && type.parameterDefinition) {
          const parameters = JSON.parse(type.parameterDefinition);
          this.licenseParameters = [];
          this.parametersForm = new FormGroup({});
          Object.getOwnPropertyNames(parameters).forEach((v) => {
            this.licenseParameters.push({
              name: v,
              value: parameters[v],
            });
            this.parametersForm?.addControl(v + 'Control', new FormControl('', [Validators.required]));
          });
        }
      });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.closeModal();
    }
  }

  formValid(): boolean {
    return !!(this.form.valid && this.selectedCompany && this.parametersForm && this.parametersForm.valid);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  companySelectionChanged(event: any): void {
    this.selectedCompany = event;
  }

  async createLicense(): Promise<void> {
    try {
      const parameters: { [key: string]: string } = {};
      this.licenseParameters.forEach((value) => {
        parameters[value.name ?? ''] = this.parametersForm.get(value.name + 'Control')?.value;
      });
      const variables = { licenseTypeId: this.form.controls.licenseTypeId.value, companyId: this.selectedCompany?.id, parameters: JSON.stringify(parameters) };
      await this.providerService.graphqlService.mutate(this.insertLicense, variables, new InsertLicenseMapper());
      this.data.callback();
      this.closeModal();
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  ngOnDestroy(): void {
    this.licenseTypeSub?.unsubscribe();
  }

  licenseParameterChanged(parameter: LicenseParameter, $event: any): void {
    const index = this.licenseParameters.findIndex((value) => value.name === parameter.name);
    const license = this.licenseParameters[index];
    if (license) {
      license.value = $event;
    }
  }
}
