import { IGraphqlExportMapper } from '../../models/interfaces/i-graphql-export-mapper';
import { IChipItem } from '../../models/interfaces/i-chip-item';
import { FullApplicationModel } from '../applications/applications-models';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';
import { UserTypeModel } from '../../shared/models/user.model';

export class ListCompaniesChipItemMapper implements IGraphqlPagedMapper {
  items: IChipItem[] = [];
  total = 0;

  returnFromJson(json: any): { items: IChipItem[]; total: number } {
    this.items = [];
    const pagedCompanies = json['listCompanies'];

    pagedCompanies['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        description: value['name'],
      });
    });
    this.total = pagedCompanies['total'];
    return { items: this.items, total: this.total };
  }
}

export class ListUserTypesChipItemMapper implements IGraphqlExportMapper {
  elements: IChipItem[] = [];

  returnFromJson(json: any): IChipItem[] {
    this.elements = [];
    const userTypes = json['listUserTypes'];
    userTypes.forEach((value: any) => {
      this.elements.push({
        id: value['id'],
        description: value['name'],
        item: {
          id: value['id'],
          name: value['name'],
          idpIdentifier: value['idpIdentifier'],
        } as UserTypeModel,
      });
    });
    return this.elements;
  }
}

export class InsertApplicationMapper implements IGraphqlMapper {
  application?: FullApplicationModel;

  returnFromJson(json: any): FullApplicationModel {
    const app = json['insertApplication'];
    this.application = {
      id: app['id'],
      idpIdentifier: app['idpIdentifier'],
      name: app['name'],
      description: app['description'],
      url: app['url'],
      imageUrl: app['imageUrl'],
      color: app['color'],
      auth0Name: app['auth0Name'],
      auth0Description: app['auth0Description'],
      isMobile: app['isMobile'],
      loginUrl: app['loginUrl'],
      callbackUrls: app['callbackUrls'],
      logoutUrls: app['logoutUrls'],
      companiesIds: app['companies'],
      roles: JSON.parse(app['roles']),
      permissions: JSON.parse(app['permissions']),
    };
    return this.application;
  }
}
