import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';
import { FullLicenseModel, LicenseModel } from '../../shared/models/license.model';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';

export class LicenseMapper implements IGraphqlPagedMapper {
  items: FullLicenseModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: FullLicenseModel[]; total: number } {
    this.items = [];
    const pagedLicenses = json['listLicenses'];

    pagedLicenses['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        licenseType: value['licenseType'],
        company: value['company'],
        machine: value['machine'],
        parameters: value['parameters'],
      });
    });
    this.total = pagedLicenses['total'];
    return { items: this.items, total: this.total };
  }
}

export class DeleteLicenseMapper implements IGraphqlMapper {
  returnFromJson(json: any): boolean {
    return json['deleteLicense'];
  }
}

export class InsertLicenseMapper implements IGraphqlMapper {
  response?: LicenseModel;
  returnFromJson(json: any): LicenseModel {
    const value = json['insertLicense'];
    this.response = {
      id: value['id'],
      licenseType: value['licenseType'],
      companyId: value['company']['id'],
      machineId: value['machine']['id'],
      parameters: value['parameters'],
    };
    return this.response;
  }
}
