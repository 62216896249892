import { FullLicenseModel } from '../../../shared/models/license.model';
import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';

export class GetLicenseMapper implements IGraphqlMapper {
  license?: FullLicenseModel;

  returnFromJson(json: any): FullLicenseModel {
    const license = json['getLicense'];

    this.license = {
      id: license['id'],
      licenseType: license['licenseType'],
      company: license['company'],
      machine: license['machine'],
      parameters: license['parameters'],
    };

    return this.license;
  }
}
