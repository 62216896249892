import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';
import { StepperOrientation } from '@angular/cdk/stepper';
import { ProviderService } from '../../core/provider.service';
import { ShowInfoComponent } from '../dialogs/show-info/show-info.component';
import {
  InsertApplicationGQL,
  InsertApplicationMutationVariables,
  ListCompaniesGQL,
  ListUserTypesGQL,
  OrderType,
} from '../../../graphql/generated';
import {
  InsertApplicationMapper,
  ListCompaniesChipItemMapper,
  ListUserTypesChipItemMapper,
} from './create-application.mappers';
import { S3Service } from '../../core/app-utils/s3.service';
import { LogLevel } from '../../models/log-level';
import { IChipItem } from '../../models/interfaces/i-chip-item';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { CreateApplicationDatasource } from './create-application-datasource';

export const checkForUriValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    let invalid = true;
    if (control.value.length > 0) {
      invalid = !(
        control.value.startsWith('http://') ||
        control.value.startsWith('https://') ||
        control.value.startsWith('www.')
      );
    }
    return invalid ? { forbiddenName: { value: control.value } } : null;
  };

@Component({
  selector: 'app-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.scss'],
})
export class CreateApplicationComponent {
  @Output()
  changeEvent = new EventEmitter<File>();
  image = '';
  stepperOrientation: Observable<StepperOrientation>;
  selectedFileName = '';
  selectedFile?: any;
  selectedColor = '';
  maxWidth = 500;
  resizedImage = '';
  loginUriMessage = _('CREATE_APPLICATION.LOGIN_URI_MESSAGE');
  loginUriTitle = _('CREATE_APPLICATION.LOGIN_URI_TITLE');
  allowedCallbackUrlsMessage = _(
    'CREATE_APPLICATION.ALLOWED_CALLBACK_URLS_MESSAGE'
  );
  allowedCallbackUrlsTitle = _(
    'CREATE_APPLICATION.ALLOWED_CALLBACK_URLS_TITLE'
  );
  allowedLogoutUrlsMessage = _(
    'CREATE_APPLICATION.ALLOWED_LOGOUT_URLS_MESSAGE'
  );
  allowedLogoutUrlsTitle = _('CREATE_APPLICATION.ALLOWED_LOGOUT_URLS_TITLE');
  fileReader = new FileReader();

  public basicInformationForm = new FormGroup({
    appType: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    url: new FormControl('https://', [
      Validators.required,
      checkForUriValidator(),
    ]),
  });
  selectedCallbacks: string[] = [];
  allowedLogouts: string[] = [];
  selectedLoginUri = '';
  loginUriError = false;
  auth0ConfigCompleted = false;
  userTypesCompleted = false;
  basicAuth0InformationFormValid = false;
  brandingValid = false;
  listCompaniesVariables = {
    filter: '',
    limit: 9999,
    orderCol: '',
    begins: 0,
    order: OrderType.Desc,
  };
  listCompaniesMapper = new ListCompaniesChipItemMapper();
  listUserTypesMapper = new ListUserTypesChipItemMapper();
  loading = false;
  showSuccessfulInsertPage = false;
  dataSource = new CreateApplicationDatasource();
  selectedUserTypes: IChipItem[] = [];
  private selectedCompanies: string[] = [];

  constructor(
    public providerService: ProviderService,
    public listCompaniesQuery: ListCompaniesGQL,
    public listUserTypesQuery: ListUserTypesGQL,
    public breakpointObserver: BreakpointObserver,
    private s3service: S3Service,
    private insertApplication: InsertApplicationGQL
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 1280px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    this.checkBasicAuth0InformationFormValid();
    this.fileReader.onload = (e: any) => {
      this.image = e.target.result;
      this.resizeImage(this.getInnerWidth());
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resizeImage(this.getInnerWidth());
  }

  getInnerWidth(): number {
    return window.innerWidth;
  }

  basicInformationFormValid(): boolean {
    return this.basicInformationForm.valid;
  }

  checkBasicAuth0InformationFormValid(): void {
    this.basicAuth0InformationFormValid =
      this.basicInformationForm.valid && this.brandingValid;
  }

  checkBrandingValid(): void {
    this.brandingValid = !!(this.image && this.selectedColor);
  }

  updateSource($event: any): void {
    if ($event.target['files'] && $event.target['files'].length > 0) {
      this.selectedFile = $event.target['files'][0];
      this.selectedFileName = $event.target['files'][0].name;
      this.projectImage($event.target['files'][0]);
      this.checkBrandingValid();
    }
  }

  projectImage(file: File): void {
    this.fileReader.readAsDataURL(file);
  }

  resizeImage(width: number): void {
    if (width > this.maxWidth) {
      width = this.maxWidth;
    }
    if (this.image.length > 0) {
      this.toDataURL(
        this.image,
        Math.round((width * 80) / 100),
        (dataUrl: any) => {
          this.resizedImage = dataUrl;
        }
      );
    }
  }

  toDataURL(src: string, width: number, callback: any): void {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = canvas.width * (img.height / img.width);
      ctx?.drawImage(img, 0, 0, width, canvas.width * (img.height / img.width));
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
    };
    img.src = src;
    img.onerror = () => {
      img.src = 'src/assets/images/image-placeholder.jpg';
    };
  }

  allowedCallbacksChanged($event: string[]): void {
    this.selectedCallbacks = $event;
    this.checkAuth0ConfigCompleted();
  }

  allowedLogoutsChanged($event: string[]): void {
    this.allowedLogouts = $event;
    this.checkAuth0ConfigCompleted();
  }

  checkForLoginUri($event: string): void {
    this.loginUriError = false;
    if ($event.length > 0) {
      this.loginUriError = !this.checkForUriHttps($event);
    }
    this.checkAuth0ConfigCompleted();
  }

  checkForUri(data: string): boolean {
    if (data.length > 0) {
      return (
        data.startsWith('http://') ||
        data.startsWith('https://') ||
        data.startsWith('www.')
      );
    }
    return false;
  }

  checkForUriHttps(data: string): boolean {
    if (data.length > 0) {
      const regex =
        /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*$/;
      return regex.test(data);
    }
    return false;
  }

  openInfoDialog(message: string, title: string): void {
    this.providerService.utilService.openDialog(ShowInfoComponent, {
      message,
      title,
    });
  }

  checkAuth0ConfigCompleted(): void {
    this.auth0ConfigCompleted =
      !this.loginUriError &&
      this.selectedCallbacks.length > 0 &&
      this.allowedLogouts.length > 0;
  }

  selectedCompaniesChanged($event: IChipItem[]): void {
    this.selectedCompanies = $event.map((value) => value.id);
  }

  selectedUserTypesChanged($event: IChipItem[]): void {
    this.selectedUserTypes = $event;
    this.checkUserTypesCompleted();
  }

  checkUserTypesCompleted(): void {
    this.userTypesCompleted =
      this.selectedUserTypes && this.selectedUserTypes.length > 0;
  }

  async createApplication(): Promise<void> {
    this.loading = true;
    let imageName = '';
    try {
      let imageUrl = '';
      imageName = `${
        this.basicInformationForm.controls.name.value
      }-logo-${crypto.randomUUID()}`;
      imageUrl = await this.s3service.uploadImageFile(
        this.selectedFile,
        imageName
      );
      const userTypesToAdd: any[] = [];
      // this.selectedUserTypes.forEach((usertype) => {
      //   userTypesToAdd.push({
      //     id: usertype.id,
      //     idpIdentifier: usertype.item.idpIdentifier,
      //     permissions: this.selectedPermissions[usertype.id]
      //       ? this.selectedPermissions[usertype.id].map((permission) => ({
      //           name: permission.scope,
      //           description: permission.description,
      //         }))
      //       : [],
      //   });
      // });
      // const permissionsToAdd = this.addedPermissions
      //   ? this.addedPermissions.map((value) => ({
      //       name: value.scope,
      //       description: value.description,
      //     }))
      //   : [];
      const variables = {
        application: {
          name: this.basicInformationForm.controls.name.value ?? '',
          description:
            this.basicInformationForm.controls.description.value ?? '',
          url: this.basicInformationForm.controls.url.value ?? '',
          imageUrl,
          color: this.selectedColor,
          loginUrl: this.selectedLoginUri,
          callbackUrls: this.selectedCallbacks,
          logoutUrls: this.allowedLogouts,
          appType: this.basicInformationForm.controls.appType.value,
          companiesIds: this.selectedCompanies ? this.selectedCompanies : [],
          userTypes: userTypesToAdd,
          permissions: [],
        },
      } as InsertApplicationMutationVariables;
      const mapper = new InsertApplicationMapper();
      await this.providerService.graphqlService.mutate(
        this.insertApplication,
        variables,
        mapper
      );
      this.loading = false;
      this.showSuccessfulInsertPage = true;
    } catch (error: any) {
      this.loading = false;
      await this.s3service.deleteFile(imageName);
      this.providerService.utilService.showMessage(
        error.message,
        LogLevel.error
      );
    }
  }
}
