import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ProviderService } from '../../provider.service';
import { LogLevel } from '../../../models/log-level';

@Component({
  selector: 'app-filter-chips-free-input',
  templateUrl: './filter-chips-free-input.component.html',
  styleUrls: ['./filter-chips-free-input.component.scss'],
})
export class FilterChipsFreeInputComponent implements OnInit {
  @Input()
  maxOptions = 3;

  @Output()
  selectionChanged = new EventEmitter<string[]>();

  @Input()
  validator?: (args: string) => boolean;

  @Input()
  selectedValues: string[] = [];

  items: string[] = [];
  isFocusedOnInput = false;

  constructor(private providerService: ProviderService) {}

  ngOnInit(): void {
    this.items = this.selectedValues;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (this.validator) {
      if (this.validator(value)) {
        if (value && this.items.indexOf(value) === -1) {
          this.items.push(value);
        }
        this.selectionChanged.emit(this.items);

        event?.chipInput.clear();
      } else {
        this.providerService.utilService.showMessage('Invalid input', LogLevel.error);
      }
    } else {
      if (value && this.items.indexOf(value) === -1) {
        this.items.push(value);
      }
      this.selectionChanged.emit(this.items);

      event?.chipInput.clear();
    }
  }

  remove(item: string): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
      this.selectionChanged.emit(this.items);
    }
  }

  focusOnInput(): void {
    this.isFocusedOnInput = true;
  }

  deFocusOnInput(): void {
    this.isFocusedOnInput = false;
  }
}
