import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';
import { MachineModel } from '../../../shared/models/machine.model';

export class MachineMapper implements IGraphqlMapper {
  machine?: MachineModel;

  returnFromJson(json: any): MachineModel {
    const value = json.insertMachine || json.updateMachine || '';

    this.machine = {
      id: value['id'],
      name: value['name'],
      code: value['code'],
      serialNumber: value['serialNumber'],
      areaId: value['areaId'],
      companyId: value['companyId'],
      machineTypeId: value['machineTypeId'],
    };
    return this.machine;
  }
}
