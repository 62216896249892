import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorUiComponent } from './form-utils/validator-ui/validator-ui.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TableUiComponent } from './form-utils/table/table-ui/table-ui.component';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GraphqlTableUiComponent } from './form-utils/graphql-table/graphql-table-ui/graphql-table-ui.component';
import { FilterChipsFreeInputComponent } from './form-utils/filter-chips-free-input/filter-chips-free-input.component';
import { A11yModule } from '@angular/cdk/a11y';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FilterChipsComponent } from './form-utils/filter-chips/filter-chips.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SelectInfiniteScrollComponent } from './form-utils/select-infinite-scroll/select-infinite-scroll.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { OptionsScrollDirective } from './form-utils/select-infinite-scroll/options-scroll.directive';
import { SelectInfiniteScrollMultiComponent } from './form-utils/select-infinite-scroll-multi/select-infinite-scroll-multi.component';
import { OptionsScrollMultiDirective } from './form-utils/select-infinite-scroll-multi/options-scroll-multi.directive';
import { I18NextModule } from 'angular-i18next';
import { FilterChipsNoInfiniteScrollComponent } from './form-utils/filter-chips-no-infinite-scroll/filter-chips-no-infinite-scroll.component';
import { WallOfCheckboxComponent } from './form-utils/wall-of-checkbox/wall-of-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ValidatorUiComponent, TableUiComponent, GraphqlTableUiComponent, FilterChipsComponent, FilterChipsNoInfiniteScrollComponent, FilterChipsFreeInputComponent, SelectInfiniteScrollComponent, OptionsScrollDirective, SelectInfiniteScrollMultiComponent, OptionsScrollMultiDirective, WallOfCheckboxComponent],
  imports: [CommonModule, BrowserAnimationsModule, BrowserModule, FormsModule, MatSnackBarModule, HttpClientModule, MatInputModule, MatTableModule, MatTooltipModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatMenuModule, A11yModule, MatChipsModule, MatIconModule, MatButtonModule, MatAutocompleteModule, ReactiveFormsModule, InfiniteScrollModule, MatSelectModule, I18NextModule, MatCheckboxModule, MatDialogModule],
  exports: [ValidatorUiComponent, TableUiComponent, GraphqlTableUiComponent, FilterChipsFreeInputComponent, FilterChipsComponent, SelectInfiniteScrollComponent, SelectInfiniteScrollMultiComponent, FilterChipsNoInfiniteScrollComponent, WallOfCheckboxComponent],
})
export class CoreModule {}
