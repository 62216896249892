import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  skip,
} from 'rxjs';
import { ProviderService } from '../../core/provider.service';

import { LogLevel } from '../../models/log-level';
import { CreateUserComponent } from '../dialogs/create-user/create-user.component';
import { CompanyModel } from '../../shared/models/company.model';
import { DeleteUserGQL, GetCompanyGQL } from '../../../graphql/generated';
import { DeleteUserMapper, GetCompanyMapper } from './company.mappers';
import { UserModel } from '../../shared/models/user.model';
import { GetUsersGraphqlMapper } from '../../shared/mappers/user.mapper';
import { GraphqlTableDatasource } from '../../core/form-utils/graphql-table/graphql-table.datasource';
import { companyChangedSubject } from '../navbar/navbar.component';
import { ModifyUserComponent } from '../dialogs/modify-user/modify-user.component';
import { AntiMemLeak } from '../../shared/abstract-classes/anti-mem-leak';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class CompanyDetailsComponent extends AntiMemLeak implements OnInit, OnDestroy {
  company?: CompanyModel;
  user?: UserModel;
  changeVariablesSubject: BehaviorSubject<{ [key: string]: string }> =
    new BehaviorSubject<{ [key: string]: string }>({});

  dataSource?: GraphqlTableDatasource<UserModel>;

  usersMapper: GetUsersGraphqlMapper = new GetUsersGraphqlMapper();

  tableDefinitions = [
    { def: 'name', title: _('COMPANY_DETAILS.NAME'), sortable: true },
    { def: 'surname', title: _('COMPANY_DETAILS.SURNAME'), sortable: true },
    { def: 'email', title: _('COMPANY_DETAILS.EMAIL'), sortable: true },
    {
      def: 'userType',
      secondDef: 'name',
      title: _('COMPANY_DETAILS.USER_TYPE'),
    },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: _('COMPANY_DETAILS.MODIFY_USER'),
      styleClass: 'table-action',
      callback: (row: any): void => {
        this.modifyUser(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: _('COMPANY_DETAILS.DELETE_USER'),
      styleClass: 'table-action-delete',
      callback: (row: any): void => {
        this.deleteUser(row);
      },
    },
  ];

  filterObservable = new BehaviorSubject<string>('');
  debounceSubject = new BehaviorSubject<string>('');
  lastDebounceValue = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private providerService: ProviderService,
    private companyGql: GetCompanyGQL,
    private deleteUserGql: DeleteUserGQL
  ) {
    super();

    this.subscriptions.add(
      this.debounceSubject
        .pipe(skip(1), debounceTime(500), distinctUntilChanged())
        .subscribe((value: any) => this.filterObservable.next(value))
    );
  }

  async ngOnInit(): Promise<void> {
    let isFromCompaniesTab = false;

    this.activatedRoute.params
      .subscribe(async (params: any) => {
        const companyId = params['id'];
        console.log('companyId', companyId);
        if (companyId) {
          console.log('entered in if');
          const mapper = new GetCompanyMapper();
          isFromCompaniesTab = !!companyId;
          this.company = await this.providerService.graphqlService.fetch(
            this.companyGql,
            { id: companyId },
            mapper
          );
        }
      })
      .unsubscribe();

    if (!isFromCompaniesTab) {
      const selectedCompany =
        this.providerService.companyService.getSelectedCompany();
      console.log('selectedCompany', selectedCompany);
      this.company = selectedCompany;
    }
    // if (selectedCompany) {
    //   const variables: { [key: string]: string } = {};
    //   variables['companyId'] = selectedCompany.id;
    //   this.dataSource = new GraphqlTableDatasource<UserModel>(
    //     this.providerService,
    //     this.listUsersGQL,
    //     this.usersMapper,
    //     variables
    //   );
    //   console.log('this.dataSource', this.dataSource);
    // }
    this.subscriptions.add(
      companyChangedSubject.pipe(skip(1)).subscribe((company: CompanyModel) => {
        console.log('company', company);

        if (company) {
          this.company = company;
          const variables: { [key: string]: string } = {};
          variables['companyId'] = company.id;
          console.log('variables', variables);

          // if (!this.dataSource) {
          //   this.dataSource = new GraphqlTableDatasource<UserModel>(
          //     this.providerService,
          //     this.listUsersGQL,
          //     this.usersMapper,
          //     variables
          //   );
          // } else {
          //   this.dataSource.changeVariables(variables);
          // }
        }
      })
    );
    // }
  }

  sendFilterQuery(event: any): void {
    this.lastDebounceValue = event.target.value;
    this.debounceSubject.next(event.target.value);
  }

  addUser(): void {
    this.providerService.utilService.openDialog(
      CreateUserComponent,
      {
        companyId: this.company?.id,
        companyIdpId: this.company?.idpId,
        callback: () => {
          this.refreshCallback();
        },
      },
      'create-modal'
    );
  }

  refreshCallback(): void {
    this.filterObservable.next(this.lastDebounceValue);
  }

  async modifyUser(row: any): Promise<void> {
    this.providerService.utilService.openDialog(
      ModifyUserComponent,
      {
        user: row,
        fromCompanyDetails: true,
        callback: () => {
          this.refreshCallback();
        },
      },
      'create-modal'
    );
  }

  async deleteUser(row: any): Promise<void> {
    if (
      await this.providerService.utilService.openConfirmationDialog(
        _('COMPANY_DETAILS.DELETE_USER_CONFIRMATION_DIALOG_TITLE'),
        _('COMPANY_DETAILS.DELETE_USER_CONFIRMATION_DIALOG_MESSAGE')
      )
    ) {
      try {
        await this.providerService.graphqlService.mutate(
          this.deleteUserGql,
          { id: row.userId },
          new DeleteUserMapper()
        );
        this.filterObservable.next(this.lastDebounceValue);
      } catch (e: any) {
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
      }
    }
  }
}
