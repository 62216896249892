import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderService } from './core/provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isAuth0Loading: Observable<boolean>;

  constructor(private providerService: ProviderService) {
    this.isAuth0Loading = providerService.oAuthService.isLoading();
    this.providerService.translationService.bootstrapCurrentLanguage();
  }
  // ===== Boostrap application data here =====
  // ==========================================
}
