import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { LogLevel } from '../../../models/log-level';
import { CustomValidators } from '../../../core/form-utils/custom-validators';
import { ListApplicationsByCompanyGQL, ListApplicationsGQL, UpdateCompanyGQL } from '../../../../graphql/generated';
import { Subscription } from 'rxjs';
import { ApplicationModel } from '../../applications/applications-models';
import { ListApplicationsMapper } from '../../applications/applications.mappers';
import { S3Service } from '../../../core/app-utils/s3.service';
import { UpdateCompanyMapper } from './modify-company.mappers';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-modify-company',
  templateUrl: './modify-company.component.html',
  styleUrls: ['./modify-company.component.scss'],
})
export class ModifyCompanyComponent implements OnInit, OnDestroy {
  result: any = {};
  selectedApplications: ApplicationModel[] = [];
  resizedImage = '';
  selectedPrimaryColor = '';
  selectedSecondaryColor = '';
  selectedPrimaryFontColor = '';
  selectedSecondaryFontColor = '';
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    businessName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      CustomValidators.patternValidator(new RegExp('^[a-z0-9][a-z0-9_-]*$'), {
        businessNameInvalid: true,
      }),
    ]),
    vat: new FormControl('', [Validators.required, CustomValidators.vatValidator]),
    address: new FormControl('', [Validators.required]),
    applications: new FormControl([] as ApplicationModel[]),
    enableBranding: new FormControl(false),
  });
  loading = false;
  initializing = false;
  image = '';
  listApplicationsMapper = new ListApplicationsMapper();
  private insertSub?: Subscription;
  private selectedFile?: any;
  private fileReader = new FileReader();
  private maxWidth = 500;
  private originalFileName = '';
  private fileChanged = false;
  private applicationSub?: Subscription;
  constructor(public dialogRef: MatDialogRef<ModifyCompanyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, @Inject(DOCUMENT) private document: Document, private providerService: ProviderService, private updateCompanyGql: UpdateCompanyGQL, public listApplicationGQL: ListApplicationsGQL, private listApplicationsByCompany: ListApplicationsByCompanyGQL, private s3service: S3Service) {
    this.fileReader.onload = (e: any) => {
      this.image = e.target.result;
      this.resizeImage(this.getInnerWidth());
    };
    this.populateVariables();
    this.applicationSub = this.form.controls.applications.valueChanges.subscribe((value) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedApplications = value!;
    });
  }

  ngOnInit(): void {
    // this.applicationArray = this.providerService.graphqlService.fetch(this.listApplicationsByCompany, { companyId: this.data.company.companyId }, new ListApplicationsByCompanyMapper());
  }

  getInnerWidth(): number {
    return window.innerWidth;
  }

  ngOnDestroy(): void {
    this.insertSub?.unsubscribe();
    this.applicationSub?.unsubscribe();
  }

  formValid(): boolean {
    return this.form.valid && this.brandingValid();
  }

  brandingValid(): boolean {
    return !this.form.controls.enableBranding.value || !!(this.image && this.selectedPrimaryColor && this.selectedSecondaryColor && this.selectedPrimaryFontColor && this.selectedSecondaryFontColor);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  updateSource($event: any): void {
    if ($event.target['files'] && $event.target['files'].length > 0) {
      this.selectedFile = $event.target['files'][0];
      this.projectImage($event.target['files'][0]);
      this.fileChanged = true;
    }
  }

  projectImage(file: File): void {
    this.fileReader.readAsDataURL(file);
  }

  resizeImage(width: number): void {
    if (width > this.maxWidth) {
      width = this.maxWidth;
    }
    if (this.image) {
      this.toDataURL(this.image, Math.round((width * 80) / 100), (dataUrl: any) => {
        this.resizedImage = dataUrl;
      });
    }
  }

  toDataURL(src: string, width: number, callback: any): void {
    const img = this.getNewImage();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = canvas.width * (img.height / img.width);
      ctx?.drawImage(img, 0, 0, width, canvas.width * (img.height / img.width));
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
    };
    img.src = src;
    img.onerror = () => {
      img.src = '';
    };
  }

  applicationSelectionChanged($event: any): void {
    this.form.controls.applications.setValue($event);
  }

  async modifyCompany(): Promise<void> {
    if (this.formValid()) {
      const imageName = `${this.form.controls.name.value}-logo-${crypto.randomUUID()}`;
      try {
        this.loading = true;
        const mapper = new UpdateCompanyMapper();
        let imageUrl = '';
        if (this.form.controls.enableBranding.value) {
          if (this.originalFileName !== '' && this.fileChanged) {
            await this.s3service.deleteFile(this.originalFileName);
          }
          imageUrl = await this.s3service.uploadImageFile(this.selectedFile, imageName);
        }
        const variables = {
          id: this.data.company.id,
          name: this.form.controls.name.value,
          description: this.form.controls.description.value,
          vat: this.form.controls.vat.value,
          address: this.form.controls.address.value,
          brandingEnabled: this.form.controls.enableBranding.value,
          applicationIds: this.form.controls.applications.value?.map((value) => value.applicationId),
          branding: {
            logo: imageUrl,
            primaryColor: this.selectedPrimaryColor,
            secondaryColor: this.selectedSecondaryColor,
            primaryFontColor: this.selectedPrimaryFontColor,
            secondaryFontColor: this.selectedSecondaryFontColor,
          },
        };
        await this.providerService.graphqlService.mutate(this.updateCompanyGql, variables, mapper);
        if (this.form.controls.enableBranding.value) {
          if (this.originalFileName !== '' && this.fileChanged) {
            await this.s3service.deleteFile(this.originalFileName);
          }
        }
        this.loading = false;
        this.closeModal();
      } catch (e: any) {
        if (this.form.controls.enableBranding.value) {
          await this.s3service.deleteFile(imageName);
        }
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
        this.loading = false;
      }
    }
  }

  private getNewImage(): any {
    return new Image();
  }

  private async getS3Image(key: string): Promise<void> {
    try {
      const data = await this.s3service.getFile(key);
      if (data && data.Body) {
        const byteArray = await data?.Body?.transformToByteArray();
        const blob = new Blob([byteArray], { type: data.ContentType });
        this.selectedFile = new File([byteArray], key);
        const urlCreator = window.URL || window.webkitURL;
        this.image = urlCreator.createObjectURL(blob);
      }
      this.resizeImage(this.getInnerWidth());
    } catch (e: any) {
      this.providerService.utilService.showMessage(e.message, LogLevel.error);
    }
  }

  private setS3File(objectUrl: string): void {
    let pathname = '';
    try {
      pathname = new URL(objectUrl).pathname;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e.message, LogLevel.error);
    }
    if (pathname) {
      const key = pathname.split('/')[1].replaceAll('+', ' ');
      this.originalFileName = key;
      this.getS3Image(key);
    } else {
      this.originalFileName = '';
    }
  }

  private async populateVariables(): Promise<void> {
    this.initializing = true;
    const objectUrl = this.data.company.branding.logo;
    if (this.data.company.brandingEnabled) {
      this.setS3File(objectUrl);
    }
    this.selectedPrimaryColor = this.data.company.branding.colors.main.primaryColor;
    this.selectedSecondaryColor = this.data.company.branding.colors.main.secondaryColor;
    this.selectedPrimaryFontColor = this.data.company.branding.colors.main.primaryFontColor;
    this.selectedSecondaryFontColor = this.data.company.branding.colors.main.secondaryFontColor;
    this.form.controls.name.setValue(this.data.company.name);
    this.form.controls.description.setValue(this.data.company.description);
    this.form.controls.businessName.setValue(this.data.company.businessName);
    this.form.controls.vat.setValue(this.data.company.vat);
    this.form.controls.address.setValue(this.data.company.address);
    this.form.controls.enableBranding.setValue(this.data.company.brandingEnabled);
    this.form.markAllAsTouched();
    this.markFormAsDirty();
    this.selectedApplications = ((await this.providerService.graphqlService.fetch(this.listApplicationsByCompany, { companyId: this.data.company.id }, new ListApplicationsMapper())) as any).items;
    this.form.controls.applications.setValue(this.selectedApplications);
    this.initializing = false;
  }

  private markFormAsDirty(): void {
    this.form.controls.name.markAsDirty();
    this.form.controls.description.markAsDirty();
    this.form.controls.businessName.markAsDirty();
    this.form.controls.vat.markAsDirty();
    this.form.controls.address.markAsDirty();
    this.form.controls.enableBranding.markAsDirty();
  }
}
