import { FullApplicationModel } from '../applications/applications-models';
import { IGraphqlMapper } from '../../models/interfaces/I-graphql-mapper';

export class GetApplicationMapper implements IGraphqlMapper {
  application?: FullApplicationModel;

  returnFromJson(json: any): FullApplicationModel {
    const getApplication = json['getApplication'];
    this.application = {
      id: getApplication['id'],
      idpIdentifier: getApplication['idpIdentifier'],
      name: getApplication['name'],
      description: getApplication['description'],
      url: getApplication['url'],
      imageUrl: getApplication['imageUrl'],
      color: getApplication['color'],
      auth0Name: getApplication['auth0Name'],
      auth0Description: getApplication['auth0Description'],
      isMobile: getApplication['isMobile'],
      loginUrl: getApplication['loginUrl'],
      callbackUrls: getApplication['callbackUrls'],
      logoutUrls: getApplication['logoutUrls'],
      companiesIds: getApplication['companiesIds'],
      roles: JSON.parse(getApplication['roles']),
      permissions: JSON.parse(getApplication['permissions']),
    };
    return this.application;
  }
}

export class UpdateApplicationMapper implements IGraphqlMapper {
  application?: FullApplicationModel;

  returnFromJson(json: any): FullApplicationModel {
    const getApplication = json['updateApplication'];
    this.application = {
      id: getApplication['id'],
      idpIdentifier: getApplication['idpIdentifier'],
      name: getApplication['name'],
      description: getApplication['description'],
      url: getApplication['url'],
      imageUrl: getApplication['imageUrl'],
      color: getApplication['color'],
      auth0Name: getApplication['auth0Name'],
      auth0Description: getApplication['auth0Description'],
      isMobile: getApplication['isMobile'],
      loginUrl: getApplication['loginUrl'],
      callbackUrls: getApplication['callbackUrls'],
      logoutUrls: getApplication['logoutUrls'],
      companiesIds: getApplication['companies'],
      roles: JSON.parse(getApplication['roles']),
      permissions: JSON.parse(getApplication['permissions']),
    };
    return this.application;
  }
}
