<h1>{{'PROFILE.TITLE' | i18next}}</h1>
<div class="user">
  <h2>{{'PROFILE.EMAIL' | i18next}}</h2>
  <div class="user-data">
    <span>
      <i class="fa fa-regular fa-envelope"></i>
      {{user.email}}
    </span>
  </div>
  <br>
  <h2>{{'PROFILE.NAME' | i18next}}</h2>
  <div class="user-data">
    <span>
      <i class="fa fa-regular fa-circle-user"></i>
      {{user.name}}
    </span>
  </div>
</div>
