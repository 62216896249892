import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  debounceTime,
  exhaustMap,
  Observable,
  scan,
  startWith,
  Subject,
  Subscriber,
  switchMap,
} from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { ProviderService } from '../../provider.service';
import { IChipItem } from '../../../models/interfaces/i-chip-item';
import * as Apollo from 'apollo-angular';
import { IGraphqlPagedMapper } from '../../../models/interfaces/I-graphql-paged-mapper';
import { filter, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent implements OnInit, OnDestroy {
  @Input()
  parameterKey?: string;
  @Input()
  maxOptions = 3;
  @Input()
  graphqlQuery?: Apollo.Query<any, any>;
  @Input()
  params: any;
  @Input()
  mapper?: IGraphqlPagedMapper | any;
  @Input()
  selectedValues: IChipItem[] = [];

  @ViewChild('itemsInput')
  itemsInput?: ElementRef<HTMLInputElement>;

  @ViewChild(MatAutocompleteTrigger)
  autoComplete1?: MatAutocompleteTrigger;

  @Output()
  selectionChanged = new EventEmitter<IChipItem[]>();
  data$: Observable<IChipItem[]> | undefined;

  items: IChipItem[] = [];
  chips = new FormControl('', [Validators.required]);
  isFocusedOnInput = false;
  exFormGroup = new FormGroup({
    dataFilterController: new FormControl(''),
  });
  private pageSize = 10;
  private total = 0;
  private nextPage$ = new Subject();

  constructor(private providerService: ProviderService) {}

  async ngOnInit(): Promise<void> {
    this.items = this.selectedValues;
    this.data$ = this.getItems('', 0);
    // Note: listen for search text changes
    const filter$ =
      this.exFormGroup.controls.dataFilterController.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        filter((q) => typeof q === 'string')
      );

    this.data$ = filter$.pipe(
      switchMap((filterString) => {
        //Note: Reset the page with every new seach text
        let currentPage = 0;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap((_) => this.getItems(filterString, currentPage)),
          tap(() => currentPage++),
          takeWhile((p: any[]) => p.length > 0),
          scan((allData: any, newData: any) => allData.concat(newData), [])
        );
      })
    );
  }

  remove(item: IChipItem): void {
    const index = this.items.findIndex((arrayItem) => arrayItem.id === item.id);
    if (index > -1) {
      this.items.splice(index, 1);
    }
    this.selectionChanged.emit(this.items);
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.items.map((value) => value.id).includes(event.option.value.id)) {
      this.items.push(event.option.value);
      this.selectionChanged.emit(this.items);
    }
    if (this.itemsInput?.nativeElement?.value) {
      this.itemsInput.nativeElement.value = '';
    }
    this.chips.setValue(null);
  }

  focusOnInput(): void {
    this.isFocusedOnInput = true;
  }

  deFocusOnInput(): void {
    this.isFocusedOnInput = false;
  }

  ngOnDestroy(): void {
    this.autoComplete1?.closePanel();
  }

  onScroll(): void {
    //Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next('');
  }

  private getItems(filterString: any, page: number): Observable<any[]> {
    return new Observable((subscriber: Subscriber<any[]>) => {
      const variables: {
        [key: string]: any;
      } = {};
      variables['page'] = {
        filter: filterString,
        limit: this.pageSize,
        orderCol: '',
        begins: page * this.pageSize,
        order: 'asc',
      };
      for (const key in this.params) {
        if (Object.hasOwn(this.params, key)) {
          variables[key] = this.params[key];
        }
      }
      if (this.graphqlQuery && this.mapper) {
        this.providerService.graphqlService
          .fetch<any>(this.graphqlQuery, variables, this.mapper)
          .then((result) => {
            this.total = result.total;
            subscriber.next(result.items);
            this.chips.setValue('', { emitEvent: true });
            subscriber.complete();
          });
      }
    });
  }
}
