import { Injectable } from '@angular/core';
import { IAuthService } from '../../../models/interfaces/i-auth-service';
import { IUser } from '../../../models/interfaces/i-user';
import { AuthService } from '@auth0/auth0-angular';
import { delay, Observable } from 'rxjs';
import { IAuthorizer } from '../../../models/interfaces/i-authorizer';

@Injectable({
  providedIn: 'root',
})
export class OAuthService extends IAuthService implements IAuthorizer {
  constructor(private authServiceSdk: AuthService) {
    super();
  }

  confirmSignUp(_: IUser): Promise<any> {
    throw new Error('Not needed');
  }

  getUser(): Observable<any> {
    return this.authServiceSdk.user$;
  }

  isAuthenticated(): Observable<boolean> {
    return this.authServiceSdk.isAuthenticated$;
  }

  signIn(_: IUser): Promise<any> {
    this.authServiceSdk.loginWithRedirect({
      appState: {
        target: '/app/applications',
      },
    });
    return Promise.resolve(true);
  }

  signOut(): Promise<any> {
    return this.authServiceSdk
      .logout({
        logoutParams: {
          returnTo: `${window.location.origin}`,
        },
      })
      .pipe(delay(2000))
      .toPromise();
  }

  signUp(_: IUser): Promise<any> {
    return Promise.resolve(undefined);
  }

  updateUser(_: IUser): Promise<any> {
    throw Error('Not needed');
  }

  isLoading(): Observable<boolean> {
    return this.authServiceSdk.isLoading$;
  }

  getIdToken(): Observable<any> {
    return this.authServiceSdk.idTokenClaims$;
  }
}
