import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { ProviderService } from '../../../../core/provider.service';
import { UpdateAreaGQL } from '../../../../../graphql/generated';

import { AreaMapper } from '../area.mappers';
import { LogLevel } from '../../../../models/log-level';

@Component({
  selector: 'app-modify-area',
  templateUrl: './modify-area.component.html',
  styleUrls: ['./modify-area.component.scss'],
})
export class ModifyAreaComponent {
  form = new FormGroup({
    id: new FormControl('', [Validators.required]),
    region: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required]),
  });
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ModifyAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private providerService: ProviderService,
    private updateAreaGQL: UpdateAreaGQL
  ) {
    this.initiateVariables(data);
  }

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async updateArea(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new AreaMapper();
        const variables = {
          id: this.form.controls.id.value,
          region: this.form.controls.region.value,
          city: this.form.controls.city.value,
          location: this.form.controls.location.value,
        };
        await this.providerService.graphqlService.mutate(
          this.updateAreaGQL,
          variables,
          mapper
        );
        this.loading = false;
        this.closeModal();
      } catch (e: any) {
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
        this.loading = false;
      }
    }
  }

  private async initiateVariables(data: any): Promise<void> {
    this.form.controls.id.setValue(data.area.id);
    this.form.controls.region.setValue(data.area.region);
    this.form.controls.city.setValue(data.area.city);
    this.form.controls.location.setValue(data.area.location);
    this.form.markAllAsTouched();
    this.markFormAsDirty();
  }

  private markFormAsDirty(): void {
    this.form.controls.region.markAsDirty();
    this.form.controls.city.markAsDirty();
    this.form.controls.location.markAsDirty();
  }
}
