// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const environment = {
  auth0: {
    domain: 'middleware-sandbox.us.auth0.com', // 'dev-zgsrvymivlynwajo.us.auth0.com',
    clientId: 'r29zeMyejr8mGAGtNePzLCRYRFYaRD6C', // 'x2xiWJrMWh1TWK99x7RDXdBPLZu233Hh',
    redirectUri: `${window.location.origin}/app/applications`,
  },
  api: {
    endpoint: 'https://qtvtqs5pdl.execute-api.eu-west-1.amazonaws.com/prod/v1/',
  },
  cognito: {
    identityPoolId: 'eu-west-1:2381c9ed-70ba-43a8-b8f6-718b3eb69d5c',
    userPoolId: 'eu-west-1_RJYcOWKUd',
    userPoolWebClientId: '1k9pup1e2mosstgsjfqaop462c',
  },
  graphql: {
    endpoint:
      'https://2aflxgdi7fgdznqyq4xwn2id64.appsync-api.eu-west-1.amazonaws.com/graphql',
  },
  bucket: {
    name: 'sandbox-evoca-mdw-logo-bucket',
    folder: '',
  },
};

export { environment };
