<h1 mat-dialog-title>{{'MODIFY_USER.TITLE' | i18next}}</h1>

<div mat-dialog-content>
    <form spellcheck="false" [formGroup]="form">
      <mat-form-field appearance="fill">
          <mat-label>{{'MODIFY_USER.NAME_LABEL' | i18next}}</mat-label>
          <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'MODIFY_USER.NAME_PLACEHOLDER' | i18next}}" value="">
          <mat-hint>{{'MODIFY_USER.NAME_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_USER.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

      <mat-form-field appearance="fill">
          <mat-label>{{'MODIFY_USER.SURNAME' | i18next}}</mat-label>
          <input matInput type="text" id="surname" name="surname" formControlName="surname" placeholder="{{'MODIFY_USER.SURNAME_PLACEHOLDER' | i18next}}" value="">
          <mat-hint>{{'MODIFY_USER.SURNAME_HINT' | i18next}}</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'surname'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_USER.SURNAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

      <mat-form-field appearance="fill">
          <mat-label>{{'MODIFY_USER.USER_ROLE_LABEL' | i18next}}</mat-label>
          <mat-select [formControl]="form.controls.userType">
              <mat-option *ngFor="let userType of userTypes" [value]="userType.id">{{providerService.utilService.toCapitalizedWords(userType.name)}}</mat-option>
          </mat-select>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'userType'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_USER.USER_ROLE_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

      <ng-container *ngIf="initiating">
        <app-loader></app-loader>
      </ng-container>
      <app-select-infinite-scroll-multi
        *ngIf="!initiating"
        [icon] = "'fa-solid fa-buildings'"
        label = "{{'MODIFY_USER.COMPANIES_LABEL' | i18next}}"
        [dataShowSelector] = "'name'"
        [dataCompareSelector] = "'id'"
        [query] = "listCompaniesGQL"
        [mapper] = "listCompaniesMapper"
        [params] = "{}"
        [selectedData]="selectedCompanies"
        [disabled] = companySelectionDisabled
        (selectionChanged)="companySelectionChanged($event)">
      </app-select-infinite-scroll-multi>

    </form>

</div>
<div class="modal-dialog-actions" mat-dialog-actions>
    <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="modifyUser();" [disabled]="!formValid() || loading">
        <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
        <span>{{'MODIFY_USER.MODIFY_USER' | i18next}}</span>
    </button>
    <a mat-button (click)="closeModal()">
        <span>{{'MODIFY_USER.CANCEL' | i18next}}</span>
    </a>
</div>
