import { Injectable } from '@angular/core';
import { Constants } from '../../models/constants';
import i18n from 'i18next';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private languageKey = 'pureLatteLanguage';
  private defaultLanguage = Constants.defaultLanguage;

  constructor() {}

  bootstrapCurrentLanguage(): void {
    const key = localStorage.getItem(this.languageKey);
    if (key) {
      this.setCurrentLanguage(key);
    } else {
      this.setCurrentLanguage(this.defaultLanguage);
    }
  }

  async setCurrentLanguage(key: string): Promise<void> {
    await i18n?.changeLanguage(key);
    localStorage.setItem(this.languageKey, key);
  }

  getCurrentLanguage(): string {
    return localStorage.getItem(this.languageKey) ?? this.defaultLanguage;
  }

  translate(key: string): string {
    return i18n.t(key);
  }
}
