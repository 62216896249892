import { ApplicationModel } from './applications-models';
import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';

export class ListApplicationsMapper implements IGraphqlPagedMapper {
  items: ApplicationModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: ApplicationModel[]; total: number } {
    this.items = [];
    const applications = json['listApplications']['items'];
    this.total = json['listApplications']['total'];
    applications.forEach((value: any) => {
      this.items.push({
        applicationId: value['id'],
        applicationName: value['name'],
        url: value['url'],
        imageUrl: value['imageUrl'],
        color: value['color'],
        description: value['description'],
      });
    });
    return { items: this.items, total: this.total };
  }
}
