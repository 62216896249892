import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetLicenseGQL } from '../../../../graphql/generated';
import { FullLicenseModel } from '../../../shared/models/license.model';
import { ProviderService } from '../../../core/provider.service';
import { GetLicenseMapper } from './license-info.mappers';

@Component({
  selector: 'app-license-info',
  templateUrl: './license-info.component.html',
  styleUrls: ['./license-info.component.scss'],
})
export class LicenseInfoComponent {
  license?: FullLicenseModel;
  loading = true;
  parameters: { name: string; value: string }[] = [];

  constructor(public dialogRef: MatDialogRef<LicenseInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private getLicense: GetLicenseGQL, private providerService: ProviderService) {
    this.initializeVariables(data.license.id);
  }

  private async initializeVariables(licenseId: string) {
    this.loading = true;
    this.license = await this.providerService.graphqlService.fetch<FullLicenseModel>(this.getLicense, { id: licenseId }, new GetLicenseMapper());
    const params = JSON.parse(this.license.parameters);
    if (params) {
      Object.getOwnPropertyNames(params).forEach((v) => {
        this.parameters.push({ name: v, value: params[v] });
      });
    }
    this.loading = false;
  }
}
