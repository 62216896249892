import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';
import { CompanyModel } from '../../../shared/models/company.model';
import { IGraphqlExportMapper } from '../../../models/interfaces/i-graphql-export-mapper';
import { ApplicationModel } from '../../applications/applications-models';

export class UpdateCompanyMapper implements IGraphqlMapper {
  company?: CompanyModel;

  returnFromJson(json: any): CompanyModel {
    const value = json['updateCompany'];
    this.company = {
      id: value['id'],
      idpId: value['idpIdentifier'],
      name: value['name'],
      businessName: value['businessName'],
      numUsers: value['numUsers'],
      description: value['description'],
      vat: value['vat'],
      address: value['address'],
      brandingEnabled: value['brandingEnabled'],
      branding: JSON.parse(value['branding']),
    };
    return this.company;
  }
}

export class ListApplicationsByCompanyMapper implements IGraphqlExportMapper {
  elements: ApplicationModel[] = [];

  returnFromJson(json: any): ApplicationModel[] {
    const value = json['listApplications'];
    this.elements = [];
    value.forEach((app: any) => {
      this.elements.push({
        applicationId: app['id'],
        applicationName: app['name'],
        description: app['description'],
        url: app['url'],
        imageUrl: app['imageUrl'],
        color: app['color'],
      });
    });
    return this.elements;
  }
}
