<div class="container">

    <span class="info" matTooltip="{{'USER_DETAILS.NAME_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user"></i>{{user.name}} {{user.surname}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'USER_DETAILS.EMAIL_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-envelope"></i>{{user.email}}</span>
    <mat-divider></mat-divider>
    <span class="info" matTooltip="{{'USER_DETAILS.USER_TYPE_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-user-tag"></i>{{this.providerService.utilService.toCapitalizedWords(user.userType.name)}}</span>
    <mat-divider></mat-divider>
    <div class="info info-companies" matTooltip="{{'USER_DETAILS.COMPANIES_TOOLTIP' | i18next}}" matTooltipPosition="left">
      <i class="fa-solid fa-building"></i>
        <span *ngIf="companiesLoading">{{'USER_DETAILS.LOADING_COMPANIES' | i18next}}</span>
        <div>
            <mat-list>
                <mat-list-item *ngFor="let company of companies">
                    <i class="fa fa-circle" style="font-size:8px"></i>
                    {{company.nameCompany}}
                </mat-list-item>
            </mat-list>
        </div>
    </div>


    <mat-divider></mat-divider>
    <button mat-raised-button color="primary" (click)="openModifyUserDialog(user);">
        <i class="fa-solid fa-pen-to-square"></i>
        <span>{{'USER_DETAILS.MODIFY_USER' | i18next}}</span>
    </button>

</div>
