import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProviderService } from '../../core/provider.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  currentRoute = '';
  currentRouteList = [''];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private providerService: ProviderService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.changeRoute(event.url);
      });
    this.changeRoute(this.router.url);
  }

  changeRoute(route: string): void {
    this.currentRoute = route;
    this.currentRouteList = this.currentRoute
      .replace('app/', '')
      .split('/')
      .filter((item) => item !== '');
    if (
      this.currentRouteList &&
      this.currentRouteList[this.currentRouteList.length - 1]
    ) {
      this.currentRouteList[this.currentRouteList.length - 1] =
        this.currentRouteList[this.currentRouteList.length - 1].replace(
          RegExp('[?].*'),
          ''
        );
    }
  }

  navigateTo(href: string): void {
    this.providerService.utilService.navigateTo(href);
  }
}
