import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validator-ui',
  templateUrl: './validator-ui.component.html',
  styleUrls: ['./validator-ui.component.scss'],
})
export class ValidatorUiComponent {
  @Input()
  errorCode?: string;

  @Input()
  forceShow?: boolean;

  @Input()
  form?: FormGroup;

  @Input()
  name?: string;

  @Input()
  icon?: string;

  @Input()
  iconSuccess?: string;

  @Input()
  message?: string;
}
