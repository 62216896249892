import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, skip, Subscription } from 'rxjs';
import { ProviderService } from '../../../provider.service';
import { LogLevel } from '../../../../models/log-level';
import { GraphqlTableDatasource } from '../graphql-table.datasource';
import { OrderType } from '../../../../../graphql/generated';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'app-graphql-table-ui',
  templateUrl: './graphql-table-ui.component.html',
  styleUrls: ['./graphql-table-ui.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class GraphqlTableUiComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  @Input()
  datasource?: GraphqlTableDatasource<any>;

  @Input()
  listItemName!: string;

  @Input()
  actionTitle = _('GRAPHQL_TABLE.ACTIONS');

  @Input()
  actionDefinitions?: {
    icon: string;
    text: string;
    styleClass: string;
    callback: any;
    tooltip?: string;
  }[];

  @Input()
  tableDefinitions?: {
    def: string;
    secondDef?: string;
    title: string;
    sortable?: boolean;
    prefix?: string;
    suffix?: string;
    iconItem?: boolean;
    iconItemColorName?: string;
    iconItemName?: string;
  }[];

  @Input()
  parentID?: number;

  @Input()
  pageSize = 3;

  @Input()
  pageSizeOptions: number[] = [3, 6, 8];

  @Input()
  filterObservable?: Observable<string>;

  @Input()
  hasRowAction?: boolean = false;

  @Output()
  rowClicked: EventEmitter<any> = new EventEmitter();

  tableDefinitionHeaders?: string[];

  private filterSubscription?: Subscription;
  private paginatorSubscription?: Subscription;
  private sortSubscription?: Subscription;
  private sortChangeSubscription?: Subscription;
  private filter = '';

  constructor(private providerService: ProviderService) {}

  mapIconItem(cell: any[], iconItemName: string): string {
    return cell.map((value: any) => value[iconItemName]).join(', ');
  }

  ngOnInit(): void {
    if (!this.datasource || !this.tableDefinitions) {
      throw new Error(
        'Verify that datasource and tableDefinitions are defined in the parent component'
      );
    }
    this.tableDefinitionHeaders = this.tableDefinitions.map((i) => i.def);

    if (this.actionDefinitions && this.actionDefinitions.length > 0) {
      this.tableDefinitionHeaders =
        this.tableDefinitionHeaders.concat('actions');
    }
  }

  ngAfterViewInit(): void {
    this.paginatorSubscription = this.paginator.page.subscribe(() => {
      this.refreshData();
    });
    // reset the paginator after sorting
    this.sortChangeSubscription = this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.filterSubscription = this.filterObservable
      ?.pipe(skip(1))
      .subscribe((value: string) => {
        this.filter = value;
        this.paginator.pageIndex = 0;
        this.refreshData();
      });

    setTimeout(() => this.refreshData(), 100);
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.paginatorSubscription?.unsubscribe();
    this.sortSubscription?.unsubscribe();
    this.sortChangeSubscription?.unsubscribe();
  }

  rowClickedAction(row: any): void {
    this.rowClicked.emit(row);
  }

  getTotalCount(): number {
    return this.datasource?.getTotalCount() ?? 0;
  }

  private refreshData(): void {
    if (this.datasource && this.tableDefinitions) {
      try {
        const direction = (OrderType as any)[
          this.providerService.utilService.toCapitalizedWords(
            this.sort.direction
          )
        ];
        this.datasource.loadElements(
          this.filter ?? '',
          this.sort.active,
          direction,
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      } catch (error: any) {
        this.providerService.utilService.showMessage(
          error.message,
          LogLevel.error
        );
      }
    }
  }
}
