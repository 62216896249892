<br><br>

<div class="align-right">
    <button mat-raised-button color="primary" (click)="addLicense();">
        <i class="fa-solid fa-user-plus"></i>
        <span>{{'LICENSES.ADD_LICENSE' | i18next}}</span>
    </button>
</div>

<div class="table-header-container">
    <div class="table-header-text">
        <i class="fa-solid fa-file-signature"></i>
        <h1>{{'LICENSES.TITLE' | i18next}}</h1>
    </div>

    <div class="table-search-form-container">
        <mat-form-field appearance="fill">
            <mat-label>{{'LICENSES.SEARCH_LABEL' | i18next}}</mat-label>
            <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
            <i class="fa-regular fa-magnifying-glass" matSuffix></i>
            <mat-hint>{{'LICENSES.FULL_TEXT_QUERY_HINT' | i18next}}</mat-hint>
        </mat-form-field>
    </div>

</div>

<mat-spinner *ngIf="!this.dataSource"></mat-spinner>
<app-graphql-table-ui
        *ngIf="this.dataSource"
        [datasource]="dataSource"
        [tableDefinitions]="tableDefinitions"
        [actionDefinitions]="actionDefinitions"
        [pageSize]="10"
        [pageSizeOptions]="[10,20,30]"
        [filterObservable]="filterObservable"
        [hasRowAction]="true"
        (rowClicked)="licenseRowClicked($event);">
</app-graphql-table-ui>
