import { Injectable } from '@angular/core';
import { IAuthService } from '../models/interfaces/i-auth-service';
import { CognitoService } from './net-utils/user-authentication/cognito.service';
import { UtilsService } from './app-utils/utils.service';
import { NetworkService } from './net-utils/network.service';
import { IamAuthenticatorService } from './net-utils/api-authorization/iam-authenticator.service';
import { OAuthService } from './net-utils/user-authentication/o-auth.service';
import { CognitoIamAuthenticatorService } from './net-utils/api-authorization/cognito-iam-authenticator.service';
import { RoleService } from './net-utils/user-authentication/role.service';
import { GraphqlService } from '../shared/services/graphql.service';
import { TranslateService } from '../shared/services/translate.service';
import { CompanyService } from '../shared/services/company.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(private utilsService: UtilsService, private cognitoAuthServiceInstance: CognitoService, private oAuthServiceInstance: OAuthService, private netService: NetworkService, private iamAuthenticatorService: IamAuthenticatorService, private cognitoIamAuthenticatorService: CognitoIamAuthenticatorService, private roleServiceInstance: RoleService, private graphqlServiceInstance: GraphqlService, private translationServiceInstance: TranslateService, private companyServiceInstance: CompanyService) {}

  get cognitoAuthService(): IAuthService {
    return this.cognitoAuthServiceInstance;
  }

  get oAuthService(): IAuthService {
    return this.oAuthServiceInstance;
  }

  get utilService(): UtilsService {
    return this.utilsService;
  }

  get networkService(): NetworkService {
    // This particular authenticator service requires the oauth service that encapsulate
    // the Okta Oauth SDK, we pass it to the singleton instance used by the provider service
    // because we use the provider service all around to avoid mismatching of context between
    // singletons.
    this.cognitoIamAuthenticatorService.setOauthService(this.oAuthService as OAuthService);
    this.netService.setAuthenticator(this.cognitoIamAuthenticatorService);
    return this.netService;
  }

  get roleService(): RoleService {
    return this.roleServiceInstance;
  }

  get graphqlService(): GraphqlService {
    return this.graphqlServiceInstance;
  }

  get translationService(): TranslateService {
    return this.translationServiceInstance;
  }

  get companyService(): CompanyService {
    return this.companyServiceInstance;
  }
}
