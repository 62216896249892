<br><br>

<div class="align-right">
  <button mat-raised-button color="primary" (click)="addCompany();">
    <i class="fa-solid fa-user-plus"></i>
    <span>{{'COMPANIES.ADD_COMPANY' | i18next}}</span>
  </button>
</div>

<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa-solid fa-buildings"></i>
    <h1>{{'COMPANIES.TITLE' | i18next}}</h1>
  </div>

  <div class="table-search-form-container">
    <mat-form-field appearance="fill">
      <mat-label>{{'COMPANIES.SEARCH' | i18next}}</mat-label>
      <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
      <mat-hint>{{'COMPANIES.TYPE_YOUR_FULL_TEXT_QUERY' | i18next}}</mat-hint>
    </mat-form-field>
  </div>

</div>


<app-graphql-table-ui
              [datasource]="dataSource"
              [tableDefinitions]="tableDefinitions"
              [actionDefinitions]="actionDefinitions"
              [pageSize]="10"
              [pageSizeOptions]="[10,20,30]"
              [filterObservable]="filterObservable"
              [hasRowAction]="true"
              (rowClicked)="companyRowClicked($event);">
</app-graphql-table-ui>
