import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { map, Observable } from 'rxjs';
import { StepperOrientation } from '@angular/cdk/stepper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GetApplicationGQL,
  GetCompanyGQL,
  ListCompaniesGQL,
  ListUserTypesGQL,
  UpdateApplicationGQL,
} from '../../../graphql/generated';
import {
  ListCompaniesChipItemMapper,
  ListUserTypesChipItemMapper,
} from '../create-application/create-application.mappers';
import { ProviderService } from '../../core/provider.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { S3Service } from '../../core/app-utils/s3.service';
import { ShowInfoComponent } from '../dialogs/show-info/show-info.component';
import { LogLevel } from '../../models/log-level';
import { checkForUriValidator } from '../create-application/create-application.component';
import { ActivatedRoute } from '@angular/router';
import { FullApplicationModel } from '../applications/applications-models';
import {
  GetApplicationMapper,
  UpdateApplicationMapper,
} from './modify-application.mapper';
import { IChipItem } from 'src/app/models/interfaces/i-chip-item';
import { GetCompanyMapper } from '../company-details/company.mappers';
import { CompanyModel } from '../../shared/models/company.model';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { IApplicationPermission } from '../create-application/create-application.models';
import {
  CheckboxTask,
  CheckboxTasks,
} from '../../core/form-utils/wall-of-checkbox/wall-of-checkbox-model';
import { CreateApplicationDatasource } from '../create-application/create-application-datasource';

@Component({
  selector: 'app-modify-application',
  templateUrl: './modify-application.component.html',
  styleUrls: ['./modify-application.component.scss'],
})
export class ModifyApplicationComponent implements OnInit {
  @Output()
  changeEvent = new EventEmitter<File>();
  image = '';
  stepperOrientation: Observable<StepperOrientation>;
  selectedFileName = '';
  selectedFile?: any;
  selectedColor = '';
  maxWidth = 500;
  resizedImage = '';
  loginUriMessage = _('MODIFY_APPLICATION.LOGIN_URI_MESSAGE');
  loginUriTitle = _('MODIFY_APPLICATION.LOGIN_URI_TITLE');
  allowedCallbackUrlsMessage = _(
    'MODIFY_APPLICATION.ALLOWED_CALLBACK_URLS_MESSAGE'
  );
  allowedCallbackUrlsTitle = _(
    'MODIFY_APPLICATION.ALLOWED_CALLBACK_URLS_TITLE'
  );
  allowedLogoutUrlsMessage = _(
    'MODIFY_APPLICATION.ALLOWED_LOGOUT_URLS_MESSAGE'
  );
  allowedLogoutUrlsTitle = _('MODIFY_APPLICATION.ALLOWED_LOGOUT_URLS_TITLE');
  fileReader = new FileReader();

  public basicInformationForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    url: new FormControl('https://', [
      Validators.required,
      checkForUriValidator(),
    ]),
  });
  public permissionsForm = new FormGroup({
    scope: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });
  selectedCallbacks: string[] = [];
  allowedLogouts: string[] = [];
  selectedLoginUri = '';
  loginUriError = false;
  isMobile = false;
  auth0ConfigCompleted = false;
  basicAuth0InformationFormValid = false;
  userTypesCompleted = false;
  permissionsCompleted = false;
  brandingValid = false;
  listUserTypesVariables = {};
  listCompaniesMapper = new ListCompaniesChipItemMapper();
  listUserTypesMapper = new ListUserTypesChipItemMapper();
  loading = false;
  showSuccessfulModifyPage = false;
  addedPermissions: IApplicationPermission[] = [];
  displayedColumns = ['scope', 'description', 'remove'];
  dataSource = new CreateApplicationDatasource();
  userTypesTasks: { [key: string]: CheckboxTasks } = {};
  selectedPermissions: { [key: string]: IApplicationPermission[] } = {};
  showErrorPage = false;
  selectedCompanies: IChipItem[] = [];
  selectedUserTypes: IChipItem[] = [];
  private originalFileName = '';
  private fileChanged = false;
  private application?: FullApplicationModel;

  constructor(
    public providerService: ProviderService,
    private getApplication: GetApplicationGQL,
    private getCompany: GetCompanyGQL,
    public listCompaniesQuery: ListCompaniesGQL,
    public listUserTypesQuery: ListUserTypesGQL,
    public breakpointObserver: BreakpointObserver,
    private s3service: S3Service,
    private updateApplication: UpdateApplicationGQL,
    private activatedRoute: ActivatedRoute
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 1280px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    this.checkBasicAuth0InformationFormValid();
    this.fileReader.onload = (e: any) => {
      this.image = e.target.result;
      this.resizeImage(this.getInnerWidth());
    };
    this.basicInformationForm.controls.name.disable();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resizeImage(this.getInnerWidth());
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params
      .subscribe(async (params: any) => {
        try {
          this.loading = true;
          const applicationId = params['id'];
          const mapper = new GetApplicationMapper();
          const application =
            await this.providerService.graphqlService.fetch<FullApplicationModel>(
              this.getApplication,
              { id: applicationId },
              mapper
            );
          await this.initializeVariables(application);
          this.loading = false;
        } catch (e: any) {
          this.providerService.utilService.showMessage(
            e.message,
            LogLevel.error
          );
          this.showErrorPage = true;
          this.loading = false;
        }
      })
      .unsubscribe();
  }

  getInnerWidth(): number {
    return window.innerWidth;
  }

  basicInformationFormValid(): boolean {
    return this.basicInformationForm.valid;
  }

  checkBasicAuth0InformationFormValid(): void {
    this.basicAuth0InformationFormValid =
      this.basicInformationForm.valid && this.brandingValid;
  }

  checkBrandingValid(): void {
    this.brandingValid = !!(this.image && this.selectedColor);
  }

  updateSource($event: any): void {
    if ($event.target['files'] && $event.target['files'].length > 0) {
      this.fileChanged = true;
      this.selectedFile = $event.target['files'][0];
      this.selectedFileName = $event.target['files'][0].name;
      this.projectImage($event.target['files'][0]);
      this.checkBrandingValid();
    }
  }

  projectImage(file: File): void {
    this.fileReader.readAsDataURL(file);
  }

  resizeImage(width: number): void {
    if (width > this.maxWidth) {
      width = this.maxWidth;
    }
    if (this.image.length > 0) {
      this.toDataURL(
        this.image,
        Math.round((width * 80) / 100),
        (dataUrl: any) => {
          this.resizedImage = dataUrl;
        }
      );
    }
  }

  toDataURL(src: string, width: number, callback: any): void {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = canvas.width * (img.height / img.width);
      ctx?.drawImage(img, 0, 0, width, canvas.width * (img.height / img.width));
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
    };
    img.src = src;
    img.onerror = () => {
      img.src = 'src/assets/images/image-placeholder.jpg';
    };
  }

  allowedCallbacksChanged($event: string[]): void {
    this.selectedCallbacks = $event;
    this.checkAuth0ConfigCompleted();
  }

  allowedLogoutsChanged($event: string[]): void {
    this.allowedLogouts = $event;
    this.checkAuth0ConfigCompleted();
  }

  checkForLoginUri($event: string): void {
    this.loginUriError = false;
    if ($event.length > 0) {
      this.loginUriError = !this.checkForUriHttps($event);
    }
    this.checkAuth0ConfigCompleted();
  }

  checkForUri(data: string): boolean {
    if (data.length > 0) {
      return (
        data.startsWith('http://') ||
        data.startsWith('https://') ||
        data.startsWith('www.')
      );
    }
    return false;
  }

  checkForUriHttps(data: string): boolean {
    if (data.length > 0) {
      const regex =
        /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
      return regex.test(data);
    }
    return false;
  }

  openInfoDialog(message: string, title: string): void {
    this.providerService.utilService.openDialog(ShowInfoComponent, {
      message,
      title,
    });
  }

  checkAuth0ConfigCompleted(): void {
    this.auth0ConfigCompleted =
      !this.loginUriError &&
      this.selectedCallbacks.length > 0 &&
      this.allowedLogouts.length > 0;
  }

  selectedCompaniesChanged($event: IChipItem[]): void {
    this.selectedCompanies = $event;
  }

  selectedUserTypesChanged($event: IChipItem[]): void {
    this.selectedUserTypes = $event;
    this.checkUserTypesCompleted();
  }

  checkUserTypesCompleted(): void {
    this.userTypesCompleted =
      this.selectedUserTypes && this.selectedUserTypes.length > 0;
  }

  async modifyApplication(): Promise<void> {
    this.loading = true;
    let imageName = '';
    try {
      imageName = `${
        this.basicInformationForm.controls.name.value
      }-logo-${crypto.randomUUID()}`;
      const imageUrl = await this.s3service.uploadImageFile(
        this.selectedFile,
        imageName
      );

      const variables = {
        id: this.application?.id ?? '',
        name: this.basicInformationForm.controls.name.value ?? '',
        description: this.basicInformationForm.controls.description.value ?? '',
        url: this.basicInformationForm.controls.url.value ?? '',
        imageUrl,
        color: this.selectedColor,
        appType: this.application?.isMobile ? 'MOBILE' : 'WEB_APP',
        loginUrl: this.selectedLoginUri,
        callbackUrls: this.selectedCallbacks,
        logoutUrls: this.allowedLogouts,
        companiesIds: this.selectedCompanies.map((value) => value.id),
        roleIds: this.selectedUserTypes,
      };
      const mapper = new UpdateApplicationMapper();
      await this.providerService.graphqlService.mutate(
        this.updateApplication,
        variables,
        mapper
      );
      if (this.originalFileName !== '' && this.fileChanged) {
        await this.s3service.deleteFile(this.originalFileName);
      }
      this.loading = false;
      this.showSuccessfulModifyPage = true;
    } catch (error: any) {
      this.loading = false;
      await this.s3service.deleteFile(imageName);
      this.providerService.utilService.showMessage(
        error.message,
        LogLevel.error
      );
    }
  }

  checkPermissionCompleted(): void {
    this.permissionsCompleted = this.addedPermissions.length !== 0;
  }

  addPermission(): void {
    const newPermission = {
      scope: this.permissionsForm.controls.scope.value ?? '',
      description: this.permissionsForm.controls.description.value ?? '',
    };
    if (
      this.addedPermissions.findIndex(
        (value) => value.scope === newPermission.scope
      ) === -1
    ) {
      this.addedPermissions.push(newPermission);
      this.dataSource.nextItems(this.addedPermissions);
      this.createUserTypesTasks();
    }
    this.checkPermissionCompleted();
    this.permissionsForm.reset();
  }

  removePermission(item: IApplicationPermission): void {
    this.addedPermissions.splice(
      this.addedPermissions.findIndex((value) => value === item),
      1
    );
    this.createUserTypesTasks();
    this.checkPermissionCompleted();
    this.dataSource.nextItems(this.addedPermissions);
  }

  initiateUserTypesTasks(): void {
    for (const key in this.selectedPermissions) {
      if (key) {
        const permissionsTasks: CheckboxTask[] = [];
        this.addedPermissions.forEach((permission) => {
          permissionsTasks.push({
            label: permission.scope + ' - ' + permission.description,
            checked: this.selectedPermissions[key].includes(permission),
            item: permission,
          });
        });
        this.userTypesTasks[key] = {
          selectAllTask: {
            label: _('CREATE_APPLICATION.SELECT_ALL'),
            checked: false,
          },
          subTasks: permissionsTasks,
        };
      }
    }
  }

  createUserTypesTasks(): void {
    this.selectedPermissions = {};
    this.userTypesTasks = {};
    this.selectedUserTypes.forEach((value) => {
      const permissionsTasks: CheckboxTask[] = [];
      this.addedPermissions.forEach((permission) => {
        permissionsTasks.push({
          label: permission.scope + ' - ' + permission.description,
          checked: false,
          item: permission,
        });
      });
      this.userTypesTasks[value.id] = {
        selectAllTask: {
          label: _('CREATE_APPLICATION.SELECT_ALL'),
          checked: false,
        },
        subTasks: permissionsTasks,
      };
    });
  }

  permissionSelectionChanged(
    userType: IChipItem,
    $event: IApplicationPermission[]
  ): void {
    this.selectedPermissions[userType.id] = $event;
  }

  private setS3File(objectUrl: string): void {
    let pathname = '';
    try {
      pathname = new URL(objectUrl).pathname;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e.message, LogLevel.error);
    }
    if (pathname) {
      const key = pathname.split('/')[1].replaceAll('+', ' ');
      this.originalFileName = key;
      console.log(key);
      this.getS3Image(key);
    } else {
      this.originalFileName = '';
    }
  }

  private async getS3Image(key: string): Promise<void> {
    try {
      const data = await this.s3service.getFile(key);
      if (data && data.Body) {
        const byteArray = await data?.Body?.transformToByteArray();
        const blob = new Blob([byteArray], { type: data.ContentType });
        this.selectedFile = new File([byteArray], key);
        const urlCreator = window.URL || window.webkitURL;
        this.image = urlCreator.createObjectURL(blob);
      }
      this.resizeImage(this.getInnerWidth());
    } catch (e: any) {
      this.providerService.utilService.showMessage(e.message, LogLevel.error);
    }
  }

  private async initializeVariables(application: FullApplicationModel) {
    this.application = application;
    const objectUrl = application.imageUrl;
    this.setS3File(objectUrl);
    this.selectedFileName = application.imageUrl;
    this.image = application.imageUrl;
    this.selectedColor = application.color;
    this.basicInformationForm.controls.name.setValue(application.name, {
      emitEvent: false,
    });
    this.basicInformationForm.controls.description.setValue(
      application.description,
      { emitEvent: false }
    );
    this.basicInformationForm.controls.url.setValue(application.url, {
      emitEvent: false,
    });
    const selectedCompanies: IChipItem[] = [];
    await Promise.all(
      application.companiesIds.map(async (value) => {
        const company =
          await this.providerService.graphqlService.fetch<CompanyModel>(
            this.getCompany,
            { id: value },
            new GetCompanyMapper()
          );
        selectedCompanies.push({ id: company.id, description: company.name });
      })
    );
    this.selectedCompanies = selectedCompanies;
    this.selectedUserTypes = application.roles.map((value) => ({
      id: value.id,
      description: value.name,
      item: {
        id: value.id,
        name: value.name,
        idpIdentifier: value.idpIdentifier,
      },
    }));
    application.roles.forEach((value) => {
      if (value.permissions) {
        this.selectedPermissions[value.id] = value.permissions.map(
          (permission) => ({
            scope: permission.name,
            description: permission.description,
          })
        );
      }
    });
    this.addedPermissions = application.permissions.map((value) => ({
      scope: value.name,
      description: value.description,
    }));
    this.isMobile = application.isMobile;
    this.selectedCallbacks = application.callbackUrls ?? [];
    this.allowedLogouts = application.logoutUrls ?? [];
    this.selectedLoginUri = application.loginUrl;
    this.initiateUserTypesTasks();
    this.checkPermissionCompleted();
    this.checkUserTypesCompleted();
    this.checkAuth0ConfigCompleted();
    this.checkBasicAuth0InformationFormValid();
    this.checkBrandingValid();
  }
}
