<h1 mat-dialog-title>{{'CREATE_LICENSE.TITLE' | i18next}}</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">
    <mat-form-field>
      <mat-label>{{'CREATE_LICENSE.LICENSE_TYPE_LABEL' | i18next}}</mat-label>
      <mat-select formControlName="licenseTypeId">
        <mat-option *ngFor="let license of licenseTypes" [value]="license.id">
          {{license.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="infinite-scroll">
      <app-select-infinite-scroll
        [icon] = "'fa-solid fa-buildings'"
        label = "{{'CREATE_LICENSE.COMPANY_LABEL' | i18next}}*"
        [dataShowSelector] = "'name'"
        [query] = "listCompanies"
        [mapper] = "mapper"
        [params] = "{}"
        (selectionChanged)="companySelectionChanged($event)">
      </app-select-infinite-scroll>
    </div>
  </form>

  <form [formGroup]="parametersForm">
    <div *ngFor="let par of licenseParameters">
      <mat-form-field appearance="fill">
        <mat-label>{{par.name ?? '' | i18next}}</mat-label>
        <input matInput formControlName="{{par.name}}Control" [name]='par.name ?? ""' (selectionchange)="licenseParameterChanged(par, $event)">
      </mat-form-field>
    </div>
  </form>
</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="createLicense();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'CREATE_LICENSE.CREATE_LICENSE' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'CREATE_LICENSE.EXPIRATION_DATE_CANCEL' | i18next}}</span>
  </a>
</div>
