import { Injectable } from '@angular/core';
import { CompanyModel } from '../models/company.model';
import { Constants } from '../../models/constants';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private selectedCompany?: CompanyModel;

  constructor() {}

  setSelectedCompany(company: CompanyModel): void {
    this.selectedCompany = company;
  }

  getSelectedCompany(): CompanyModel | undefined {
    return this.selectedCompany;
  }

  getIsAdminSelected(): boolean {
    return this.selectedCompany?.name === Constants.superAdmin;
  }
}
