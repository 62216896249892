import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { ProviderService } from '../../../../core/provider.service';
import {
  InsertAreaGQL,
  ListCompaniesGQL,
} from '../../../../../graphql/generated';
import { CompanyModel } from '../../../../shared/models/company.model';
import { ListCompaniesMapper } from '../../../companies/companies.mappers';
import { LogLevel } from '../../../../models/log-level';
import { AreaMapper } from '../area.mappers';

@Component({
  selector: 'app-create-area',
  templateUrl: './create-area.component.html',
  styleUrls: ['./create-area.component.scss'],
})
export class CreateAreaComponent {
  form = new FormGroup({
    region: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required]),
  });
  loading = false;
  selectedCompany?: CompanyModel;
  mapper = new ListCompaniesMapper();
  constructor(
    public dialogRef: MatDialogRef<CreateAreaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private providerService: ProviderService,
    public listCompanies: ListCompaniesGQL,
    public insertAreaGQL: InsertAreaGQL
  ) {}

  formValid(): boolean {
    return !!(this.form.valid && this.selectedCompany);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  companySelectionChanged(event: any): void {
    this.selectedCompany = event;
  }

  async createArea(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new AreaMapper();
        const variables = {
          region: this.form.controls.region.value,
          city: this.form.controls.city.value,
          location: this.form.controls.location.value,
          companyId: this.selectedCompany?.id,
        };
        await this.providerService.graphqlService.mutate(
          this.insertAreaGQL,
          variables,
          mapper
        );
        this.loading = false;
        this.closeModal();
      } catch (e: any) {
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
        this.loading = false;
      }
    }
  }
}
