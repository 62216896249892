import { NgModule } from '@angular/core';
import { ApplicationsComponent } from './applications/applications.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './auth/profile/profile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '../core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreateUserComponent } from './dialogs/create-user/create-user.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { CompaniesComponent } from './companies/companies.component';
import { UsersComponent } from './users/users.component';
import { CreateCompanyComponent } from './dialogs/create-company/create-company.component';
import { MatCardModule } from '@angular/material/card';
import { AppCardComponent } from './app-card/app-card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { RouterLink } from '@angular/router';
import { CreateApplicationComponent } from './create-application/create-application.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ColorPickerModule } from 'ngx-color-picker';
import { ShowInfoComponent } from './dialogs/show-info/show-info.component';
import { LoaderComponent } from './loader/loader.component';
import { ModifyApplicationComponent } from './modify-application/modify-application.component';
import { CustomConfirmationDialogComponent } from './dialogs/custom-confirmation-dialog/custom-confirmation-dialog.component';
import { ModifyCompanyComponent } from './dialogs/modify-company/modify-company.component';
import { UserDetailsComponent } from './dialogs/user-details/user-details.component';
import { ModifyUserComponent } from './dialogs/modify-user/modify-user.component';
import { MatListModule } from '@angular/material/list';
import { AreasComponent } from './areas/areas.component';
import { LicensesComponent } from './licenses/licenses.component';
import { MachinesComponent } from './machines/machines.component';
import { CreateAreaComponent } from './dialogs/area/create-area/create-area.component';
import { ModifyAreaComponent } from './dialogs/area/modify-area/modify-area.component';
import { ModifyMachineComponent } from './dialogs/machine/modify-machine/modify-machine.component';
import { CreateMachineComponent } from './dialogs/machine/create-machine/create-machine.component';
import { LicenseInfoComponent } from './dialogs/license-info/license-info.component';
import { MachineDetailsComponent } from './dialogs/machine/machine-details/machine-details.component';
import { CreateLicenseComponent } from './dialogs/create-license/create-license.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AreaDetailsComponent } from './dialogs/area/area-details/area-details.component';
import { I18NextModule } from 'angular-i18next';
import { TranslateComponent } from './translate/translate.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { ScrollNearEndDirective } from '../shared/directives/scroll-near-end.directive';
import { BrandingComponent } from './branding/branding.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    ApplicationsComponent,
    ProfileComponent,
    NavbarComponent,
    SidebarComponent,
    CreateUserComponent,
    CustomConfirmationDialogComponent,
    SignInComponent,
    CompaniesComponent,
    UsersComponent,
    CreateCompanyComponent,
    AppCardComponent,
    BreadcrumbComponent,
    CompanyDetailsComponent,
    CreateApplicationComponent,
    ShowInfoComponent,
    LoaderComponent,
    ModifyApplicationComponent,
    ModifyCompanyComponent,
    UserDetailsComponent,
    ModifyUserComponent,
    AreasComponent,
    LicensesComponent,
    MachinesComponent,
    CreateAreaComponent,
    ModifyAreaComponent,
    LicenseInfoComponent,
    CreateLicenseComponent,
    MachineDetailsComponent,
    ModifyMachineComponent,
    CreateMachineComponent,
    AreaDetailsComponent,
    TranslateComponent,
    BrandingComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CoreModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatCardModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatLegacyChipsModule,
    RouterLink,
    MatStepperModule,
    MatCheckboxModule,
    ColorPickerModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ScrollingModule,
    MatAutocompleteModule,
    I18NextModule,
    MatSortModule,
    MatTableModule,
    MatExpansionModule,
    MatChipsModule,
    ScrollNearEndDirective,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent,
    TranslateComponent,
  ],
})
export class ComponentsModule {}
