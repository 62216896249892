import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';
import { UserModel, UserTypeModel } from '../../../shared/models/user.model';
import { IGraphqlExportMapper } from '../../../models/interfaces/i-graphql-export-mapper';

export class InsertUserMapper implements IGraphqlMapper {
  user?: UserModel;

  returnFromJson(json: any): UserModel {
    const value = json['insertUser'];
    this.user = {
      userId: value['id'],
      email: value['email'],
      idpIdentifier: value['idpIdentifier'],
      name: value['name'],
      surname: value['surname'],
      userType: {} as any,
    };
    return this.user;
  }
}

export class ListUserTypesMapper implements IGraphqlExportMapper {
  elements: UserTypeModel[] = [];

  returnFromJson(json: any): UserTypeModel[] {
    this.elements = [];
    const values = json['listUserTypes'];
    values.forEach((value: any) => {
      this.elements.push({
        id: value['id'],
        name: value['name'],
        idpIdentifier: value['idpIdentifier'],
      });
    });
    return this.elements;
  }
}
