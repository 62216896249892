import { Component, Inject } from '@angular/core';
import { AreaModel } from '../../../../shared/models/area.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../../core/provider.service';
import { ModifyAreaComponent } from '../modify-area/modify-area.component';
import { GetCompanyGQL } from '../../../../../graphql/generated';

@Component({
  selector: 'app-area-details',
  templateUrl: './area-details.component.html',
  styleUrls: ['./area-details.component.scss'],
})
export class AreaDetailsComponent {
  area: AreaModel = {
    id: '',
    region: '',
    city: '',
    location: '',
    companyId: '',
    companyName: '',
  };

  constructor(public dialogRef: MatDialogRef<AreaDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public providerService: ProviderService, private getCompany: GetCompanyGQL) {
    console.log('data', data);

    this.area = {
      id: this.data.area.id,
      region: this.data.area.region,
      city: this.data.area.city,
      location: this.data.area.location,
      companyId: this.data.area.companyId,
      companyName: this.data.area.companyName,
    };
  }

  openModifyMachineDialog(area: AreaModel): void {
    console.log(area);
    this.providerService.utilService.openDialog(ModifyAreaComponent, { area }, 'modify-modal');
  }
}
