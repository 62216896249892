import { Component, OnInit, ViewChild } from '@angular/core';
import { ProviderService } from '../../core/provider.service';
import { ListApplicationsMapper } from './applications.mappers';
import {
  ListApplicationsByCompanyGQL,
  ListApplicationsGQL,
} from '../../../graphql/generated';
import { ApplicationModel } from './applications-models';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  BehaviorSubject,
  debounceTime,
  exhaustMap,
  Observable,
  scan,
  skip,
  startWith,
  Subject,
  Subscriber,
  switchMap,
} from 'rxjs';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { LogLevel } from '../../models/log-level';
import { CompanyModel } from '../../shared/models/company.model';
import { companyChangedSubject } from '../navbar/navbar.component';
import { AntiMemLeak } from '../../shared/abstract-classes/anti-mem-leak';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent extends AntiMemLeak implements OnInit {
  @ViewChild(CdkVirtualScrollViewport)
  viewport!: CdkVirtualScrollViewport;
  loading = true;
  sizeBig = false;
  sizeMedium = false;
  sizeSmall = false;
  gridSize = 'app-card-size-big';
  isAdmin = false;

  offset = new BehaviorSubject(0);
  $data: Observable<any[]> | undefined;
  form = new FormGroup({
    dataFilterController: new FormControl(''),
  });
  private nextPage$ = new Subject();
  private company?: CompanyModel;
  private lastLimit = 0;

  constructor(
    public providerService: ProviderService,
    private listApplicationsGQL: ListApplicationsGQL,
    private listApplicationsByCompanyId: ListApplicationsByCompanyGQL
  ) {
    super();
    this.gridSize = localStorage.getItem('gridSize') ?? 'app-card-size-big';
    this.changeSize(this.gridSize);
  }

  changeSize(size: string): void {
    this.gridSize = size;
    localStorage.setItem('gridSize', size);
    this.sizeBig = size === 'app-card-size-big';
    this.sizeMedium = size === 'app-card-size-medium';
    this.sizeSmall = size === 'app-card-size-small';
  }

  getApplications(page: number, filterString: string): Observable<any[]> {
    this.loading = true;
    return new Observable((subscriber: Subscriber<any[]>) => {
      let variables: {
        [key: string]: any;
      };
      let query: ListApplicationsGQL | ListApplicationsByCompanyGQL;
      let limit = 10;
      switch (this.gridSize) {
        case 'app-card-size-medium':
          limit = 50;
          break;
        case 'app-card-size-small':
          limit = 100;
          break;
      }
      if (this.company?.name.toLowerCase() === 'superadmin evoca') {
        variables = {
          page: {
            filter: filterString,
            limit,
            orderCol: '',
            begins: page * this.lastLimit,
            order: 'asc',
          },
        };
        query = this.listApplicationsGQL;
      } else {
        variables = {
          page: {
            filter: filterString,
            limit,
            orderCol: '',
            begins: page * this.lastLimit,
            order: 'asc',
          },
          companyId: this.company?.id,
        };
        query = this.listApplicationsByCompanyId;
      }
      this.lastLimit = limit;
      this.providerService.graphqlService
        .fetch<any>(query, variables, new ListApplicationsMapper())
        .then((result: { items: ApplicationModel[]; total: number }) => {
          subscriber.next(result.items);
          this.loading = false;
          subscriber.complete();
        })
        .catch((err: Error) => {
          this.providerService.utilService.showMessage(
            err.message,
            LogLevel.error
          );
        });
    });
  }

  async ngOnInit(): Promise<void> {
    const filter$ = this.form.controls.dataFilterController.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      filter((q) => typeof q === 'string')
    );

    this.company = this.providerService.companyService.getSelectedCompany();
    if (this.company) {
      this.$data = filter$.pipe(
        switchMap((filterString) => {
          //Note: Reset the page with every new search text
          let currentPage = 0;
          return this.nextPage$.pipe(
            startWith(currentPage),
            // Note: Until the backend responds, ignore NextPage requests.
            exhaustMap((_) =>
              this.getApplications(currentPage, filterString ?? '')
            ),
            tap(() => currentPage++),
            takeWhile((p: any[]) => p.length > 0),
            scan((allData: any, newData: any) => allData.concat(newData), [])
          );
        })
      );
    }
    this.subscriptions.add(
      companyChangedSubject.pipe(skip(1)).subscribe((value) => {
        this.company = value;
        this.$data = filter$.pipe(
          switchMap((filterString) => {
            //Note: Reset the page with every new search text
            let currentPage = 0;
            return this.nextPage$.pipe(
              startWith(currentPage),
              // Note: Until the backend responds, ignore NextPage requests.
              exhaustMap((_) =>
                this.getApplications(currentPage, filterString ?? '')
              ),
              tap(() => currentPage++),
              takeWhile((p: any[]) => p.length > 0),
              scan((allData: any, newData: any) => allData.concat(newData), [])
            );
          })
        );
      })
    );
    const userAndRole = await this.providerService.roleService.getUserAndRole();
    this.isAdmin = userAndRole.userType.name === 'superadmin_evoca';
  }

  removeApplication(_$event: string): void {
    this.nextPage$.next(true);
  }

  onNearEndScroll(): void {
    this.nextPage$.next(true);
  }
}
