import { Component, Inject, OnInit } from '@angular/core';
import { UserTypeModel } from '../../../../shared/models/user.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../../core/provider.service';

import { MachineModel } from '../../../../shared/models/machine.model';
import { ModifyMachineComponent } from '../modify-machine/modify-machine.component';

@Component({
  selector: 'app-machine-details',
  templateUrl: './machine-details.component.html',
  styleUrls: ['./machine-details.component.scss'],
})
export class MachineDetailsComponent implements OnInit {
  public userTypes: UserTypeModel[] = [];

  machine: MachineModel = {
    id: '',
    name: '',
    code: '',
    serialNumber: '',
    areaId: '',
    companyId: '',
    machineTypeId: '',
  };

  constructor(
    public dialogRef: MatDialogRef<MachineDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    console.log('data', data);
    this.machine = {
      id: this.data.row.id,
      name: this.data.row.name,
      code: this.data.row.code,
      serialNumber: this.data.row.serialNumber,
      areaId: this.data.row.areaId,
      companyId: this.data.row.companyId,
      machineTypeId: this.data.row.machineTypeId,
    };
  }

  ngOnInit(): void {}

  openModifyMachineDialog(machine: MachineModel): void {
    console.log(machine);
    this.providerService.utilService.openDialog(
      ModifyMachineComponent,
      { machine },
      'modify-modal'
    );
  }
}
