import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { LogLevel } from '../../../models/log-level';
import { CustomValidators } from '../../../core/form-utils/custom-validators';
import {
  InsertCompanyGQL,
  ListApplicationsGQL,
} from '../../../../graphql/generated';
import { Subscription } from 'rxjs';
import { ApplicationModel } from '../../applications/applications-models';
import { ListApplicationsMapper } from '../../applications/applications.mappers';
import { S3Service } from '../../../core/app-utils/s3.service';
import { DOCUMENT } from '@angular/common';
import { InsertCompanyMapper } from './create-company.mappers';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnDestroy {
  result: any = {};
  applicationArray: ApplicationModel[] = [];
  selectedApplications: ApplicationModel[] = [];
  resizedImage = '';
  selectedPrimaryColor = '';
  selectedSecondaryColor = '';
  selectedPrimaryFontColor = '';
  selectedSecondaryFontColor = '';
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    businessName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      CustomValidators.patternValidator(new RegExp('^[a-z0-9][a-z0-9_-]*$'), {
        businessNameInvalid: true,
      }),
    ]),
    vat: new FormControl('', [
      Validators.required,
      CustomValidators.vatValidator,
    ]),
    address: new FormControl('', [Validators.required]),
    // applications: new FormControl([] as ApplicationModel[]),
    enableBranding: new FormControl(false),
  });
  loading = false;
  image = '';
  private insertSub?: Subscription;
  private selectedFile?: any;
  private fileReader = new FileReader();
  private maxWidth = 500;
  private applicationSub?: Subscription;
  constructor(
    public dialogRef: MatDialogRef<CreateCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private providerService: ProviderService,
    private insertCompanyGql: InsertCompanyGQL,
    private listApplicationGQL: ListApplicationsGQL,
    private s3service: S3Service
  ) {
    this.getApplications();
    this.fileReader.onload = (e: any) => {
      this.image = e.target.result;
      this.resizeImage(this.getInnerWidth());
    };
    // this.applicationSub = this.form.controls.applications.valueChanges.subscribe((value) => {
    //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //   this.selectedApplications = value!;
    // });
  }

  getInnerWidth(): number {
    return window.innerWidth;
  }

  ngOnDestroy(): void {
    this.insertSub?.unsubscribe();
    this.applicationSub?.unsubscribe();
  }

  formValid(): boolean {
    return this.form.valid && this.brandingValid();
  }

  brandingValid(): boolean {
    return (
      !this.form.controls.enableBranding.value ||
      !!(
        this.image &&
        this.selectedPrimaryColor &&
        this.selectedSecondaryColor &&
        this.selectedPrimaryFontColor &&
        this.selectedSecondaryFontColor
      )
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  updateSource($event: any): void {
    if ($event.target['files'] && $event.target['files'].length > 0) {
      this.selectedFile = $event.target['files'][0];
      this.projectImage($event.target['files'][0]);
    }
  }

  projectImage(file: File): void {
    this.fileReader.readAsDataURL(file);
  }

  resizeImage(width: number): void {
    if (width > this.maxWidth) {
      width = this.maxWidth;
    }
    if (this.image) {
      this.toDataURL(
        this.image,
        Math.round((width * 80) / 100),
        (dataUrl: any) => {
          this.resizedImage = dataUrl;
        }
      );
    }
  }

  toDataURL(src: string, width: number, callback: any): void {
    const img = this.getNewImage();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = canvas.width * (img.height / img.width);
      ctx?.drawImage(img, 0, 0, width, canvas.width * (img.height / img.width));
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
    };
    img.src = src;
    img.onerror = () => {
      img.src = '';
    };
  }

  async getApplications(): Promise<void> {
    try {
      const mapper = new ListApplicationsMapper();
      const applicationFetched: { items: any[]; total: number } =
        await this.providerService.graphqlService.fetch(
          this.listApplicationGQL,
          undefined,
          mapper
        );
      this.applicationArray = applicationFetched.items;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e.message, LogLevel.error);
    }
  }

  async createCompany(): Promise<void> {
    if (this.formValid()) {
      let imageName = '';
      try {
        this.loading = true;
        const mapper = new InsertCompanyMapper();
        imageName = `${
          this.form.controls.name.value
        }-logo-${crypto.randomUUID()}`;
        let imageUrl = '';
        if (this.form.controls.enableBranding.value) {
          imageUrl = await this.s3service.uploadImageFile(
            this.selectedFile,
            imageName
          );
        }
        const variables = {
          name: this.form.controls.name.value,
          businessName: this.form.controls.businessName.value,
          description: this.form.controls.description.value,
          vat: this.form.controls.vat.value,
          address: this.form.controls.address.value,
          brandingEnabled: this.form.controls.enableBranding.value,
          branding: {
            logo: imageUrl,
            primaryColor: this.selectedPrimaryColor,
            secondaryColor: this.selectedSecondaryColor,
            primaryFontColor: this.selectedPrimaryFontColor,
            secondaryFontColor: this.selectedSecondaryFontColor,
          },
        };
        await this.providerService.graphqlService.mutate(
          this.insertCompanyGql,
          variables,
          mapper
        );
        this.loading = false;
        this.closeModal();
      } catch (e: any) {
        if (this.form.controls.enableBranding.value) {
          await this.s3service.deleteFile(imageName);
        }
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
        this.loading = false;
      }
    }
  }

  private getNewImage(): any {
    return new Image();
  }
}
