import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import {
  DeleteUserGQL,
  ListCompaniesByUserIdGQL,
  ListUserTypesGQL,
} from '../../../../graphql/generated';
import { UserModel, UserTypeModel } from '../../../shared/models/user.model';
import { Subscription } from 'rxjs';
import { LogLevel } from '../../../models/log-level';
import { ModifyUserComponent } from '../modify-user/modify-user.component';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  public userTypes: UserTypeModel[] = [];

  user: UserModel = {
    userId: '',
    name: '',
    surname: '',
    email: '',
    idpIdentifier: '',
    userType: {} as any,
  };

  companies: { idCompany: string; nameCompany: string }[] = [];

  companiesLoading = false;

  private listUserTypesSub?: Subscription;
  private listCompaniesSub?: Subscription;
  private deleteUserSub?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<UserDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService,
    private listUserTypes: ListUserTypesGQL,
    private deleteUserGQL: DeleteUserGQL,
    private listCompaniesByUserIdGQL: ListCompaniesByUserIdGQL
  ) {
    this.user = {
      userId: this.data.row.userId,
      name: this.data.row.name,
      surname: this.data.row.surname,
      email: this.data.row.email,
      idpIdentifier: this.data.row.idpIdentifier,
      userType: this.data.row.userType,
    };

    this.getUserTypes();
    this.getCompaniesByUserId();
  }

  ngOnInit(): void {}

  getUserTypes(): void {
    this.listUserTypesSub = this.listUserTypes
      .watch({}, { errorPolicy: 'all' })
      .valueChanges.subscribe((value) => {
        this.userTypes = [];
        value.data.listUserTypes?.forEach((userType) => {
          this.userTypes.push({
            id: userType?.id ?? '',
            name: userType?.name ?? '',
            idpIdentifier: userType?.idpIdentifier ?? '',
          });
        });
      });
  }

  getCompaniesByUserId(): void {
    this.companiesLoading = true;
    this.listCompaniesSub = this.listCompaniesByUserIdGQL
      .watch({ id: this.user.userId }, { errorPolicy: 'all' })
      .valueChanges.subscribe((value) => {
        this.companies = [];
        value.data.listCompanies?.items.forEach((company) => {
          this.companies.push({
            idCompany: company?.id ?? '',
            nameCompany: company?.name ?? '',
          });
        });
        this.companiesLoading = false;
      });
  }

  deleteUser(row: any): void {
    this.deleteUserSub = this.deleteUserGQL
      .mutate({ id: row.userId }, { errorPolicy: 'all' })
      .subscribe((value) => {
        if (value.errors && value.errors.length > 0) {
          let stringToShow = '';
          value.errors.forEach((err: any) => {
            stringToShow = stringToShow.concat(err.message, ' ');
          });
          this.providerService.utilService.showMessage(
            stringToShow.trim(),
            LogLevel.error
          );
        } else if (!value) {
          this.providerService.utilService.showMessage(
            'Error deleting user',
            LogLevel.error
          );
        }
      });
  }

  openModifyUserDialog(user: UserModel): void {
    this.providerService.utilService.openDialog(
      ModifyUserComponent,
      { user },
      'modify-modal'
    );
  }
}
