import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { GraphQLModule } from './graphql.module';
import { ApolloInterceptor } from './apollo-interceptor';
import { CognitoIamAuthenticatorService } from './core/net-utils/api-authorization/cognito-iam-authenticator.service';
import { OAuthService } from './core/net-utils/user-authentication/o-auth.service';
import { I18N_PROVIDERS } from './app-initializer';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        scope: 'openid profile email offline_access',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        redirect_uri: environment.auth0.redirectUri,
      },
    }),
    GraphQLModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApolloInterceptor,
      multi: true,
      deps: [CognitoIamAuthenticatorService, OAuthService],
    },
    I18N_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
