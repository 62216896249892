import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';
import { AreaModel } from '../../shared/models/area.model';

export class AreasMapper implements IGraphqlPagedMapper {
  items: AreaModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: AreaModel[]; total: number } {
    this.items = [];
    const pagedAreas = json['listAreas'];

    pagedAreas['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        region: value['region'],
        city: value['city'],
        location: value['location'],
        companyId: value['company']['id'],
        companyName: value['company']['name'],
      });
    });
    this.total = pagedAreas['total'];
    return { items: this.items, total: this.total };
  }
}
