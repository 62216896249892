import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { CompanyModel } from '../../../../shared/models/company.model';
import { ListCompaniesMapper } from '../../../companies/companies.mappers';
import { MachineTypeModel } from '../../../../shared/models/machine-type.model';
import { AreaModel } from '../../../../shared/models/area.model';
import { Subscription } from 'rxjs';
import { ProviderService } from '../../../../core/provider.service';
import {
  InsertMachineGQL,
  ListAreasGQL,
  ListCompaniesGQL,
  ListMachineTypesGQL,
  OrderType,
} from '../../../../../graphql/generated';
import { LogLevel } from '../../../../models/log-level';
import { MachineMapper } from '../machine.mapper';

@Component({
  selector: 'app-create-machine',
  templateUrl: './create-machine.component.html',
  styleUrls: ['./create-machine.component.scss'],
})
export class CreateMachineComponent {
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    area: new FormControl('', [Validators.required]),
    machineType: new FormControl('', [Validators.required]),
  });
  loading = false;
  selectedCompany?: CompanyModel;
  mapper = new ListCompaniesMapper();

  public machineTypes: MachineTypeModel[] = [];
  public areas: AreaModel[] = [];
  loadingAreas = false;

  private listMachineTypesSub?: Subscription;
  private listAreasSub?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CreateMachineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private providerService: ProviderService,
    public listCompanies: ListCompaniesGQL,
    public listMachineTypesGQL: ListMachineTypesGQL,
    public listAreasGQL: ListAreasGQL,
    public insertMachineGQL: InsertMachineGQL
  ) {
    this.form.controls.area.disable();
    this.getMachineTypes();
  }

  getMachineTypes(): void {
    this.listMachineTypesSub = this.listMachineTypesGQL
      .watch({}, { errorPolicy: 'all' })
      .valueChanges.subscribe((value) => {
        this.machineTypes = [];
        value.data.listMachineTypes?.forEach((machineType) => {
          this.machineTypes.push({
            id: machineType?.id ?? '',
            name: machineType?.name ?? '',
            description: machineType?.description ?? '',
          });
        });
      });
  }

  async getAreas(): Promise<void> {
    this.listAreasSub = this.listAreasGQL
      .watch(
        {
          page: {
            begins: 0,
            filter: '',
            limit: 99,
            order: OrderType.Asc,
            orderCol: '',
          },
          companyId: this.selectedCompany?.id,
        },
        { errorPolicy: 'all' }
      )
      .valueChanges.subscribe((value) => {
        this.areas = [];
        value.data.listAreas?.items.forEach((area) => {
          this.areas.push({
            id: area?.id ?? '',
            city: area?.city ?? '',
            region: area?.region ?? '',
            location: area?.location ?? '',
            companyId: area?.company?.id ?? '',
            companyName: area?.company?.name ?? '',
          });
        });
      });
  }

  formValid(): boolean {
    return !!(this.form.valid && this.selectedCompany);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async companySelectionChanged(event: any): Promise<void> {
    this.loadingAreas = true;
    this.form.controls.area.disable();
    this.areas = [];
    this.selectedCompany = event;
    await this.getAreas();
    this.loadingAreas = false;
    this.form.controls.area.enable();
  }

  async createMachine(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;
        const mapper = new MachineMapper();
        const variables = {
          name: this.form.controls.name.value,
          code: this.form.controls.code.value,
          serialNumber: this.form.controls.serialNumber.value,
          areaId: this.form.controls.area.value,
          machineTypeId: this.form.controls.machineType.value,
          companyId: this.selectedCompany?.id,
        };
        await this.providerService.graphqlService.mutate(
          this.insertMachineGQL,
          variables,
          mapper
        );
        this.loading = false;
        this.closeModal();
      } catch (e: any) {
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
        this.loading = false;
      }
    }
  }

  areasForCompanySelected(): boolean {
    return (
      this.areas.length === 0 &&
      this.selectedCompany !== undefined &&
      !this.loadingAreas
    );
  }
}
