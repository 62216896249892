import { Component } from '@angular/core';
import { ProviderService } from '../../core/provider.service';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})
export class TranslateComponent {
  constructor(public providerService: ProviderService) {}

  async changeLanguage(language: string): Promise<void> {
    await this.providerService.translationService.setCurrentLanguage(language);
    this.reload();
  }

  private reload() {
    document.location.reload();
  }
}
