import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogLevel } from '../../../models/log-level';
import { ProviderService } from '../../../core/provider.service';
import { InsertUserGQL, InsertUserMutationVariables, ListApplicationsGQL, ListUserTypesGQL } from '../../../../graphql/generated';
import { InsertUserMapper, ListUserTypesMapper } from './create-user.mappers';
import { ApplicationModel } from '../../applications/applications-models';
import { ListApplicationsMapper } from '../../applications/applications.mappers';
import { UserTypeModel } from '../../../shared/models/user.model';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent {
  result: any = {};
  loading = false;
  applications: ApplicationModel[] = [];
  listApplicationsMapper: ListApplicationsMapper = new ListApplicationsMapper();
  selectedApplications: ApplicationModel[] = [];
  userTypes: UserTypeModel[] = [];

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')]),
    userType: new FormControl('', [Validators.required]),
  });

  constructor(public dialogRef: MatDialogRef<CreateUserComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public providerService: ProviderService, private insertUser: InsertUserGQL, private listUserTypes: ListUserTypesGQL, public listApplications: ListApplicationsGQL) {
    this.getApplications();
    this.getUserTypes();
  }
  async getUserTypes(): Promise<void> {
    this.userTypes = await this.providerService.graphqlService.fetch(this.listUserTypes, {}, new ListUserTypesMapper());
  }

  async getApplications(): Promise<void> {
    this.applications = [
      {
        applicationId: 'id1',
        applicationName: 'application1',
        description: 'description',
        url: 'url',
        imageUrl: 'imageUrl',
        color: 'color',
        companies: [
          {
            companyName: 'companyName',
            companyId: 'companyId',
          },
        ],
      },
      {
        applicationId: 'id2',
        applicationName: 'application2',
        description: 'description2',
        url: 'url2',
        imageUrl: 'imageUrl2',
        color: 'color2',
        companies: [
          {
            companyName: 'companyName2',
            companyId: 'companyId2',
          },
        ],
      },
    ]; // = await this.providerService.graphqlService.fetch(this.listApplications, {}, new ListUserTypesMapper());
  }

  formValid(): boolean {
    return this.form.valid;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async addUser(): Promise<void> {
    if (this.formValid()) {
      try {
        this.loading = true;

        const variables = {
          email: this.form.controls.email.value ?? '',
          name: this.form.controls.name.value ?? '',
          surname: this.form.controls.surname.value ?? '',
          userTypeId: this.form.controls.userType.value ?? '',
          companiesIds: [this.data.companyId],
        } as InsertUserMutationVariables;

        await this.providerService.graphqlService.mutate(this.insertUser, variables, new InsertUserMapper());
        if (this.data.callback) {
          this.data.callback();
        }
        this.closeModal();
      } catch (error: any) {
        this.providerService.utilService.showMessage(error.toString(), LogLevel.error);
      } finally {
        this.loading = false;
      }
    }
  }

  applicationsSelectionChanged($event: ApplicationModel[]): void {
    this.selectedApplications = $event;
  }
}
