<div class="navbar">

  <button mat-button class="menu-box" (click)="toggleSidebar(true)">
    <i class="fa-solid fa-bars"></i>
  </button>

  <a (click)="navigateTo('/app/applications');">
    <div class="company-info">
      <div class="logo">
        <img src="/assets/images/logo.png" alt="logo" title="logo"/>
      </div>
      <div class="title">
        <h2>{{eConstants.title}}</h2>
      </div>
    </div>
  </a>
  <div class="user-area">
    <div class="company-selector">
      <app-select-infinite-scroll
        [icon] = "'fa fa-solid fa-buildings'"
        label = "{{'NAVBAR.COMPANIES_LABEL' | i18next}}"
        [dataShowSelector] = "'name'"
        [query] = "listCompanies"
        [mapper] = "mapper"
        [params] = "{}"
        [selectFirstOption]="true"
        (selectionChanged)="companySelectionChanged($event)">
      </app-select-infinite-scroll>
    </div>
    <div class="profile-menu" [matMenuTriggerFor]="profileMenu">
      <i class="fa-solid fa-circle-user"></i>
      <span>{{user ? user.email : '' }}</span>
    </div>
    <mat-menu #profileMenu="matMenu">
      <app-translate></app-translate>
      <a mat-menu-item (click)="navigateTo('/app/profile');">
        <i class="fa-solid fa-circle-user"></i>
        &nbsp;
        <span>Profile</span>
      </a>
      <a mat-menu-item (click)="signOut()">
        <i class="fa-regular fa-right-from-bracket"></i>
        &nbsp;
        <span>Sign Out</span>
      </a>
    </mat-menu>
    <div class="user-mobile">
      <a class="hamburger-menu" mat-icon-button [matMenuTriggerFor]="mobileMenu"><i class="fa-solid fa-bars"></i></a>
      <mat-menu #mobileMenu="matMenu">
        <span mat-menu-item disabled>Welcome, {{user ? user.email : ''}}</span>
        <app-translate></app-translate>
        <a (click)="navigateTo('/app/profile');" mat-menu-item>
          <i class="fa-regular fa-user"></i>
          <span>Profile</span>
        </a>
        <mat-divider></mat-divider>
        <a (click)="signOut()" mat-menu-item>
          <i class="fa-regular fa-right-from-bracket"></i>
          <span>Sign Out</span>
        </a>
      </mat-menu>
    </div>
  </div>
</div>
