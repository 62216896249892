import { Injectable } from '@angular/core';
import { ProviderService } from '../../provider.service';
import { IExportMapper } from 'src/app/models/interfaces/i-export-mapper';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private totalCount = 0;
  constructor(private providerService: ProviderService) {}

  getTotalCount(): number {
    return this.totalCount;
  }

  async find(
    url: string,
    parentId: number,
    filter = '',
    sortItem = '',
    sortOrder = 'asc',
    pageNumber = 0,
    pageSize = 3,
    mapper: IExportMapper
  ): Promise<IExportMapper> {
    const params = {
      filter,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      parentId: parentId.toString(),
      sortItem,
      sortOrder,
    };

    try {
      const result = await this.providerService.networkService.get(
        url,
        mapper,
        params
      );
      this.totalCount = mapper.elements.length;
      return result;
    } catch (error) {
      console.log('ERROR IN GET TABLE SERVICE');
      return Promise.reject(error);
    }
  }
}
