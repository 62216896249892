<div class="selector">
  <form [formGroup]="exFormGroup">
    <div class="selector-label">
      <i class="{{icon}}"></i>
      <span>{{label}}</span>
    </div>
    <mat-select multiple [compareWith]="compareObjects"
                (optionsScroll)="onScroll()" formControlName="selectedOptions" *ngIf="!loading">
      <mat-select-trigger>
        {{getOptionShowValue(exFormGroup.controls.selectedOptions.value[0]) || ''}}
        <span *ngIf="(this.exFormGroup.controls.selectedOptions.value.length || 0) > 1">
          (+{{(this.exFormGroup.controls.selectedOptions.value.length || 0) - 1}} {{this.exFormGroup.controls.selectedOptions.value.length === 1 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let option of data$ | async" [value]="option">
        {{getOptionShowValue(option)}}
      </mat-option>
    </mat-select>
  </form>
</div>
