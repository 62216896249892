<div class="create-application-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <mat-stepper *ngIf="!showSuccessfulModifyPage && !showErrorPage" linear [orientation]="(stepperOrientation | async)!" labelPosition="bottom" class="stepper-container">
    <mat-step [stepControl]="basicInformationForm">
      <ng-template matStepLabel>{{'MODIFY_APPLICATION.BASIC_INFORMATION' | i18next}}</ng-template>
      <form spellcheck="false" [formGroup]="basicInformationForm" class="basic-form">
        <mat-form-field appearance="fill">
          <mat-label>{{'MODIFY_APPLICATION.NAME_LABEL' | i18next}}</mat-label>
          <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'MODIFY_APPLICATION.NAME_PLACEHOLDER' | i18next}}" value="">
          <mat-hint>{{'MODIFY_APPLICATION.NAME_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [errorCode]="'required'" [form]="basicInformationForm" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_APPLICATION.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

        <mat-form-field appearance="fill">
          <mat-label>{{'MODIFY_APPLICATION.DESCRIPTION_LABEL' | i18next}}</mat-label>
          <input matInput type="text" id="description" name="description" formControlName="description" placeholder="{{'MODIFY_APPLICATION.DESCRIPTION_PLACEHOLDER' | i18next}}" value="">
          <mat-hint>{{'MODIFY_APPLICATION.DESCRIPTION_HINT' | i18next}}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{'MODIFY_APPLICATION.URL_LABEL' | i18next}}</mat-label>
          <input matInput type="text" id="url" name="url" formControlName="url" placeholder="https://" value="">
          <mat-hint>{{'MODIFY_APPLICATION.URL_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [errorCode]="'required'" [form]="basicInformationForm" [name]="'url'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_APPLICATION.URL_REQUIRED_ERROR' | i18next}}"></app-validator-ui>
        <app-validator-ui [errorCode]="'pattern'" [form]="basicInformationForm" [name]="'url'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_APPLICATION.URL_PATTERN_ERROR' | i18next}}"></app-validator-ui>
      </form>
      <div class="stepper-buttons">
        <button mat-raised-button color="{{basicInformationFormValid() ? 'primary': 'disabled'}}" [disabled]="!basicInformationFormValid()" type="button" mat-button matStepperNext  (click)="checkBasicAuth0InformationFormValid()">{{'MODIFY_APPLICATION.NEXT' | i18next}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="brandingValid">
      <ng-template matStepLabel>{{'MODIFY_APPLICATION.BRANDING' | i18next}}</ng-template>
      <div class="second-step-container">
        <div class="image-container">
          <img src="{{resizedImage ? resizedImage: 'assets/images/image-placeholder.jpg'}}" alt="assets/images/image-not-found.png">
        </div>
        <div class='upload-container'>
          <label class="custom-file-upload">
            <span class="title {{selectedFileName ? 'selected': ''}}">{{'MODIFY_APPLICATION.IMAGE' | i18next}} *</span>
            <span class="selected-file">{{selectedFileName}}</span>
            <input type="file" accept="image/*" (change)="updateSource($event)"/>
            <i class="fa-solid fa-paperclip"></i>
          </label>
        </div>
        <div class="color-container">
          <div class="color-input-container">
            <span class="title {{selectedColor ? 'selected': ''}}">{{'MODIFY_APPLICATION.COLOR' | i18next}} *</span>
            <span class="selected-color">{{selectedColor}}</span>
            <div class="color-picker-input-container">
              <input id="color-picker-input" [style.background]="selectedColor" [(colorPicker)]="selectedColor" (colorPickerChange)="checkBrandingValid();"/>
            </div>
          </div>
        </div>
        <div class="stepper-buttons">
          <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'MODIFY_APPLICATION.BACK' | i18next}}</button>
          <button mat-raised-button color="{{brandingValid ? 'primary': 'disabled'}}" [disabled]="!brandingValid" type="button" mat-button matStepperNext (click)="checkBasicAuth0InformationFormValid();">{{'MODIFY_APPLICATION.NEXT' | i18next}}</button>
        </div>
      </div>
    </mat-step>
    <mat-step [completed]="auth0ConfigCompleted">
      <ng-template matStepLabel>{{'MODIFY_APPLICATION.AUTH0_CONFIG' | i18next}}</ng-template>
      <div class="app-type-container">
        <span *ngIf="isMobile">{{'MODIFY_APPLICATION.APP_TYPE_MOBILE' | i18next}}</span>
        <span *ngIf="!isMobile">{{'MODIFY_APPLICATION.APP_TYPE_SINGLE_PAGE_APPLICATION' | i18next}}</span>
      </div>
      <div class="custom-input-container">
        <div class="custom-input">
          <span class="title {{selectedColor ? 'selected': ''}}">{{'MODIFY_APPLICATION.LOGIN_URI' | i18next}} * <a class="fa-regular fa-circle-question" (click)="openInfoDialog(loginUriMessage, loginUriTitle)"></a></span>
          <input [(ngModel)]="selectedLoginUri" (ngModelChange)="checkForLoginUri($event)"/>
        </div>
        <div *ngIf="loginUriError" class="error">
          <span>{{'MODIFY_APPLICATION.LOGIN_URI_WRONG_FORMAT_ERROR' | i18next}}</span>
        </div>
      </div>
      <div class="custom-filter-chips-container" *ngIf="!loading">
        <div class="custom-filter-chips">
          <span class="title">{{'MODIFY_APPLICATION.ALLOWED_CALLBACK_URLS' | i18next}} * <a class="fa-regular fa-circle-question" (click)="openInfoDialog(allowedCallbackUrlsMessage, allowedCallbackUrlsTitle)"></a></span>
          <app-filter-chips-free-input [selectedValues]="selectedCallbacks" [validator]="checkForUri" [maxOptions]="99" (selectionChanged)="allowedCallbacksChanged($event)"/>
        </div>
      </div>
      <div class="custom-filter-chips-container" *ngIf="!loading">
        <div class="custom-filter-chips">
          <span class="title">{{'MODIFY_APPLICATION.ALLOWED_LOGOUT_URLS' | i18next}} * <a class="fa-regular fa-circle-question" (click)="openInfoDialog(allowedLogoutUrlsMessage, allowedLogoutUrlsTitle)"></a></span>
          <app-filter-chips-free-input [selectedValues]="allowedLogouts" [validator]="checkForUri" [maxOptions]="99" (selectionChanged)="allowedLogoutsChanged($event)"/>
        </div>
      </div>
      <div class="stepper-buttons">
        <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'MODIFY_APPLICATION.BACK' | i18next}}</button>
        <button mat-raised-button color="{{auth0ConfigCompleted ? 'primary': 'disabled'}}" [disabled]="!auth0ConfigCompleted" type="button" mat-button matStepperNext>{{'MODIFY_APPLICATION.NEXT' | i18next}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="userTypesCompleted">
      <ng-template matStepLabel>{{'MODIFY_APPLICATION.COMPANY_USER_TYPE_SELECTION' | i18next}}</ng-template>
      <div class="custom-filter-chips-container" *ngIf="!loading">
        <div class="custom-filter-chips">
          <span class="title">{{'MODIFY_APPLICATION.COMPANIES_TO_ADD' | i18next}}</span>
          <app-filter-chips [selectedValues]="selectedCompanies" [graphqlQuery]="listCompaniesQuery" [mapper]="listCompaniesMapper" [maxOptions]="99" (selectionChanged)="selectedCompaniesChanged($event)"/>
        </div>
      </div>
      <div class="custom-filter-chips-container" *ngIf="!loading">
        <div class="custom-filter-chips">
          <span class="title">{{'MODIFY_APPLICATION.USER_TYPES_TO_ADD' | i18next}}</span>
          <app-filter-chips [selectedValues]="selectedUserTypes" [graphqlQuery]="listUserTypesQuery" [params]="listUserTypesVariables" [mapper]="listUserTypesMapper" [maxOptions]="99" (selectionChanged)="selectedUserTypesChanged($event)"/>
        </div>
      </div>
      <div class="stepper-buttons">
        <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'MODIFY_APPLICATION.BACK' | i18next}}</button>
        <button mat-raised-button type="button" color="{{userTypesCompleted ? 'primary': 'disabled'}}" [disabled]="!userTypesCompleted" mat-button matStepperNext>{{'MODIFY_APPLICATION.NEXT' | i18next}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="permissionsCompleted">
      <div class="permissions-container">
        <div class="permissions">
          <ng-template matStepLabel>{{'MODIFY_APPLICATION.CREATE_PERMISSIONS' | i18next}}</ng-template>
          <div class="create-permission-title"><span>{{'MODIFY_APPLICATION.CREATE_PERMISSIONS_TITLE' | i18next}}</span></div>
          <div class="create-permission-form">
            <form [formGroup]="permissionsForm">
              <div class="permission-form-field">
                <mat-form-field appearance="fill">
                  <mat-label>{{'MODIFY_APPLICATION.SCOPE_LABEL' | i18next}}</mat-label>
                  <input matInput type="text" name="scope" formControlName="scope" placeholder="{{'MODIFY_APPLICATION.SCOPE_PLACEHOLDER' | i18next}}" value="">
                  <mat-hint>{{'MODIFY_APPLICATION.SCOPE_HINT' | i18next}}</mat-hint>
                </mat-form-field>
                <app-validator-ui [errorCode]="'required'" [form]="permissionsForm" [name]="'scope'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_APPLICATION.SCOPE_ERROR' | i18next}}"></app-validator-ui>
              </div>
              <div class="permission-form-field">
                <mat-form-field appearance="fill">
                  <mat-label>{{'MODIFY_APPLICATION.DESCRIPTION_LABEL' | i18next}}</mat-label>
                  <input matInput type="text" name="description" formControlName="description" placeholder="{{'MODIFY_APPLICATION.DESCRIPTION_PLACEHOLDER' | i18next}}" value="">
                  <mat-hint>{{'MODIFY_APPLICATION.DESCRIPTION_HINT' | i18next}}</mat-hint>
                </mat-form-field>
                <app-validator-ui [errorCode]="'required'" [form]="permissionsForm" [name]="'description'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_APPLICATION.DESCRIPTION_ERROR' | i18next}}"></app-validator-ui>
              </div>
              <button class="permission-form-button" mat-raised-button
                      [disabled]="!permissionsForm.valid" color="{{permissionsForm.valid ? 'primary': 'disabled'}}" type="button" mat-button (click)="addPermission()">{{'MODIFY_APPLICATION.ADD_PERMISSION' | i18next}}</button>
            </form>
          </div>

          <div class="permissions-table">
            <mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="scope">
                <th mat-header-cell *matHeaderCellDef>{{'MODIFY_APPLICATION.PERMISSION_TABLE_SCOPE_HEADER' | i18next}}</th>
                <td mat-cell *matCellDef="let item"> {{item.scope}} </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>{{'MODIFY_APPLICATION.PERMISSION_TABLE_DESCRIPTION_HEADER' | i18next}}</th>
                <td mat-cell *matCellDef="let item"> {{item.description}} </td>
              </ng-container>
              <ng-container matColumnDef="remove">
                <th mat-header-cell *matHeaderCellDef>{{'MODIFY_APPLICATION.PERMISSION_TABLE_DELETE_HEADER' | i18next}}</th>
                <td mat-cell *matCellDef="let item"><button mat-icon-button (click)="removePermission(item)"><mat-icon>delete</mat-icon></button></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>
            </mat-table>
          </div>
        </div>
        <div class="stepper-buttons">
          <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'MODIFY_APPLICATION.BACK' | i18next}}</button>
          <button mat-raised-button color="{{permissionsCompleted ? 'primary': 'disabled'}}" [disabled]="!permissionsCompleted" type="button" mat-button matStepperNext>{{'MODIFY_APPLICATION.NEXT' | i18next}}</button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{'MODIFY_APPLICATION.ASSIGN_PERMISSIONS' | i18next}}</ng-template>
      <mat-accordion multi>
        <ng-container *ngFor="let userType of selectedUserTypes">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{userType.description}}
              </mat-panel-title>
              <mat-panel-description>
                <ng-container *ngFor="let permission of selectedPermissions[userType.id]">
                  <span>
                      {{permission.scope}} - {{permission.description}}
                  </span>
                </ng-container>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-container *ngIf="userTypesTasks && userTypesTasks[userType.id] && userTypesTasks[userType.id].subTasks">
              <app-wall-of-checkbox [allTasks]="userTypesTasks[userType.id]" [hasSelectAll]="true" (selectionChanged)="permissionSelectionChanged(userType, $event)"></app-wall-of-checkbox>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
      <div class="stepper-buttons">
        <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'MODIFY_APPLICATION.BACK' | i18next}}</button>
        <button mat-raised-button color="primary" type="button" mat-button (click)="modifyApplication()">{{'MODIFY_APPLICATION.MODIFY_APPLICATION' | i18next}}</button>
      </div>
    </mat-step>
  </mat-stepper>
  <div *ngIf="showErrorPage" class="success-container">
    <span class="success-title">{{'MODIFY_APPLICATION.RETRIEVE_APPLICATION_ERROR' | i18next}}</span>
    <a class="go-home" (click)="providerService.utilService.navigateTo('/app/applications')">{{'MODIFY_APPLICATION.GO_BACK_AND_TRY_AGAIN' | i18next}}</a>
  </div>
  <div *ngIf="showSuccessfulModifyPage" class="success-container">
    <span class="success-title">{{'MODIFY_APPLICATION.APPLICATION_MODIFIED_SUCCESS_MESSAGE' | i18next}}</span>
    <a class="go-home" (click)="providerService.utilService.navigateTo('/app/applications')">{{'MODIFY_APPLICATION.GO_TO_APPLICATIONS' | i18next}}</a>
  </div>
</div>
