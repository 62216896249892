import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckboxTasks } from './wall-of-checkbox-model';

@Component({
  selector: 'app-wall-of-checkbox',
  templateUrl: './wall-of-checkbox.component.html',
  styleUrls: ['./wall-of-checkbox.component.scss'],
})
export class WallOfCheckboxComponent<M> {
  @Input()
  allTasks?: CheckboxTasks;

  @Input()
  hasSelectAll?: boolean;

  @Output()
  selectionChanged = new EventEmitter<M[]>();

  constructor() {
    this.updateAllComplete();
  }

  updateAllComplete(): void {
    if (this.allTasks && this.allTasks.selectAllTask) {
      this.allTasks.selectAllTask.checked =
        this.allTasks?.subTasks != null &&
        this.allTasks.subTasks.every((t) => t.checked);
    }
    this.selectionChanged.emit(
      this.allTasks?.subTasks
        ?.filter((value) => value.checked)
        .map((value) => value.item)
    );
  }
  setAll(completed: boolean): void {
    if (!this.allTasks?.subTasks) {
      return;
    }
    this.allTasks?.subTasks?.forEach((t) => {
      t.checked = completed;
    });
    if (this.allTasks && this.allTasks.selectAllTask) {
      this.allTasks.selectAllTask.checked = completed;
    }
    this.selectionChanged.emit(
      this.allTasks?.subTasks
        ?.filter((value) => value.checked)
        .map((value) => value.item)
    );
  }
}
