import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutInAppComponent } from './layout/layout-in-app/layout-in-app.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { LayoutOutAppComponent } from './layout/layout-out-app/layout-out-app.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CreateApplicationComponent } from './components/create-application/create-application.component';
import { ModifyApplicationComponent } from './components/modify-application/modify-application.component';
import { UsersComponent } from './components/users/users.component';
import { AreasComponent } from './components/areas/areas.component';
import { LicensesComponent } from './components/licenses/licenses.component';
import { MachinesComponent } from './components/machines/machines.component';
import { BrandingComponent } from './components/branding/branding.component';

const routes: Routes = [
  {
    path: 'auth',
    component: LayoutOutAppComponent,
    children: [{ path: 'sign-in', component: SignInComponent }],
  },
  {
    path: 'app',
    component: LayoutInAppComponent,
    canActivate: [AuthGuard],
    // TODO - restore all Role Guards
    children: [
      { path: 'applications', component: ApplicationsComponent },
      {
        path: 'companies',
        component: CompaniesComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'companies/:id',
        component: CompanyDetailsComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'company',
        component: CompanyDetailsComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'areas',
        component: AreasComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'licenses',
        component: LicensesComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'machines',
        component: MachinesComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'applications/create-application',
        component: CreateApplicationComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'applications/modify-application/:id',
        component: ModifyApplicationComponent,
        // canActivate: [RoleGuardService],
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'branding',
        component: BrandingComponent,
        // canActivate: [RoleGuardService],
      },
      { path: 'profile', component: ProfileComponent },
    ],
  },
  { path: '', redirectTo: '/app/applications', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
