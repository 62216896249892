import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ProviderService } from '../../provider.service';
import { IChipItem } from '../../../models/interfaces/i-chip-item';
import * as Apollo from 'apollo-angular';
import { IGraphqlExportMapper } from '../../../models/interfaces/i-graphql-export-mapper';
import { LogLevel } from '../../../models/log-level';

@Component({
  selector: 'app-filter-chips-no-infinite-scroll',
  templateUrl: './filter-chips-no-infinite-scroll.component.html',
  styleUrls: ['./filter-chips-no-infinite-scroll.component.scss'],
})
export class FilterChipsNoInfiniteScrollComponent implements OnInit, OnDestroy {
  @Input()
  resetSubject: Observable<boolean> = new Observable<boolean>();
  @Input()
  parameterKey?: string;
  @Input()
  maxOptions = 3;
  @Input()
  graphqlQuery?: Apollo.Query<any, any>;
  @Input()
  variables: any;
  @Input()
  mapper?: IGraphqlExportMapper;
  @Input()
  selectedValues: string[] = [];

  @ViewChild('itemsInput')
  itemsInput?: ElementRef<HTMLInputElement>;

  @ViewChild(MatAutocompleteTrigger)
  autoComplete1?: MatAutocompleteTrigger;

  @Output()
  selectionChanged = new EventEmitter<IChipItem[]>();
  autoCompleteItems?: IChipItem[];

  autoCompleteItemsFiltered?: Observable<IChipItem[]>;

  items: IChipItem[] = [];
  chips = new FormControl('', [Validators.required]);
  isFocusedOnInput = false;

  constructor(private providerService: ProviderService) {
    this.autoCompleteItemsFiltered = this.chips.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filter(item) : this.autoCompleteItems?.slice() || []))
    );
  }

  ngOnInit(): void {
    this.resetSubject.subscribe((response) => {
      if (response) {
        this.items = [];
      }
    });
    this.getAutoCompleteItems();
  }

  remove(item: IChipItem): void {
    const index = this.items.findIndex((arrayItem) => arrayItem.id === item.id);
    if (index > -1) {
      this.items.splice(index, 1);
    }
    this.selectionChanged.emit(this.items);
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.items.map((value) => value.id).includes(event.option.value.id)) {
      this.items.push(event.option.value);
      this.selectionChanged.emit(this.items);
    }
    if (this.itemsInput?.nativeElement?.value) {
      this.itemsInput.nativeElement.value = '';
    }
    this.chips.setValue(null);
  }

  focusOnInput(): void {
    this.isFocusedOnInput = true;
  }

  deFocusOnInput(): void {
    this.isFocusedOnInput = false;
  }

  ngOnDestroy(): void {
    this.autoComplete1?.closePanel();
  }

  private _filter(value: any): IChipItem[] {
    if (typeof value === 'string') {
      const toSearch = value.toLowerCase();
      const res = this.autoCompleteItems?.filter((item) => item.id.toLowerCase().includes(toSearch) || item.description.toLowerCase().includes(toSearch));

      return res || [];
    } else {
      return [];
    }
  }

  private async getAutoCompleteItems(): Promise<void> {
    if (this.graphqlQuery && this.variables && this.mapper) {
      try {
        this.autoCompleteItems = await this.providerService.graphqlService.fetch(this.graphqlQuery, this.variables, this.mapper);
        if (this.selectedValues && this.selectedValues.length > 0 && this.autoCompleteItems) {
          this.items = this.selectedValues.map((value) => ({
            id: value,
            description: this.autoCompleteItems?.find((item) => item.id === value)?.description ?? '',
          }));
        }
        this.chips.setValue('', { emitEvent: true });
      } catch (error: any) {
        this.providerService.utilService.showMessage(error, LogLevel.error);
      }
    }
  }
}
