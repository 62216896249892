import { LicenseTypeModel } from '../../../shared/models/license.model';
import { IGraphqlExportMapper } from '../../../models/interfaces/i-graphql-export-mapper';

export class ListLicenseTypesMapper implements IGraphqlExportMapper {
  elements: LicenseTypeModel[] = [];

  returnFromJson(json: any): LicenseTypeModel[] {
    const types = json['listLicenseTypes'];
    this.elements = [];
    types.forEach((value: any) => {
      this.elements.push({
        id: value['id'],
        name: value['name'],
        description: value['description'],
        parameterDefinition: value['parametersDefinition'],
      });
    });

    return this.elements;
  }
}
