import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { IUser } from '../../../models/interfaces/i-user';
import { IAuthService } from '../../../models/interfaces/i-auth-service';

export class AWSAmplifyWrapper {
  static getAuth(): any {
    return Auth;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService extends IAuthService {
  constructor() {
    const config: any = {};
    config['Auth'] = environment.cognito;
    Amplify.configure(config);

    super();
  }

  signUp(user: IUser): Promise<any> {
    return Auth.signUp({
      username: user.name,
      password: user.password,
      attributes: {
        email: user.email,
      },
    });
  }

  confirmSignUp(user: IUser): Promise<any> {
    return AWSAmplifyWrapper.getAuth().confirmSignUp(user.email, user.code);
  }

  signIn(user: IUser): Promise<any> {
    return AWSAmplifyWrapper.getAuth()
      .signIn(user.name || user.email, user.password)
      .then(() => {
        this.authenticationSubject.next(true);
      });
  }

  signOut(): Promise<any> {
    return AWSAmplifyWrapper.getAuth()
      .signOut()
      .then(() => {
        this.authenticationSubject.next(false);
      });
  }

  isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {
          if (user) {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => false);
    }
  }

  async getUser(): Promise<any> {
    return AWSAmplifyWrapper.getAuth().currentUserInfo();
  }

  updateUser(user: IUser): Promise<any> {
    return AWSAmplifyWrapper.getAuth()
      .currentUserPoolUser()
      .then((cognitoUser: any) =>
        AWSAmplifyWrapper.getAuth().updateUserAttributes(cognitoUser, user)
      );
  }

  isLoading(): any {
    throw new Error('Not needed');
  }
}
