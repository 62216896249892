import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: any = { email: '', name: '' };
  sub1: Subscription;

  constructor(private providerService: ProviderService) {
    this.sub1 = this.providerService.oAuthService.getUser().subscribe((user: any) => (this.user = user));
  }

  ngOnDestroy(): void {
    this.sub1.unsubscribe();
  }

  async ngOnInit(): Promise<void> {}
}
