import { ModifyUserCompanyModel } from './modify-user.models';
import { IGraphqlPagedMapper } from '../../../models/interfaces/I-graphql-paged-mapper';
import { IGraphqlMapper } from '../../../models/interfaces/I-graphql-mapper';
import { UserModel } from '../../../shared/models/user.model';

export class ListCompaniesByUserIdMapper implements IGraphqlPagedMapper {
  items: ModifyUserCompanyModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: ModifyUserCompanyModel[]; total: number } {
    this.items = [];
    const pagedCompanies = json['listCompanies'];
    pagedCompanies['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        name: value['name'],
      });
    });
    this.total = pagedCompanies['total'];
    return { items: this.items, total: this.total };
  }
}

export class ModifyUserMapper implements IGraphqlMapper {
  user?: UserModel;

  returnFromJson(json: any): UserModel {
    const value = json['updateUser'];
    this.user = {
      userId: value['id'],
      email: value['email'],
      idpIdentifier: value['idpIdentifier'],
      name: value['name'],
      surname: value['surname'],
      userType: value['userType'],
    };
    return this.user;
  }
}
