<ng-container>
  <div class="chips-container" (click)="focusOnInput()">
    <form>
      <mat-chip-grid #chipGrid class="chips-grid">
        <ng-container *ngIf="items.length < maxOptions">
          <mat-chip-row *ngFor="let item of items" (removed)="remove(item)">
            <span>{{item}}</span>
            <button matChipRemove [attr.aria-label]="'remove ' + item">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </ng-container>
        <ng-container *ngIf="items.length >= maxOptions">
          <mat-chip-row *ngFor="let item of items.slice(0,maxOptions)" (removed)="remove(item)">
            <span>{{item}}</span>
            <button matChipRemove [attr.aria-label]="'remove ' + item">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <ng-container *ngIf="items.length >= maxOptions + 1">
            <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="others-button">
              <span>
                 +{{(items.slice(maxOptions,items.length).length)}} {{items.slice(maxOptions,items.length).length === 1 ? 'other' : 'others'}}
              </span>
            </button>
            <mat-menu #menu="matMenu" class="more-items-menu">
              <div *ngFor="let item of items.slice(maxOptions,items.length)" class="more-items-menu-item">
                <button class="more-items-menu-item-button" type="button" mat-flat-button (click)="remove(item)" [attr.aria-label]="'remove ' + item">
                  <span>{{item}}</span>
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </div>
            </mat-menu>
          </ng-container>
        </ng-container>
        <input [cdkTrapFocusAutoCapture]="isFocusedOnInput" [cdkTrapFocus]="isFocusedOnInput" (focusout)="deFocusOnInput()"
               [matChipInputFor]="chipGrid"
               (matChipInputTokenEnd)="add($event)"/>
      </mat-chip-grid>
    </form>
  </div>

</ng-container>
