import { BehaviorSubject } from 'rxjs';
import { IUser } from './i-user';

/**
 * Class responsible to describe how we want a User Authenticator Service to be like to be used trasparently in the App.
 */
export abstract class IAuthService {
  protected authenticationSubject: BehaviorSubject<any>;

  protected constructor() {
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  abstract isLoading(): any;

  abstract signUp(user: IUser): Promise<any>;

  abstract confirmSignUp(user: IUser): Promise<any>;

  abstract signIn(user: IUser): Promise<any>;

  abstract signOut(): Promise<any>;

  abstract isAuthenticated(): any;

  abstract getUser(): any;

  abstract updateUser(user: IUser): Promise<any>;
}
