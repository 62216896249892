<h1 mat-dialog-title>{{'MODIFY_COMPANY.MODIFY' | i18next}} {{data.company.name}}</h1>

<div mat-dialog-content>
  <form spellcheck="false" [formGroup]="form">

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_COMPANY.NAME_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'MODIFY_COMPANY.NAME_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_COMPANY.NAME_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_COMPANY.NAME_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_COMPANY.DESCRIPTION_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="description" name="description" formControlName="description" placeholder="{{'MODIFY_COMPANY.DESCRIPTION_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_COMPANY.DESCRIPTION_HINT' | i18next}}</mat-hint>
    </mat-form-field>


    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_COMPANY.BUSINESS_NAME_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="businessName" name="businessName" formControlName="businessName" placeholder="{{'MODIFY_COMPANY.BUSINESS_NAME_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_COMPANY.BUSINESS_NAME_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui
      [form]="form"
      [name]="'businessName'"
      [icon]="'fa-regular fa-circle-exclamation'"
      message="{{'MODIFY_COMPANY.BUSINESS_NAME_MIN_LENGTH_ERROR' | i18next}}"
      [errorCode]="'minlength'">
    </app-validator-ui>
    <app-validator-ui
      [form]="form"
      [name]="'businessName'"
      [icon]="'fa-regular fa-circle-exclamation'"
      message="{{'MODIFY_COMPANY.BUSINESS_NAME_INVALID_ERROR' | i18next}}"
      [errorCode]="'businessNameInvalid'">
    </app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_COMPANY.VAT_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="vat" name="vat" formControlName="vat" placeholder="{{'MODIFY_COMPANY.VAT_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_COMPANY.VAT_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'vat'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_COMPANY.VAT_REQUIRED_ERROR' | i18next}}" [errorCode]="'required'"></app-validator-ui>
    <app-validator-ui [form]="form" [name]="'vat'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_COMPANY.VAT_INVALID_ERROR' | i18next}}" [errorCode]="'vatInvalid'"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>{{'MODIFY_COMPANY.ADDRESS_LABEL' | i18next}}</mat-label>
      <input matInput type="text" id="address" name="address" formControlName="address" placeholder="{{'MODIFY_COMPANY.ADDRESS_PLACEHOLDER' | i18next}}" value="">
      <mat-hint>{{'MODIFY_COMPANY.ADDRESS_HINT' | i18next}}</mat-hint>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'address'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_COMPANY.ADDRESS_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

    <app-loader *ngIf="initializing"></app-loader>
    <app-select-infinite-scroll-multi
      *ngIf="!initializing"
      [icon] = "'fa-solid fa-grid'"
      label = "{{'MODIFY_USER.APPLICATIONS_LABEL' | i18next}}"
      [dataShowSelector] = "'applicationName'"
      [dataCompareSelector] = "'applicationId'"
      [query] = "listApplicationGQL"
      [mapper] = "listApplicationsMapper"
      [params] = "{}"
      [selectedData]="selectedApplications"
      (selectionChanged)="applicationSelectionChanged($event)">
    </app-select-infinite-scroll-multi>
    <app-validator-ui [form]="form" [name]="'applications'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'MODIFY_COMPANY.APPLICATIONS_REQUIRED_ERROR' | i18next}}"></app-validator-ui>

    <div class="branding-container">
      <mat-checkbox [formControl]="form.controls.enableBranding">{{'MODIFY_COMPANY.ENABLE_BRANDING' | i18next}}</mat-checkbox>
      <div class="image-container">
        <img src="{{resizedImage ? resizedImage: 'assets/images/image-placeholder.jpg'}}">
      </div>
      <div class='upload-container'>
        <div class="custom-file-upload">
          <span class="title {{image ? 'selected': ''}} {{!form.controls.enableBranding.value ? 'disabled': ''}}">{{'MODIFY_COMPANY.IMAGE' | i18next}} *</span>
          <div class="file-input-container">
            <label for="file-input"><i class="fa-solid fa-paperclip"></i></label>
            <input id="file-input" [disabled]="!form.controls.enableBranding.value" type="file" accept="image/*" (change)="updateSource($event)"/>
          </div>
        </div>
      </div>
      <div class="color-container">
        <div class="color-input-container">
          <span class="title {{selectedPrimaryColor ? 'selected': ''}} {{!form.controls.enableBranding.value ? 'disabled': ''}}">{{'MODIFY_COMPANY.PRIMARY_COLOR' | i18next}} *</span>
          <span class="selected-color">{{selectedPrimaryColor}}</span>
          <div class="color-picker-input-container">
            <input class="color-picker-input" [disabled]="!form.controls.enableBranding.value" [style.background]="selectedPrimaryColor" [(colorPicker)]="selectedPrimaryColor"/>
          </div>
        </div>
      </div>
      <div class="color-container">
        <div class="color-input-container">
          <span class="title {{selectedSecondaryColor ? 'selected': ''}} {{!form.controls.enableBranding.value ? 'disabled': ''}}">{{'MODIFY_COMPANY.SECONDARY_COLOR' | i18next}} *</span>
          <span class="selected-color">{{selectedSecondaryColor}}</span>
          <div class="color-picker-input-container">
            <input class="color-picker-input" [disabled]="!form.controls.enableBranding.value" [style.background]="selectedSecondaryColor" [(colorPicker)]="selectedSecondaryColor"/>
          </div>
        </div>
      </div>
      <div class="color-container">
        <div class="color-input-container">
          <span class="title {{selectedPrimaryFontColor ? 'selected': ''}} {{!form.controls.enableBranding.value ? 'disabled': ''}}">{{'MODIFY_COMPANY.PRIMARY_FONT_COLOR' | i18next}} *</span>
          <span class="selected-color">{{selectedPrimaryFontColor}}</span>
          <div class="color-picker-input-container">
            <input class="color-picker-input" [disabled]="!form.controls.enableBranding.value" [style.background]="selectedPrimaryFontColor" [(colorPicker)]="selectedPrimaryFontColor"/>
          </div>
        </div>
      </div>
      <div class="color-container">
        <div class="color-input-container">
          <span class="title {{selectedSecondaryFontColor ? 'selected': ''}} {{!form.controls.enableBranding.value ? 'disabled': ''}}">{{'MODIFY_COMPANY.SECONDARY_FONT_COLOR' | i18next}} *</span>
          <span class="selected-color">{{selectedSecondaryFontColor}}</span>
          <div class="color-picker-input-container">
            <input class="color-picker-input" [disabled]="!form.controls.enableBranding.value" [style.background]="selectedSecondaryFontColor" [(colorPicker)]="selectedSecondaryFontColor"/>
          </div>
        </div>
      </div>
    </div>

  </form>

</div>
<div class="modal-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="modifyCompany();" [disabled]="!formValid() || loading">
    <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
    <span>{{'MODIFY_COMPANY.MODIFY_COMPANY' | i18next}}</span>
  </button>
  <a mat-button (click)="closeModal()">
    <span>{{'MODIFY_COMPANY.CANCEL' | i18next}}</span>
  </a>
</div>
