<div class="create-application-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <mat-stepper *ngIf="!showSuccessfulInsertPage" linear [orientation]="(stepperOrientation | async)!" labelPosition="bottom" class="stepper-container">
    <mat-step [stepControl]="basicInformationForm">
      <ng-template matStepLabel>{{'CREATE_APPLICATION.BASIC_INFORMATION' | i18next}}</ng-template>
      <form spellcheck="false" [formGroup]="basicInformationForm" class="basic-form">
        <mat-label>{{'CREATE_APPLICATION.APP_TYPE' | i18next}}</mat-label>
        <mat-radio-group [formControl]="basicInformationForm.controls.appType">
          <mat-radio-button [disableRipple]="true" value="WEB_APP">{{'CREATE_APPLICATION.WEB_APP' | i18next}}</mat-radio-button>
          <mat-radio-button [disableRipple]="true" value="MOBILE">{{'CREATE_APPLICATION.MOBILE' | i18next}}</mat-radio-button>
        </mat-radio-group>

        <mat-form-field appearance="fill">
          <mat-label>{{'CREATE_APPLICATION.NAME' | i18next}}</mat-label>
          <input matInput type="text" id="name" name="name" formControlName="name" placeholder="{{'CREATE_APPLICATION.NAME_PLACEHOLDER' | i18next}}" value="">
          <mat-hint>{{'CREATE_APPLICATION.APPLICATION_NAME_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [errorCode]="'required'" [form]="basicInformationForm" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_APPLICATION.NAME_ERROR' | i18next}}"></app-validator-ui>

        <mat-form-field appearance="fill">
          <mat-label>{{'CREATE_APPLICATION.DESCRIPTION' | i18next}}</mat-label>
          <input matInput type="text" id="description" name="description" formControlName="description" placeholder="{{'CREATE_APPLICATION.DESCRIPTION_PLACEHOLDER' | i18next}}" value="">
          <mat-hint>{{'CREATE_APPLICATION.APPLICATION_DESCRIPTION_HINT' | i18next}}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{'CREATE_APPLICATION.URL' | i18next}}</mat-label>
          <input matInput type="text" id="url" name="url" formControlName="url" placeholder="https://" value="">
          <mat-hint>{{'CREATE_APPLICATION.APPLICATION_URL_HINT' | i18next}}</mat-hint>
        </mat-form-field>
        <app-validator-ui [errorCode]="'required'" [form]="basicInformationForm" [name]="'url'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_APPLICATION.URL_REQUIRED_ERROR' | i18next}}"></app-validator-ui>
        <app-validator-ui [errorCode]="'pattern'" [form]="basicInformationForm" [name]="'url'" [icon]="'fa-regular fa-circle-exclamation'" message="{{'CREATE_APPLICATION.URL_PATTERN_ERROR' | i18next}}"></app-validator-ui>
      </form>
      <div class="stepper-buttons">
        <button mat-raised-button color="{{basicInformationFormValid() ? 'primary': 'disabled'}}" [disabled]="!basicInformationFormValid()" type="button" mat-button matStepperNext  (click)="checkBasicAuth0InformationFormValid()">{{'CREATE_APPLICATION.NEXT' | i18next}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="brandingValid">
      <ng-template matStepLabel>{{'CREATE_APPLICATION.BRANDING' | i18next}}</ng-template>
      <div class="second-step-container">
        <div class="image-container">
          <img src="{{resizedImage ? resizedImage: 'assets/images/image-placeholder.jpg'}}" alt="assets/images/image-not-found.png">
        </div>
        <div class='upload-container'>
          <label class="custom-file-upload">
            <span class="title {{selectedFileName ? 'selected': ''}}">{{'CREATE_APPLICATION.IMAGE' | i18next}} *</span>
            <span class="selected-file">{{selectedFileName}}</span>
            <input type="file" accept="image/*" (change)="updateSource($event)"/>
            <i class="fa-solid fa-paperclip"></i>
          </label>
        </div>
        <div class="color-container">
          <div class="color-input-container">
            <span class="title {{selectedColor ? 'selected': ''}}">{{'CREATE_APPLICATION.COLOR' | i18next}} *</span>
            <span class="selected-color">{{selectedColor}}</span>
            <div class="color-picker-input-container">
              <input id="color-picker-input" [style.background]="selectedColor" [(colorPicker)]="selectedColor" (colorPickerChange)="checkBrandingValid();"/>
            </div>
          </div>
        </div>
        <div class="stepper-buttons">
          <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'CREATE_APPLICATION.BACK' | i18next}}</button>
          <button mat-raised-button color="{{brandingValid ? 'primary': 'disabled'}}" [disabled]="!brandingValid" type="button" mat-button matStepperNext (click)="checkBasicAuth0InformationFormValid();">{{'CREATE_APPLICATION.NEXT' | i18next}}</button>
        </div>
      </div>
    </mat-step>
    <mat-step [completed]="auth0ConfigCompleted">
      <ng-template matStepLabel>{{'CREATE_APPLICATION.AUTH0_CONFIG' | i18next}}</ng-template>
      <div class="custom-input-container">
        <div class="custom-input">
          <span class="title {{selectedColor ? 'selected': ''}}">{{'CREATE_APPLICATION.LOGIN_URI' | i18next}} * <a class="fa-regular fa-circle-question" (click)="openInfoDialog(loginUriMessage, loginUriTitle)"></a></span>
          <input [(ngModel)]="selectedLoginUri" (ngModelChange)="checkForLoginUri($event)"/>
        </div>
        <div *ngIf="loginUriError" class="error">
          <span>{{'CREATE_APPLICATION.URI_FORMAT_ERROR' | i18next}}</span>
        </div>
      </div>
      <div class="custom-filter-chips-container">
        <div class="custom-filter-chips">
          <span class="title">{{'CREATE_APPLICATION.ALLOWED_CALLBACK_URLS' | i18next}} * <a class="fa-regular fa-circle-question" (click)="openInfoDialog(allowedCallbackUrlsMessage, allowedCallbackUrlsTitle)"></a></span>
          <app-filter-chips-free-input [validator]="checkForUri" [maxOptions]="99" (selectionChanged)="allowedCallbacksChanged($event)"/>
        </div>
      </div>
      <div class="custom-filter-chips-container">
        <div class="custom-filter-chips">
          <span class="title">{{'CREATE_APPLICATION.ALLOWED_LOGOUT_URL' | i18next}} * <a class="fa-regular fa-circle-question" (click)="openInfoDialog(allowedLogoutUrlsMessage, allowedLogoutUrlsTitle)"></a></span>
          <app-filter-chips-free-input [validator]="checkForUri" [maxOptions]="99" (selectionChanged)="allowedLogoutsChanged($event)"/>
        </div>
      </div>
      <div class="stepper-buttons">
        <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'CREATE_APPLICATION.BACK' | i18next}}</button>
        <button mat-raised-button color="{{auth0ConfigCompleted ? 'primary': 'disabled'}}" [disabled]="!auth0ConfigCompleted" type="button" mat-button matStepperNext>{{'CREATE_APPLICATION.NEXT' | i18next}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="userTypesCompleted">
      <ng-template matStepLabel>{{'CREATE_APPLICATION.COMPANY_SELECTION' | i18next}}</ng-template>
      <div class="custom-filter-chips-container">
        <div class="custom-filter-chips">
          <span class="title">{{'CREATE_APPLICATION.COMPANIES_TO_ADD' | i18next}}</span>
          <app-filter-chips [graphqlQuery]="listCompaniesQuery" [params]="listCompaniesVariables" [mapper]="listCompaniesMapper" [maxOptions]="99" (selectionChanged)="selectedCompaniesChanged($event)"/>
        </div>
      </div>
      <div class="custom-filter-chips-container">
        <div class="custom-filter-chips">
          <span class="title">{{'CREATE_APPLICATION.USER_TYPES_TO_ADD' | i18next}}*</span>
          <app-filter-chips-no-infinite-scroll [graphqlQuery]="listUserTypesQuery" [variables]="{}" [mapper]="listUserTypesMapper" [maxOptions]="99" (selectionChanged)="selectedUserTypesChanged($event)"/>
        </div>
      </div>
      <div class="stepper-buttons">
        <button mat-raised-button color="primary" type="button" mat-button matStepperPrevious>{{'CREATE_APPLICATION.BACK' | i18next}}</button>
        <button mat-raised-button color="primary" type="button" mat-button (click)="createApplication()">{{'CREATE_APPLICATION.CREATE_APPLICATION' | i18next}}</button>
      </div>
    </mat-step>
  </mat-stepper>
  <div *ngIf="showSuccessfulInsertPage" class="success-container">
    <span class="success-title">{{'CREATE_APPLICATION.APPLICATION_INSERTED_MESSAGE' | i18next}}</span>
    <a class="go-home" (click)="providerService.utilService.navigateTo('/app/applications')">{{'CREATE_APPLICATION.GO_TO_APPLICATIONS' | i18next}}</a>
  </div>
</div>
