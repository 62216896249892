import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationModel } from '../applications/applications-models';
import { ProviderService } from '../../core/provider.service';
import { DeleteApplicationGQL } from '../../../graphql/generated';
import { DeleteMutationMapper } from '../../shared/mappers/delete-mutation.mapper';
import { LogLevel } from '../../models/log-level';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'app-app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss'],
})
export class AppCardComponent {
  @Input()
  title = '';
  @Input()
  img = '';
  @Input()
  url = '';
  @Input()
  headerColor = '';
  @Input()
  gridSize = 'app-card-size-big';
  @Input()
  application: ApplicationModel = {} as any;
  @Input()
  isAdmin = false;
  @Output()
  applicationsRemoved = new EventEmitter<string>();

  constructor(
    public providerService: ProviderService,
    private deleteApplicationGQL: DeleteApplicationGQL
  ) {}

  async deleteApplication(id: string): Promise<void> {
    if (
      await this.providerService.utilService.openConfirmationDialog(
        _('APP_CARD.DELETE_APPLICATION_CONFIRMATION_TITLE'),
        _('APP_CARD.DELETE_APPLICATION_CONFIRMATION_MESSAGE')
      )
    ) {
      try {
        const mapper = new DeleteMutationMapper();
        const res = await this.providerService.graphqlService.mutate<boolean>(
          this.deleteApplicationGQL,
          { id },
          mapper
        );
        if (!res) {
          this.providerService.utilService.showMessage(
            _('APP_CARD.APP_DELETION_ERROR'),
            LogLevel.error
          );
        } else {
          this.applicationsRemoved.emit(this.application.applicationId);
        }
      } catch (e: any) {
        this.providerService.utilService.showMessage(e.message, LogLevel.error);
      }
    }
  }

  async modifyApplication(id: string): Promise<void> {
    await this.providerService.utilService.navigateTo(
      'app/applications/modify-application/',
      id
    );
  }
}
