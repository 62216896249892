import { IGraphqlPagedMapper } from '../../models/interfaces/I-graphql-paged-mapper';
import { MachineModel } from '../../shared/models/machine.model';

export class MachineMapper implements IGraphqlPagedMapper {
  items: MachineModel[] = [];
  total = 0;

  returnFromJson(json: any): { items: MachineModel[]; total: number } {
    this.items = [];
    const pagedMachines = json['listMachines'];

    pagedMachines['items'].forEach((value: any) => {
      this.items.push({
        id: value['id'],
        name: value['name'],
        code: value['code'],
        serialNumber: value['serialNumber'],
        areaId: value['areaId'],
        companyId: value['companyId'],
        machineTypeId: value['machineTypeId'],
      });
    });
    this.total = pagedMachines['total'];
    return { items: this.items, total: this.total };
  }
}
