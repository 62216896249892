import {
  I18NEXT_SERVICE,
  I18NextTitle,
  ITranslationService,
} from 'angular-i18next';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import I18NextHttpBackend from 'i18next-http-backend';

export const appInit = (i18next: ITranslationService) => (): any =>
  i18next.use(I18NextHttpBackend).init({
    fallbackLng: 'en',
    debug: false,
    backend: {
      loadPath: 'assets/i18n/{{lng}}.json',
    },
    lng: window.navigator.language.split('-')[0],
  });

export const localeIdFactory = (i18next: ITranslationService): string =>
  i18next.language;

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: Title,
    useClass: I18NextTitle,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
